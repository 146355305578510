import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { NotFound, Login, Dashboard, Reports, Forbidden } from './views/shared'
import { i18nConfig } from './config'
import { useSelector } from 'react-redux'
import { hasReqLevel, loadCredentials, updateCurrentUser } from './utils'
import { GlobalModal } from './views/modals'

const ProtectedRoute = (props) => {
  const { token } = useSelector(state => state.login)
  const { redirectTo = '/login', children, minLevel } = props
  if (minLevel && !hasReqLevel(minLevel)) return <Navigate replace to='/forbidden' />
  if (!token) return <Navigate replace to={redirectTo} />
  return children
}

function App (props) {
  const { token } = useSelector(state => state.login)
  const [initialized, setInitialized] = useState(false)

  const init = async () => {
    await i18nConfig()
    loadCredentials()
    updateCurrentUser()
    setInitialized(true)
  }

  useEffect(() => {
    init()
  }, [])

  if (!initialized) {
    return (
      <div className='d-flex justify-content-center align-items-center vh-100'>
        <div className='spinner-border text-primary' role='status' />
      </div>
    )
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Navigate to='/dashboard' replace />} />
          <Route path='/login' element={token ? <Navigate to='/dashboard' replace /> : <Login />} />
          <Route path='/dashboard/*' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path='/reports/schedule' element={<ProtectedRoute><Reports type='schedule' /></ProtectedRoute>} />
          <Route path='/reports/lab-kits' element={<ProtectedRoute><Reports type='lab-kits' /></ProtectedRoute>} />
          <Route path='/reports/patients-without-schedule' element={<ProtectedRoute minLevel={2}><Reports type='patients-without-schedule' /></ProtectedRoute>} />
          <Route path='/reports/all-invoices/:id' element={<ProtectedRoute minLevel={2}><Reports type='all-invoices' /></ProtectedRoute>} />
          <Route path='/reports/visits-to-invoice/:id' element={<ProtectedRoute minLevel={2}><Reports type='visits-to-invoice' /></ProtectedRoute>} />
          <Route path='/reports/sum-visits-in-invoices/:id' element={<ProtectedRoute minLevel={2}><Reports type='sum-visits-in-invoices' /></ProtectedRoute>} />
          <Route path='/forbidden' element={<Forbidden />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
      <GlobalModal />
    </>
  )
}

export default App
