import React, { useMemo, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { connect } from 'react-redux'
import { validateProcedure } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import { createProcedure } from '../../../actions'

// const EX = {
//   name: '',
//   clinicalResearch: '',
//   visits: []
// }

function ProcedureForm (props) {
  const { clinicalResearches, visits, onHide, prefill = {} } = props

  const [query, setQuery] = useState({ visits: [], ...prefill })
  const [loading, setLoading] = useState(false)

  const handleUpdateQuery = (field, value) => {
    const _query = validateProcedure(field === 'visits' ? { ...query } : { ...query, [field]: value })

    if (field === 'clinicalResearch') {
      _query.visits = []
    }
    if (field === 'visits') {
      if (_query.visits.includes(value)) {
        _query.visits = _query.visits.filter(item => item !== value)
      } else {
        _query.visits = [..._query.visits, value]
      }
    }
    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createProcedure({
      data: validateProcedure(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const _visits = useMemo(() => {
    return visits.filter(item => item.clinicalResearch === query.clinicalResearch)
  }, [query.clinicalResearch, visits])
  const disabled = !query.clinicalResearch || !query.name?.trim()

  return (
    <>
      <Row>
        <Col>
          <Form.Label>
            {t('fields.name')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('name', e.target.value)}
          />
        </Col>
        <Col>
          <Form.Label>
            {t('fields.clinicalResearch')}*
          </Form.Label>
          <Form.Select
            value={query.clinicalResearch}
            onChange={e => handleUpdateQuery('clinicalResearch', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {clinicalResearches.map(cr => {
              return (
                <option
                  key={cr._id}
                  value={cr._id}
                >
                  {cr.name}
                </option>
              )
            })}
          </Form.Select>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Form.Label>
            {t('fields.visits')}
          </Form.Label>
          {!query.clinicalResearch && <p className='fst-italic'>{t('forms.selectCRforVisits')}</p>}
          {!!query.clinicalResearch && !_visits.length && <p className='fst-italic'>{t('forms.noVisitsForCR')}</p>}

          <div
            style={{ maxHeight: '500px', overflowY: 'auto' }}
            className='row px-3'
          >
            {_visits.map(visit => {
              return (
                <Form.Check
                  className='col-md-6'
                  key={visit._id}
                  id={visit._id}
                  type='checkbox'
                  label={visit.reason}
                  checked={query.visits.includes(visit._id)}
                  onChange={() => handleUpdateQuery('visits', visit._id)}
                />
              )
            })}
          </div>
        </Col>
      </Row>
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapStateToProps = state => ({
  clinicalResearches: state.dashboard.clinicalResearches,
  visits: state.dashboard.visits
})

const mapDispatchToProps = dispatch => ({
  createProcedure: payload => dispatch(createProcedure(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureForm)
