import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { connect } from 'react-redux'
import { validateProforma } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import moment from 'moment'
import { calcMoney } from '../../../utils'
import _ from 'lodash'
import { CURRENCIES, MULTIPLIER_VAT } from '../../../config/constants'
import { createProforma } from '../../../actions'

// const EX = {
//   reason: '',
//   date: '',
//   amount: 0,
//   VAT: false',
//   total: 0,
//   currency: 'BGN',
//   completed: false,
//   archived: false,
//   notes: ''
// }

function ProformaForm (props) {
  const { onHide } = props

  const [query, setQuery] = useState({ currency: 'BGN', VAT: false, amount: 0 })
  const [loading, setLoading] = useState(false)

  const handleUpdateQuery = (field, value) => {
    const _query = validateProforma({ ...query, [field]: value })
    if (field === 'amount') {
      if (_query[field] === undefined) {
        _query[field] = 0
      }
    }
    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createProforma({
      data: validateProforma(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const disabled = !query.reason || !query.date
  return (
    <>
      <Row>
        <Col>
          <br />
          <Form.Label>
            {t('fields.reason')}*
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('reason', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.amount')}
          </Form.Label>
          <Form.Control
            type='number'
            onChange={e => handleUpdateQuery('amount', e.target.value)}
            onFocus={e => e.target.select()}
          />
          <br />
          <Form.Label>
            {t('fields.total')}
          </Form.Label>
          <Form.Control
            disabled
            value={_.isFinite(query.amount) ? Number(calcMoney(query.VAT ? (query.amount * MULTIPLIER_VAT) : query.amount)).toFixed(2) : 0}
            type='number'
          />
          <br />
          <Form.Label>
            {t('fields.notes')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('notes', e.target.value)}
          />
        </Col>
        <Col>
          <br />
          <Form.Label>
            {t('fields.date')}*
          </Form.Label>
          <Form.Control
            type='date'
            onChange={e => handleUpdateQuery('date', moment(e.target.value, 'YYYY-MM-DD').toDate())}
          />
          <br />
          <Form.Label>
            {t('fields.VAT')}
          </Form.Label>
          <Form.Select
            value={query.VAT}
            onChange={e => handleUpdateQuery('VAT', e.target.value === 'true')}
          >
            <option value='false'>{t('common.no')}</option>
            <option value='true'>{t('common.yes')}</option>
          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.currency')}
          </Form.Label>
          <Form.Select
            value={query.currency}
            onChange={e => handleUpdateQuery('currency', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {CURRENCIES.map(c => {
              return (
                <option
                  key={c}
                  value={c}
                >
                  {c}
                </option>
              )
            })}
          </Form.Select>
        </Col>
      </Row>
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  createProforma: payload => dispatch(createProforma(payload))
})

export default connect(null, mapDispatchToProps)(ProformaForm)
