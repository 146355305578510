export const modalTypes = {
  SET_MODAL_FIELDS: 'modal/SET_MODAL_FIELDS',
  RESET_MODAL_FIELDS: 'modal/RESET_MODAL_FIELDS'
}

export const setModalFields = (payload) => ({
  type: modalTypes.SET_MODAL_FIELDS,
  payload
})

export const resetModalFields = () => ({
  type: modalTypes.RESET_MODAL_FIELDS
})
