import React, { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { t } from '../../../config'
import { TABLES } from '../../../config/constants'
import { connect } from 'react-redux'
import { resetModalFields } from '../../../actions'
import { AmendmentsGrid } from '../../../components/dashboard'
import { TemplateForm } from '../../../components/forms'

function GlobalModal (props) {
  const { show, type, data } = props
  const onHide = () => {
    props.resetModalFields()
  }
  const content = useMemo(() => {
    switch (type) {
      case TABLES.AMENDMENTS:
        return {
          title: `${t('dashboard.tabs.amendmentsFor')} ${data?.reason}`,
          render: () => {
            return (
              <AmendmentsGrid
                visit={data}
              />
            )
          }
        }
      case 'cr_template':
        return {
          title: `${t('dashboard.tabs.templateFor')} ${data?.name}`,
          render: () => {
            return (
              <TemplateForm
                data={data}
                onHide={onHide}
              />
            )
          }
        }
    }
  }, [type, data])
  if (!content) return null
  return (
    <Modal
      onHide={onHide}
      show={show}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header
        closeButton
      >
        <Modal.Title id='contained-modal-title-vcenter'>
          {content.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content.render()}
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = state => ({
  show: state.modal.show,
  type: state.modal.type,
  data: state.modal.data
})

const mapDispatchToProps = dispatch => ({
  resetModalFields: () => dispatch(resetModalFields())
})

export default connect(mapStateToProps, mapDispatchToProps)(GlobalModal)
