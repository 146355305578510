import moment from 'moment'
import { store } from '../config'
import { getControlColDef } from './grid'
import { calcMoney, handleDangerConfirm } from './helper'
import { deleteTreasuryMovement, editTreasuryMovement } from '../actions/treasury'
import { SelectEditor } from '../components/cells'
import { COL_DATE_WIDTH, TREASURY_MOVEMENT_TYPES } from '../config/constants'
import { validateTreasuryMovement } from './validation'

export const getTreasuryBalance = (movements = []) => {
  const balance = calcMoney(movements.reduce((acc, movement) => {
    if (movement.type === 'top-up') return acc + movement.amount
    return acc - movement.amount
  }, 0))
  return balance
}

export const getGridColDefs = () => {
  const { movements } = store.getState().treasury
  if (!movements) return undefined
  return [
    {
      editable: (params) => !params.data.autoGenerated,
      colId: 'treasuryMovementType',
      field: 'type',
      cellDataType: 'text',
      valueFormatter: (params) => params.value.toUpperCase(),
      cellEditor: SelectEditor,
      cellEditorParams: {
        options: TREASURY_MOVEMENT_TYPES.map(type => ({ label: type.toUpperCase(), value: type }))
      }
    },
    {
      editable: (params) => !params.data.autoGenerated,
      colId: 'treasuryMovementDate',
      field: 'date',
      width: COL_DATE_WIDTH,
      cellDataType: 'date',
      valueGetter: (params) => moment(params.data.date).toDate(),
      valueFormatter: (params) => moment(params.data.date).format('DD MMM YYYY')
    },
    {
      editable: (params) => !params.data.autoGenerated,
      colId: 'treasuryMovementAmount',
      field: 'amount',
      cellDataType: 'number',
      valueFormatter: (params) => Number(params.data.amount).toFixed(2)
    },
    {
      editable: (params) => !params.data.autoGenerated,
      width: 550,
      colId: 'treasuryMovementNotes',
      field: 'notes',
      cellDataType: 'text'
    },
    {
      ...getControlColDef('treasuryControlColId'),
      cellRendererParams: (params) => ({
        onDelete: !params.data.autoGenerated
          ? () => {
              handleDangerConfirm({
                onConfirm: () => {
                  store.dispatch(deleteTreasuryMovement({ _id: params.data._id }))
                }
              })
            }
          : null
      })
    }
  ].filter(item => item)
}

export const handleCellEditRequest = (event) => {
  const data = validateTreasuryMovement({
    [event.colDef.field]: event.newValue
  })

  if (_.isEmpty(data)) return

  store.dispatch(editTreasuryMovement({
    _id: event.data._id,
    data
  }))
}

export const getGridRowData = (filter) => {
  const { movements } = store.getState().treasury
  if (!movements) return undefined
  let _movements = movements.slice(0).sort((a, b) => moment(a.date).isBefore(b.date) ? 1 : -1)
  switch (filter) {
    case 'thisMonth':
      _movements = _movements.filter(movement => moment(movement.date).isSame(moment(), 'month'))
      break
    case 'lastMonth':
      _movements = _movements.filter(movement => moment(movement.date).isSame(moment().subtract(1, 'month'), 'month'))
      break
  }
  return _movements
}
