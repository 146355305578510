import React from 'react'
import { Link } from 'react-router-dom'
import { t } from '../../../config'

function Forbidden () {
  return (
    <div className='d-flex flex-fill align-items-center justify-content-center'>
      <div className='text-center'>
        <h1 className='display-1 fw-bold'>403</h1>
        <p className='fs-3'> <span className='text-danger'>{t('forbidden.oops')}</span> {t('forbidden.title')}</p>
        <p className='lead'>
          {t('forbidden.text')}
        </p>
        <Link replace to='/dashboard' className='btn btn-primary'>{t('forbidden.button')}</Link>
      </div>
    </div>
  )
}

export default Forbidden
