import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { connect } from 'react-redux'
import { store, t } from '../../../config'
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap'
import moment from 'moment'
import { exportCSV } from '../../../utils/csv'

const getGridRowData = (filter) => {
  const { dashboard } = store.getState()
  if (!dashboard.scheduledVisits) return undefined

  let svs = dashboard.scheduledVisits.filter(item => dashboard.patients.find(patient => patient._id === item.patient).status === 'active')

  switch (filter.period) {
    case 'thisWeek':
      svs = svs.filter(item => moment(item.date).isBetween(moment().startOf('isoWeek'), moment().endOf('isoWeek'), null, '[]'))
      break
    case 'nextWeek':
      svs = svs.filter(item => moment(item.date).isBetween(moment().startOf('isoWeek').add(1, 'week'), moment().endOf('isoWeek').add(1, 'week'), null, '[]'))
      break
    case 'thisMonth':
      svs = svs.filter(item => moment(item.date).isBetween(moment().startOf('month'), moment().endOf('month'), null, '[]'))
      break
    case 'nextMonth':
      svs = svs.filter(item => moment(item.date).isBetween(moment().startOf('month').add(1, 'month'), moment().endOf('month').add(1, 'month'), null, '[]'))
      break
    case 'oneMonth':
      svs = svs.filter(item => moment(item.date).isBetween(moment().startOf('day'), moment().add(31, 'days').endOf('day'), null, '[]'))
      break
    case 'threeMonths':
      svs = svs.filter(item => moment(item.date).isBetween(moment().startOf('day'), moment().add(3, 'months').endOf('day'), null, '[]'))
      break
  }

  const lbs = dashboard.labKits.filter(kit => svs.find(sv => kit.visits.includes(sv.visit)))
  const data = lbs.map(lb => {
    const sum = svs.filter(sv => lb.visits.includes(sv.visit)).length
    return {
      clinicalResearch: dashboard.clinicalResearches.find(cr => cr._id === lb.clinicalResearch).name,
      labKit: lb.name,
      sum
    }
  }).sort((a, b) => a.clinicalResearch.localeCompare(b.clinicalResearch))
  return data
}

const getGridColDefs = () => {
  const { dashboard } = store.getState()
  if (!dashboard.scheduledVisits) return undefined
  return [
    {
      editable: false,
      colId: 'clinicalResearch',
      field: 'clinicalResearch',
      cellDataType: 'text'
    },
    {
      editable: false,
      colId: 'labKit',
      field: 'labKit',
      headerName: t('fields.labKit'),
      cellDataType: 'text'
    },
    {
      editable: false,
      colId: 'sum',
      field: 'sum',
      cellDataType: 'text'
    }
  ].filter(item => item)
}

function ReportLabKits (props) {
  const TIME_FILTERS = [
    { label: t('common.thisWeek'), value: 'thisWeek' },
    { label: t('common.nextWeek'), value: 'nextWeek' },
    { label: t('common.thisMonth'), value: 'thisMonth' },
    { label: t('common.nextMonth'), value: 'nextMonth' },
    { label: t('common.oneMonth'), value: 'oneMonth' },
    { label: t('common.threeMonths'), value: 'threeMonths' }

  ]

  const { dashboard } = props

  const gridRef = useRef(null)

  const [filter, setFilter] = useState({ period: 'thisWeek', doctors: [], type: null })
  const defaultColDef = useMemo(() => ({ filter: true, editable: true, resizable: true })) // Enable on all columns
  const [colDefs, setColDefs] = useState([])
  const rowData = useMemo(() => getGridRowData(filter), [dashboard, filter])

  useEffect(() => {
    const _colDefs = getGridColDefs()
    setColDefs(_colDefs)
  }, [dashboard])

  const handleExportRowData = () => {
    const data = rowData.map(item => ({
      'Clinical Research': item.clinicalResearch,
      'Lab Kit': item.labKit,
      Sum: item.sum
    }))
    exportCSV(data, `report_lab_kits_${filter.period}`)
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>

      <div
        className='mb-3'
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >

        <ButtonGroup
          style={{ width: 750 }}
        >

          {TIME_FILTERS.map((item, index) => {
            return (
              <ToggleButton
                key={item.label}
                variant={filter.period === item.value ? 'primary' : 'secondary'}
                onClick={() => setFilter({ ...filter, period: item.value })}
              >
                {item.label}
              </ToggleButton>
            )
          })}
        </ButtonGroup>
        <Button
          onClick={handleExportRowData}
          disabled={!rowData?.length}
          className='ms-3'
          variant='success'
        >
          {t('common.export')}
        </Button>
      </div>
      <div className='ag-theme-quartz' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          pagination
          readOnlyEdit
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={colDefs}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps, null)(ReportLabKits)
