import { generalTypes, loginTypes } from '../actions'

const initialState = {
  loading: false,
  language: 'en'
}

const general = (state = initialState, { type, payload }) => {
  switch (type) {
    case generalTypes.SET_LANGUAGE:
      return { ...state, language: payload }
    case generalTypes.START_LOADING:
      return { ...state, loading: true }
    case generalTypes.STOP_LOADING:
      return { ...state, loading: false }
    case loginTypes.DEAUTHENTICATE:
      return initialState
    default:
      return state
  }
}

export default general
