import React from 'react'
import { t } from '../../../config'

const ReportsContainer = (props) => {
  const { title } = props

  return (
    <div className='d-flex flex-column flex-fill'>
      {/* header */}
      <header className='navbar sticky-top bg-dark flex-md-nowrap p-0 shadow' data-bs-theme='dark'>
        <span className='navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 py-3 text-white'>{t('dashboard.title')}</span>
      </header>
      {/* /header */}
      <div className='container-fluid d-flex flex-column flex-fill p-0'>
        <div className='d-flex flex-row flex-fill'>
          <main className='col-md-9 ms-sm-auto col-lg-10 p-4 d-flex flex-fill flex-column'>
            <div className='d-flex flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom'>
              <h1 className='h2'>{title}</h1>
            </div>
            {props.children}
          </main>

        </div>
      </div>
    </div>
  )
}

export default ReportsContainer
