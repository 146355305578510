import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { store, t } from '../../../config'
import { Button } from 'react-bootstrap'
import { exportCSV } from '../../../utils/csv'

const getGridColDefs = () => {
  const { dashboard } = store.getState()
  if (!dashboard.invoices) return undefined
  return [
    {
      editable: false,
      colId: 'invoice',
      field: 'invoice',
      cellDataType: 'text'
    },
    {
      editable: false,
      colId: 'notes',
      field: 'notes',
      cellDataType: 'text'
    },
    {
      editable: false,
      colId: 'invoiceAmount',
      field: 'invoiceAmount',
      headerName: t('fields.amount'),
      cellDataType: 'number',
      valueFormatter: (params) => {
        if (params.value === '-') return '-'
        return Number(params.value).toFixed(2)
      }
    },
    {
      editable: false,
      colId: 'invoiceCurrency',
      headerName: 'I Currency',
      width: 120,
      field: 'invoiceCurrency',
      cellDataType: 'text'
    },
    {
      editable: false,
      colId: 'sumVisits',
      field: 'sumVisits',
      headerName: t('fields.visits'),
      cellDataType: 'number',
      valueFormatter: (params) => {
        if (params.value === '-') return '-'
        return Number(params.value).toFixed(2)
      }
    },
    {
      editable: false,
      colId: 'visitCurrency',
      headerName: 'V Currency',
      width: 120,
      field: 'visitCurrency',
      cellDataType: 'text'
    },
    {
      editable: false,
      colId: 'sumReimbursements',
      field: 'sumReimbursements',
      headerName: t('fields.reimbursement'),
      cellDataType: 'number',
      valueFormatter: (params) => {
        if (params.value === '-') return '-'
        return Number(params.value).toFixed(2)
      }
    },
    {
      editable: false,
      colId: 'sumRevenue',
      field: 'sumRevenue',
      headerName: t('fields.revenue'),
      cellDataType: 'number',
      valueFormatter: (params) => {
        if (params.value === '-') return '-'
        return Number(params.value).toFixed(2)
      }
    },
    {
      editable: false,
      colId: 'revenueCurrency',
      headerName: 'R Currency',
      width: 120,
      field: 'revenueCurrency',
      cellDataType: 'text'
    },
    {
      editable: false,
      colId: 'payment',
      field: 'payment',
      headerName: t('fields.payment'),
      cellDataType: 'number',
      valueFormatter: (params) => {
        if (params.value === '-') return '-'
        return Number(params.value).toFixed(2)
      }
    },
    {
      editable: false,
      colId: 'paymentCurrency',
      headerName: 'P Currency',
      width: 120,
      field: 'paymentCurrency',
      cellDataType: 'text'
    }

  ].filter(item => item)
}
const getGridRowData = (clinicalResearchId) => {
  const { dashboard } = store.getState()
  if (!dashboard.invoices) return undefined

  const data = dashboard.invoices.filter(item => item.clinicalResearches.includes(clinicalResearchId)).map(invoice => {
    const reimbursementAVs = dashboard.actualVisits.filter(av => av.reimbursementInvoice === invoice._id)
    const visitAVs = dashboard.actualVisits.filter(av => av.visitInvoice === invoice._id).map(av => ({ ...av, amendment: dashboard.amendments.find(am => am._id === av.amendment) }))
    const visitCurrency = dashboard.visits.find(item => item._id === visitAVs?.[0]?.visit)?.currency || '-'
    const revenues = dashboard.revenues.filter(revenue => revenue.invoice === invoice._id)
    const revenueCurrency = dashboard.revenues.find(item => item.invoice === invoice._id)?.currency || '-'

    const payment = dashboard.payments.find(payment => payment._id === invoice.payment)?.amount || 0
    const paymentCurrency = dashboard.payments.find(payment => payment._id === invoice.payment)?.currency || '-'

    return {
      _id: invoice._id,
      paymentId: invoice.payment,
      color: invoice.color,
      invoice: invoice.name,
      notes: invoice.notes,
      invoiceAmount: invoice.amount,
      invoiceCurrency: invoice.currency,
      sumVisits: visitAVs.reduce((acc, av) => acc + av.amendment?.budgetMhat || 0, 0),
      visitCurrency,
      sumReimbursements: reimbursementAVs.reduce((acc, av) => acc + av.reimbursement || 0, 0),
      sumRevenue: revenues.reduce((acc, revenue) => acc + revenue.amount || 0, 0),
      revenueCurrency,
      payment,
      paymentCurrency
    }
  })
  if (data.length) {
    const crAVs = dashboard.actualVisits.filter(av => av.clinicalResearch === clinicalResearchId).map(av => ({ ...av, amendment: dashboard.amendments.find(am => am._id === av.amendment) }))
    const crRevs = dashboard.revenues.filter(rev => rev.clinicalResearch === clinicalResearchId)
    const additionalRow = {
      _id: '-',
      paymentId: '-',
      color: 'silver',
      invoice: 'UNRELATED AVs',
      notes: '-',
      invoiceAmount: '-',
      invoiceCurrency: '-',
      sumVisits: crAVs.filter(item => !item.visitInvoice).reduce((acc, av) => acc + av.amendment?.budgetMhat || 0, 0) || '-',
      visitCurrency: data[0].visitCurrency || '-',
      sumReimbursements: crAVs.filter(item => !item.reimbursementInvoice).reduce((acc, av) => acc + av.reimbursement || 0, 0) || '-',
      sumRevenue: crRevs.filter(item => !item.invoice).reduce((acc, revenue) => acc + revenue.amount || 0, 0) || '-',
      revenueCurrency: data.find(item => item.revenueCurrency !== '-')?.revenueCurrency || '-',
      payment: '-',
      paymentCurrency: '-'
    }
    data.push(additionalRow)
  }
  return data
}

function ReportSumVisitsInInvoices (props) {
  const { dashboard, params } = props
  const gridRef = useRef(null)

  const defaultColDef = useMemo(() => ({ filter: true, editable: true, resizable: true })) // Enable on all columns

  const [colDefs, setColDefs] = useState([])
  const rowData = useMemo(() => getGridRowData(params.id), [dashboard, params])

  useEffect(() => {
    const _colDefs = getGridColDefs()
    setColDefs(_colDefs)
  }, [dashboard])

  const gridOptions = useMemo(() => {
    return {
      getRowStyle: (params) => ({ backgroundColor: params.data.color || 'white' })
    }
  }, [])

  const handleExportRowData = () => {
    const exportData = rowData.map(item => ({
      Invoice: item.invoice,
      'Invoice ID': item._id,
      Notes: item.notes,
      Amount: item.invoiceAmount,
      'I Currency': item.invoiceCurrency,
      Visits: item.sumVisits,
      'V Currency': item.visitCurrency,
      Reimbursement: item.sumReimbursements,
      Revenue: item.sumRevenue,
      'R Currency': item.revenueCurrency,
      Payment: item.payment,
      'P Currency': item.paymentCurrency,
      'Payment ID': item.paymentId || '-'
    }))
    exportCSV(exportData, 'report_sum_visits_in_invoices')
  }
  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Button
        onClick={handleExportRowData}
        disabled={!rowData?.length}
        style={{ alignSelf: 'flex-start' }}
        className='mb-3'
        variant='success'
      >
        {t('common.export')}
      </Button>
      <div className='ag-theme-quartz' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          pagination
          readOnlyEdit
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={colDefs}
          gridOptions={gridOptions}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps)(ReportSumVisitsInInvoices)
