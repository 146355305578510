export const treasuryTypes = {
  SET_TREASURY_FIELDS: 'treasury/SET_TREASURY_FIELDS',
  GET_TREASURY_MOVEMENTS: 'treasury/GET_TREASURY_MOVEMENTS',
  GET_TREASURY_MOVEMENTS_SUCCESS: 'treasury/GET_TREASURY_MOVEMENTS_SUCCESS',
  CREATE_TREASURY_MOVEMENT: 'treasury/CREATE_TREASURY_MOVEMENT',
  CREATE_TREASURY_MOVEMENT_SUCCESS: 'treasury/CREATE_TREASURY_MOVEMENT_SUCCESS',

  EDIT_TREASURY_MOVEMENT: 'treasury/EDIT_TREASURY_MOVEMENT',
  EDIT_TREASURY_MOVEMENT_SUCCESS: 'treasury/EDIT_TREASURY_MOVEMENT_SUCCESS',
  EDIT_TREASURY_MOVEMENT_FAILED: 'treasury/EDIT_TREASURY_MOVEMENT_FAILED',

  DELETE_TREASURY_MOVEMENT: 'treasury/DELETE_TREASURY_MOVEMENT',
  DELETE_TREASURY_MOVEMENT_SUCCESS: 'treasury/DELETE_TREASURY_MOVEMENT_SUCCESS'
}

export const setTreasuryFields = (payload) => ({
  type: treasuryTypes.SET_TREASURY_FIELDS,
  payload
})

export const getTreasuryMovements = (payload) => ({
  type: treasuryTypes.GET_TREASURY_MOVEMENTS,
  payload
})

export const getTreasuryMovementsSuccess = (payload) => ({
  type: treasuryTypes.GET_TREASURY_MOVEMENTS_SUCCESS,
  payload
})

export const createTreasuryMovement = (payload) => ({
  type: treasuryTypes.CREATE_TREASURY_MOVEMENT,
  payload
})

export const createTreasuryMovementSuccess = (payload) => ({
  type: treasuryTypes.CREATE_TREASURY_MOVEMENT_SUCCESS,
  payload
})

export const editTreasuryMovement = (payload) => ({
  type: treasuryTypes.EDIT_TREASURY_MOVEMENT,
  payload
})

export const editTreasuryMovementSuccess = (payload) => ({
  type: treasuryTypes.EDIT_TREASURY_MOVEMENT_SUCCESS,
  payload
})

export const editTreasuryMovementFailed = (payload) => ({
  type: treasuryTypes.EDIT_TREASURY_MOVEMENT_FAILED,
  payload
})

export const deleteTreasuryMovement = (payload) => ({
  type: treasuryTypes.DELETE_TREASURY_MOVEMENT,
  payload
})

export const deleteTreasuryMovementSuccess = (payload) => ({
  type: treasuryTypes.DELETE_TREASURY_MOVEMENT_SUCCESS,
  payload
})
