import { combineEpics } from 'redux-observable'
import * as login from './login'
import * as dashboard from './dashboard'
import * as treasury from './treasury'
import * as utility from './utility'

export const rootEpic = combineEpics(
  login.loginSignInEpic, login.loginGetMyUserEpic,

  dashboard.getDashboardEpic, dashboard.getArchivedDashboardEpic,

  dashboard.archiveClinicalResearchEpic, dashboard.unarchiveClinicalResearchEpic,
  dashboard.archivePaymentEpic, dashboard.unarchivePaymentEpic,
  dashboard.archiveProformaEpic, dashboard.unarchiveProformaEpic,

  dashboard.editClinicalResearchEpic, dashboard.editDoctorEpic, dashboard.editPatientEpic,
  dashboard.editVisitEpic, dashboard.editScheduledVisitEpic, dashboard.editActualVisitEpic,
  dashboard.editProformaEpic, dashboard.editPaymentEpic, dashboard.editInvoiceEpic,
  dashboard.editProcedureEpic, dashboard.editLabKitEpic, dashboard.editAmendmentEpic,
  dashboard.editRevenueEpic, dashboard.editCompanyEpic,
  dashboard.editClinicalResearchTemplateEpic,

  dashboard.deleteClinicalResearchEpic, dashboard.deleteDoctorEpic, dashboard.deletePatientEpic,
  dashboard.deleteVisitEpic, dashboard.deleteScheduledVisitEpic, dashboard.deleteActualVisitEpic,
  dashboard.deleteProformaEpic, dashboard.deletePaymentEpic, dashboard.deleteInvoiceEpic,
  dashboard.deleteProcedureEpic, dashboard.deleteLabKitEpic, dashboard.deleteAmendmentEpic,
  dashboard.deleteRevenueEpic, dashboard.deleteCompanyEpic,

  dashboard.createClinicalResearchEpic, dashboard.createDoctorEpic, dashboard.createPatientEpic,
  dashboard.createVisitEpic, dashboard.createScheduledVisitEpic, dashboard.createActualVisitEpic,
  dashboard.createProformaEpic, dashboard.createPaymentEpic, dashboard.createInvoiceEpic,
  dashboard.createProcedureEpic, dashboard.createLabKitEpic, dashboard.createAmendmentEpic,
  dashboard.createRevenueEpic, dashboard.createCompanyEpic,

  treasury.getTreasuryMovementsEpic,
  treasury.editTreasuryMovementEpic, treasury.createTreasuryMovementEpic, treasury.deleteTreasuryMovementEpic,

  utility.getTasksEpic, utility.editTaskEpic, utility.createTaskEpic, utility.deleteTaskEpic
)
