import { ofType } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { loginTypes } from '../actions'
import { Observable } from 'rxjs'
import { API } from '../config'
import { noAuth, handleAPIError, storeCredentials, jwtAuth } from '../utils'

export const loginSignInEpic = action$ =>
  action$.pipe(
    ofType(loginTypes.LOGIN_SIGN_IN),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/user/login`,
        method: 'POST',
        headers: noAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            const credentials = response
            storeCredentials(credentials)
            if (payload.onSuccess) payload.onSuccess()
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const loginGetMyUserEpic = (action$, state$) =>
  action$.pipe(
    ofType(loginTypes.LOGIN_GET_MY_USER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/user/my`,
        method: 'GET',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            const { user } = response
            storeCredentials({
              token: state$.value.login.token,
              user
            })
            if (payload?.onSuccess) payload.onSuccess()
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            console.error(err)
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )
