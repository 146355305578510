import { dashboardTypes, loginTypes } from '../actions'

const initialState = {
  loading: false,
  clinicalResearches: undefined,
  doctors: undefined,
  patients: undefined,
  visits: undefined,
  scheduledVisits: undefined,
  actualVisits: undefined,
  procedures: undefined,
  labKits: undefined,
  proformas: undefined,
  payments: undefined,
  invoices: undefined,
  amendments: undefined,
  revenues: undefined,
  companies: undefined
}

const handleRemoveTableItemSuccess = (tableState, payload) => {
  return tableState.filter(item => item._id !== payload._id)
}

const handleCreateTableItemSuccess = (tableState, payload) => {
  return [payload, ...tableState]
}

const handleCreateMultipleTableItemSuccess = (tableState, payload) => {
  return [...payload, ...tableState]
}

const handleUpdateTableStart = (tableState, payload) => {
  return tableState.map(item => item._id === payload._id ? { ...item, ...payload.data, _pending: item } : item)
}
const handleUpdateTableSuccess = (tableState, payload) => {
  return tableState.map(item => item._id === payload._id ? payload : item)
}
const handleUpdateTableFailed = (tableState, payload) => {
  return tableState.map(item => item._id === payload._id ? item._pending : item)
}

const dashboard = (state = initialState, { type, payload }) => {
  switch (type) {
    case dashboardTypes.GET_ARCHIVED_DASHBOARD:
    case dashboardTypes.GET_DASHBOARD:
      return {
        ...state,
        loading: true
      }
    case dashboardTypes.GET_ARCHIVED_DASHBOARD_SUCCESS:
    case dashboardTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false
      }
    case dashboardTypes.EDIT_CLINICAL_RESEARCH:
    case dashboardTypes.EDIT_DOCTOR:
    case dashboardTypes.EDIT_PATIENT:
    case dashboardTypes.EDIT_VISIT:
    case dashboardTypes.EDIT_SCHEDULED_VISIT:
    case dashboardTypes.EDIT_ACTUAL_VISIT:
    case dashboardTypes.EDIT_PROFORMA:
    case dashboardTypes.EDIT_PAYMENT:
    case dashboardTypes.EDIT_INVOICE:
    case dashboardTypes.EDIT_PROCEDURE:
    case dashboardTypes.EDIT_LAB_KIT:
    case dashboardTypes.EDIT_AMENDMENT:
    case dashboardTypes.EDIT_COMPANY:
    case dashboardTypes.EDIT_REVENUE:
      return {
        ...state,
        [payload.key]: handleUpdateTableStart(state[payload.key], payload)
      }
    case dashboardTypes.EDIT_CLINICAL_RESEARCH_SUCCESS:
    case dashboardTypes.EDIT_DOCTOR_SUCCESS:
    case dashboardTypes.EDIT_PATIENT_SUCCESS:
    case dashboardTypes.EDIT_VISIT_SUCCESS:
    case dashboardTypes.EDIT_SCHEDULED_VISIT_SUCCESS:
    case dashboardTypes.EDIT_ACTUAL_VISIT_SUCCESS:
    case dashboardTypes.EDIT_PROFORMA_SUCCESS:
    case dashboardTypes.EDIT_PAYMENT_SUCCESS:
    case dashboardTypes.EDIT_INVOICE_SUCCESS:
    case dashboardTypes.EDIT_PROCEDURE_SUCCESS:
    case dashboardTypes.EDIT_LAB_KIT_SUCCESS:
    case dashboardTypes.EDIT_AMENDMENT_SUCCESS:
    case dashboardTypes.EDIT_COMPANY_SUCCESS:
    case dashboardTypes.EDIT_REVENUE_SUCCESS:
      return {
        ...state,
        [payload.key]: handleUpdateTableSuccess(state[payload.key], payload.payload)
      }
    case dashboardTypes.EDIT_CLINICAL_RESEARCH_FAILED:
    case dashboardTypes.EDIT_DOCTOR_FAILED:
    case dashboardTypes.EDIT_PATIENT_FAILED:
    case dashboardTypes.EDIT_VISIT_FAILED:
    case dashboardTypes.EDIT_SCHEDULED_VISIT_FAILED:
    case dashboardTypes.EDIT_ACTUAL_VISIT_FAILED:
    case dashboardTypes.EDIT_PROFORMA_FAILED:
    case dashboardTypes.EDIT_PAYMENT_FAILED:
    case dashboardTypes.EDIT_INVOICE_FAILED:
    case dashboardTypes.EDIT_PROCEDURE_FAILED:
    case dashboardTypes.EDIT_LAB_KIT_FAILED:
    case dashboardTypes.EDIT_AMENDMENT_FAILED:
    case dashboardTypes.EDIT_COMPANY_FAILED:
    case dashboardTypes.EDIT_REVENUE_FAILED:
      return {
        ...state,
        [payload.key]: handleUpdateTableFailed(state[payload.key], payload.payload)
      }
    case dashboardTypes.DELETE_CLINICAL_RESEARCH_SUCCESS:
    case dashboardTypes.DELETE_DOCTOR_SUCCESS:
    case dashboardTypes.DELETE_PATIENT_SUCCESS:
    case dashboardTypes.DELETE_VISIT_SUCCESS:
    case dashboardTypes.DELETE_SCHEDULED_VISIT_SUCCESS:
    case dashboardTypes.DELETE_ACTUAL_VISIT_SUCCESS:
    case dashboardTypes.DELETE_PROFORMA_SUCCESS:
    case dashboardTypes.DELETE_PAYMENT_SUCCESS:
    case dashboardTypes.DELETE_INVOICE_SUCCESS:
    case dashboardTypes.DELETE_PROCEDURE_SUCCESS:
    case dashboardTypes.DELETE_LAB_KIT_SUCCESS:
    case dashboardTypes.DELETE_AMENDMENT_SUCCESS:
    case dashboardTypes.DELETE_COMPANY_SUCCESS:
    case dashboardTypes.DELETE_REVENUE_SUCCESS:
    case dashboardTypes.ARCHIVE_PAYMENT_SUCCESS:
    case dashboardTypes.UNARCHIVE_PAYMENT_SUCCESS:
    case dashboardTypes.ARCHIVE_PROFORMA_SUCCESS:
    case dashboardTypes.UNARCHIVE_PROFORMA_SUCCESS:
      return {
        ...state,
        [payload.key]: handleRemoveTableItemSuccess(state[payload.key], payload.payload)
      }
    case dashboardTypes.CREATE_CLINICAL_RESEARCH_SUCCESS:
    case dashboardTypes.CREATE_DOCTOR_SUCCESS:
    case dashboardTypes.CREATE_PATIENT_SUCCESS:
    case dashboardTypes.CREATE_VISIT_SUCCESS:
    case dashboardTypes.CREATE_SCHEDULED_VISIT_SUCCESS:
    case dashboardTypes.CREATE_ACTUAL_VISIT_SUCCESS:
    case dashboardTypes.CREATE_PROFORMA_SUCCESS:
    case dashboardTypes.CREATE_PAYMENT_SUCCESS:
    case dashboardTypes.CREATE_INVOICE_SUCCESS:
    case dashboardTypes.CREATE_PROCEDURE_SUCCESS:
    case dashboardTypes.CREATE_LAB_KIT_SUCCESS:
    case dashboardTypes.CREATE_AMENDMENT_SUCCESS:
    case dashboardTypes.CREATE_COMPANY_SUCCESS:
    case dashboardTypes.CREATE_REVENUE_SUCCESS:
      return {
        ...state,
        [payload.key]: handleCreateTableItemSuccess(state[payload.key], payload.payload)
      }
    case dashboardTypes.CREATE_MULTIPLE_SCHEDULED_VISITS_SUCCESS:
      return {
        ...state,
        [payload.key]: handleCreateMultipleTableItemSuccess(state[payload.key], payload.payload)
      }
    case dashboardTypes.SET_INITIAL_DASHBOARD:
    case loginTypes.DEAUTHENTICATE:
      return initialState
    default:
      return state
  }
}

export default dashboard
