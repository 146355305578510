import moment from 'moment'
import { store } from '../config'

export const generateAdditionalSVs = ({
  template,
  clinicalResearch,
  visit,
  patient,
  date
}) => {
  if (!template || !clinicalResearch || !visit || !patient || !date) {
    return []
  }
  const { amendments } = store.getState().dashboard
  const nextVisitIndex = (template.visits.indexOf(visit) + 1)
  let lastDate = moment(date)
  const visits = template.visits.slice(nextVisitIndex)
  const daysAfter = template.daysAfter.slice(nextVisitIndex)
  if (!visits.length) return []

  const toGenerate = visits.map((item, index) => {
    lastDate = moment(lastDate).add(daysAfter[index], 'days')
    const _item = {
      clinicalResearch,
      patient,
      visit: item,
      amendment: amendments.filter(amd => amd.visit === item)?.[0]?._id,
      date: lastDate
    }
    return _item
  })

  return toGenerate
}
