import React, { useEffect, useState } from 'react'
import { DashboardContainer, Grid, ReportsGroup, TasksGrid, TreasuryGrid } from '../../../components/dashboard'
import { connect } from 'react-redux'
import { getDashboard } from '../../../actions'
import { REPORTS, TABLES, TASKS, TREASURY_MOVEMENTS } from '../../../config/constants'
import { useNavigate, useParams } from 'react-router-dom'

function Dashboard (props) {
  const [activeTab, setActiveTab] = useState('active')
  const [activeTable, setActiveTable] = useState('reports')
  const navigate = useNavigate()
  const [initialized, setInitialized] = useState(false)
  const tables = Object.values(TABLES)
  const params = useParams()

  useEffect(() => {
    navigate(`/dashboard/${activeTable}`, { replace: true })
  }, [activeTable])

  useEffect(() => {
    if (initialized) return
    const initialTable = params['*']
    setInitialized(true)
    if (tables.includes(initialTable) || initialTable === TREASURY_MOVEMENTS) {
      setActiveTable(initialTable)
    }
  }, [initialized, params])

  useEffect(() => {
    props.getDashboard()
  }, [])

  useEffect(() => {
    if (activeTable === 'reports' && activeTab !== 'active') {
      setActiveTab('active')
      props.getDashboard()
    }
  }, [activeTab, activeTable])

  const renderGrid = () => {
    switch (activeTable) {
      case TABLES.CLINICAL_RESEARCHES:
      case TABLES.DOCTORS:
      case TABLES.PATIENTS:
      case TABLES.VISITS:
      case TABLES.SCHEDULED_VISITS:
      case TABLES.ACTUAL_VISITS:
      case TABLES.PROCEDURES:
      case TABLES.LAB_KITS:
      case TABLES.INVOICES:
      case TABLES.PAYMENTS:
      case TABLES.PROFORMAS:
      case TABLES.REVENUES:
      case TABLES.COMPANIES:
        return (
          <Grid
            tab={activeTab}
            table={activeTable}
          />
        )
      case TREASURY_MOVEMENTS:
        return (
          <TreasuryGrid />
        )
      case REPORTS:
        return (
          <ReportsGroup />
        )
      case TASKS:
        return (
          <TasksGrid />
        )
      default: return (
        <>
          <h1>Under development</h1>
        </>
      )
    }
  }
  return (
    <DashboardContainer
      activeTable={activeTable}
      setActiveTable={setActiveTable}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    >
      {renderGrid()}
    </DashboardContainer>
  )
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
  getDashboard: payload => dispatch(getDashboard(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
