import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { validateDoctor } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import { connect } from 'react-redux'
import { createDoctor } from '../../../actions'

// const EX = {
//   name: ''
// }

function DoctorForm (props) {
  const { onHide } = props

  const [query, setQuery] = useState({})
  const [loading, setLoading] = useState(false)

  const handleUpdateQuery = (field, value) => {
    const _query = validateDoctor({ ...query, [field]: value })

    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createDoctor({
      data: validateDoctor(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const disabled = !query.name
  return (
    <>
      <Row>

        <Col>
          <Form.Label>
            {t('fields.name')}*
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('name', e.target.value)}
          />
        </Col>
      </Row>
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  createDoctor: payload => dispatch(createDoctor(payload))
})

export default connect(null, mapDispatchToProps)(DoctorForm)
