import { createStore, applyMiddleware, compose } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { rootEpic } from '../epics'
import reducers from '../reducers'

const epicMiddleware = createEpicMiddleware()

const middleware = []
middleware.push(epicMiddleware)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middleware))(createStore)

const store = createStoreWithMiddleware(reducers)

epicMiddleware.run(rootEpic)

export { store }
