import React, { useImperativeHandle, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { t } from '../../../config'
import { timeout } from '../../../utils'
import { COLORS } from '../../../config/constants'

const CustomToggle = React.forwardRef((props, ref) => {
  return <span className='fw-medium fst-italic' style={{ paddingLeft: 16, paddingRight: 16 }}>{t('common.select')}...</span>
})

const ColorEditor = React.forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value)

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value
      },
      isPopup: () => {
        return false
      }
    }
  })

  const handleSelectValue = async (item) => {
    setValue(value === item ? undefined : item)
    await timeout(50)
    props.api.stopEditing()
  }

  return (
    <div>
      <Dropdown
        style={{ position: 'fixed' }}
        show
      >
        <Dropdown.Toggle
          as={CustomToggle}
        />

        <Dropdown.Menu
          style={{ maxHeight: '450px', overflowY: 'auto' }}
        >
          {COLORS.map(item => {
            return (
              <Dropdown.Item
                onClick={() => {
                  handleSelectValue(item.color)
                }}
                style={{ backgroundColor: item.color, height: 30 }}
                key={item.color}
              />
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
})

export default ColorEditor
