export const TABLES = {
  CLINICAL_RESEARCHES: 'clinicalResearches',
  DOCTORS: 'doctors',
  PATIENTS: 'patients',
  VISITS: 'visits',
  AMENDMENTS: 'amendments',
  SCHEDULED_VISITS: 'scheduledVisits',
  ACTUAL_VISITS: 'actualVisits',
  PROCEDURES: 'procedures',
  LAB_KITS: 'labKits',
  INVOICES: 'invoices',
  PAYMENTS: 'payments',
  PROFORMAS: 'proformas',
  REVENUES: 'revenues',
  COMPANIES: 'companies'
}
export const TREASURY_MOVEMENTS = 'treasuryMovements'
export const REPORTS = 'reports'
export const TASKS = 'tasks'

export const COLORS = [
  { name: 'All', value: null, color: 'white' },
  { name: 'Yellow', value: 'yellow', color: 'yellow' },
  { name: 'Green', value: 'lightGreen', color: 'lightGreen' },
  { name: 'Pink', value: 'pink', color: 'pink' },
  { name: 'Blue', value: 'skyBlue', color: 'skyBlue' }
]

export const PATIENT_STATUSES = ['active', 'failed', 'completed']
export const INVOICE_CATEGORIES = ['visit', 'fixed', 'reimbursement', 'other']
export const CURRENCIES = ['BGN', 'EUR', 'USD']
export const TREASURY_MOVEMENT_TYPES = ['top-up', 'expense']
export const BANKS = ['UniCredit', 'BGN', 'EUR', 'USD', 'Cash']

export const MULTIPLIER_VAT = 1.2

export const COL_CURRENCY_WIDTH = 115
export const COL_AMENDMENT_WIDTH = 140
export const COL_DATE_WIDTH = 125
