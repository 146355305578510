import _ from 'lodash'
import { archiveClinicalResearch, archivePayment, archiveProforma, deleteActualVisit, deleteClinicalResearch, deleteCompany, deleteDoctor, deleteInvoice, deleteLabKit, deletePatient, deletePayment, deleteProcedure, deleteProforma, deleteRevenue, deleteScheduledVisit, deleteVisit, editActualVisit, editAmendment, editClinicalResearch, editCompany, editDoctor, editInvoice, editLabKit, editPatient, editPayment, editProcedure, editProforma, editRevenue, editScheduledVisit, editVisit, setModalFields, unarchiveClinicalResearch, unarchivePayment, unarchiveProforma } from '../actions'
import { store, t } from '../config'
import { BANKS, COL_AMENDMENT_WIDTH, COL_CURRENCY_WIDTH, COL_DATE_WIDTH, CURRENCIES, INVOICE_CATEGORIES, PATIENT_STATUSES, TABLES } from '../config/constants'
import { validateActualVisit, validateAmendment, validateClinicalResearch, validateCompany, validateDoctor, validateInvoice, validateLabKit, validatePatient, validatePayment, validateProcedure, validateProforma, validateRevenue, validateScheduledVisit, validateVisit } from './validation'
import { ColorEditor, ButtonEditor, RowEditor, SelectEditor } from '../components/cells'
import moment from 'moment'
import { handleDangerConfirm, hasReqLevel } from './helper'

export const getControlColDef = (colId) => {
  const colDef = {
    colId,
    width: 100,
    filter: false,
    sortable: false,
    editable: false,
    cellRenderer: RowEditor
  }
  return colDef
}

export const getButtonColDef = (colId) => {
  const colDef = {
    colId,
    width: 150,
    filter: false,
    sortable: false,
    editable: false,
    cellRenderer: ButtonEditor
  }
  return colDef
}

export const getGridColDefs = (table, tab) => {
  const { dashboard } = store.getState()
  const colDef = `${tab}${table}ColDefId`
  switch (table) {
    case TABLES.CLINICAL_RESEARCHES:
      if (!dashboard.clinicalResearches) return undefined
      return [
        {
          editable: (params) => !params.data.archived && hasReqLevel(2),
          colId: `${tab}ClinicalResearchName`,
          field: 'name',
          cellDataType: 'text'
        },
        {
          editable: (params) => !params.data.archived && hasReqLevel(2),
          colId: `${tab}ClinicalResarchDoctor`,
          field: 'doctor',
          cellDataType: 'text',
          valueGetter: (params) => params.data.doctor,
          valueFormatter: (params) => dashboard.doctors.find(doctor => doctor._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.doctors.find(doctor => doctor._id === params.data.doctor)?.name,
          cellEditor: SelectEditor,
          cellEditorParams: {
            options: dashboard.doctors.map(doctor => ({ label: doctor.name, value: doctor._id }))
          }
        },
        { editable: (params) => !params.data.archived && hasReqLevel(2), colId: `${tab}ClinicalResearchCro`, field: 'cro', cellDataType: 'text', headerName: 'CRO' },
        { editable: (params) => !params.data.archived && hasReqLevel(2), colId: `${tab}ClinicalResearchSponsor`, field: 'sponsor', cellDataType: 'text' },
        // { field: 'status' }, // hide
        // optional
        {
          headerName: t('fields.template'),
          ...getButtonColDef(`${tab}ClinicalResearchTemplate`),
          width: 100,
          cellRendererParams: (params) => ({
            disabled: params.data.archived,
            text: t(params.data.template ? 'common.view' : 'common.create'),
            onClick: () => {
              store.dispatch(setModalFields({
                show: true,
                type: 'cr_template',
                data: params.data
              }))
            }
          })
        },
        { editable: (params) => !params.data.archived && hasReqLevel(2), colId: `${tab}ClinicalResearchInvoiceTo`, field: 'invoiceTo', cellDataType: 'text' },
        { editable: (params) => !params.data.archived && hasReqLevel(2), colId: `${tab}ClinicalResearchReimbursement`, field: 'reimbursement', cellDataType: 'text' },
        { editable: (params) => !params.data.archived && hasReqLevel(2), colId: `${tab}ClinicalResearchNotes`, field: 'notes', cellDataType: 'text' },
        { editable: (params) => !params.data.archived && hasReqLevel(2), colId: `${tab}ClinicalResearchStage`, field: 'stage', cellDataType: 'text' },
        { editable: (params) => !params.data.archived && hasReqLevel(2), colId: `${tab}ClinicalResearchDaysOfPatientInCR`, field: 'daysOfPatientInCR', cellDataType: 'text' },
        { editable: (params) => !params.data.archived && hasReqLevel(2), colId: `${tab}ClinicalResearchDrugDescription`, field: 'drugDescription', cellDataType: 'text' },
        { editable: (params) => !params.data.archived && hasReqLevel(2), colId: `${tab}ClinicalResearchStructureOfStudy`, field: 'structureOfStudy', cellDataType: 'text' },
        { editable: (params) => !params.data.archived && hasReqLevel(2), colId: `${tab}ClinicalResearchDisease`, field: 'disease', cellDataType: 'text' },
        { editable: (params) => !params.data.archived && hasReqLevel(2), colId: `${tab}ClinicalResearchPhase`, field: 'phase', cellDataType: 'text' },
        hasReqLevel(2) && {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onUnarchive: params.data.archived && hasReqLevel(3)
              ? () => {
                  handleDangerConfirm({
                    text: t('dashboard.unarchiveCR'),
                    onConfirm: () => {
                      store.dispatch(unarchiveClinicalResearch({ _id: params.data._id }))
                    }
                  })
                }
              : null,
            onArchive: !params.data.archived && hasReqLevel(3)
              ? () => {
                  handleDangerConfirm({
                    text: t('dashboard.archiveCR'),
                    onConfirm: () => {
                      store.dispatch(archiveClinicalResearch({ _id: params.data._id }))
                    }
                  })
                }
              : null,
            onDelete: !params.data.archived
              ? () => {
                  handleDangerConfirm({
                    onConfirm: () => {
                      store.dispatch(deleteClinicalResearch({ _id: params.data._id }))
                    }
                  })
                }
              : null
          })
        }
      ].filter(item => item)
    case TABLES.DOCTORS:
      if (!dashboard.doctors) return undefined
      return [
        { editable: hasReqLevel(2), colId: `${tab}DoctorName`, field: 'name', cellDataType: 'text', flex: 1 },
        tab === 'active' && hasReqLevel(2) && {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onDelete: () => {
              handleDangerConfirm({
                onConfirm: () => {
                  store.dispatch(deleteDoctor({ _id: params.data._id }))
                }
              })
            }
          })
        }
      ].filter(item => item)
    case TABLES.COMPANIES:
      if (!dashboard.companies) return undefined
      return [
        { editable: true, colId: `${tab}CompanyName`, field: 'name', cellDataType: 'text' },
        { editable: true, colId: `${tab}CompanyNotes`, field: 'notes', cellDataType: 'text' },
        tab === 'active' && {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onDelete: () => {
              handleDangerConfirm({
                onConfirm: () => {
                  store.dispatch(deleteCompany({ _id: params.data._id }))
                }
              })
            }
          })
        }
      ].filter(item => item)
    case TABLES.PATIENTS:
      if (!dashboard.patients) return undefined
      return [
        {
          editable: false,
          colId: `${tab}PatientClinicalResearch`,
          field: 'clinicalResearch',
          cellDataType: 'text',
          valueFormatter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.data.clinicalResearch)?.name
        },
        { editable: (params) => !params.data.archived, colId: `${tab}PatientName`, field: 'name', cellDataType: 'text' },
        { editable: (params) => !params.data.archived, colId: `${tab}PatientIdentifier`, field: 'identifier', cellDataType: 'text' },
        {
          editable: (params) => !params.data.archived,
          colId: `${tab}PatientStatus`,
          field: 'status',
          cellDataType: 'text',
          valueGetter: (params) => params.data.status,
          valueFormatter: (params) => _.capitalize(params.data.status),
          cellEditor: SelectEditor,
          cellEditorParams: {
            options: PATIENT_STATUSES.map(status => ({ label: _.capitalize(status), value: status }))
          }
        },
        { editable: (params) => !params.data.archived, colId: `${tab}PatientNotes`, field: 'notes', cellDataType: 'text' },
        tab === 'active' && {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onDelete: () => {
              handleDangerConfirm({
                onConfirm: () => {
                  store.dispatch(deletePatient({ _id: params.data._id }))
                }
              })
            }
          })
        }
      ].filter(item => item)
    case TABLES.VISITS:
      if (!dashboard.visits) return undefined
      return [
        {
          editable: false,
          colId: `${tab}VisitClinicalResearch`,
          field: 'clinicalResearch',
          cellDataType: 'text',
          valueFormatter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.data.clinicalResearch)?.name
        },
        { editable: (params) => !params.data.archived && hasReqLevel(2), colId: `${tab}VisitReason`, field: 'reason', cellDataType: 'text' },
        // hasReqLevel(2) && {
        //   editable: false,
        //   colId: `${tab}VisitBudgetMhat`,
        //   headerName: t('fields.latestBudgetMhat'),
        //   cellDataType: 'number',
        //   valueFormatter: (params) => {
        //     const amendments = dashboard.amendments.filter(amendment => amendment.visit === params.data._id)
        //     if (!amendments.length) return ''
        //     return Number(amendments[0].budgetMhat).toFixed(2)
        //   },
        //   filterValueGetter: (params) => {
        //     const amendments = dashboard.amendments.filter(amendment => amendment.visit === params.data._id)
        //     if (!amendments.length) return ''
        //     return Number(amendments[0].budgetMhat).toFixed(2)
        //   }
        // },
        // hasReqLevel(2) && {
        //   editable: false,
        //   colId: `${tab}VisitBudgetPI`,
        //   headerName: t('fields.latestBudgetPI'),
        //   cellDataType: 'number',
        //   valueFormatter: (params) => {
        //     const amendments = dashboard.amendments.filter(amendment => amendment.visit === params.data._id)
        //     if (!amendments.length) return ''
        //     return Number(amendments[0]?.budgetPI).toFixed(2)
        //   },
        //   filterValueGetter: (params) => {
        //     const amendments = dashboard.amendments.filter(amendment => amendment.visit === params.data._id)
        //     if (!amendments.length) return ''
        //     return Number(amendments[0]?.budgetPI).toFixed(2)
        //   }
        // },
        hasReqLevel(2) && {
          editable: (params) => !params.data.archived,
          colId: `${tab}VisitCurrency`,
          field: 'currency',
          width: COL_CURRENCY_WIDTH,
          cellDataType: 'text',
          cellEditor: SelectEditor,
          cellEditorParams: {
            options: CURRENCIES.map(currency => ({ label: currency, value: currency }))
          }
        },
        {
          headerName: t('fields.amendments'),
          ...getButtonColDef('amendmentsButtonColId'),
          cellRendererParams: (params) => ({
            text: t('common.view'),
            onClick: () => {
              store.dispatch(setModalFields({
                show: true,
                type: TABLES.AMENDMENTS,
                data: params.data
              }))
            },
            disabledText: t('common.view')
          })
        },
        tab === 'active' && hasReqLevel(2) && {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onDelete: () => {
              handleDangerConfirm({
                onConfirm: () => {
                  store.dispatch(deleteVisit({ _id: params.data._id }))
                }
              })
            }
          })
        }
      ].filter(item => item)
    case TABLES.SCHEDULED_VISITS:
      if (!dashboard.scheduledVisits) return undefined
      return [
        {
          editable: false,
          colId: `${tab}ScheduledVisitClinicalResearch`,
          field: 'clinicalResearch',
          cellDataType: 'text',
          valueFormatter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.data.clinicalResearch)?.name
        },
        {
          editable: false,
          colId: `${tab}ScheduledVisitVisit`,
          headerName: t('fields.visit'),
          field: 'visit',
          cellDataType: 'text',
          valueGetter: (params) => params.data.visit,
          valueFormatter: (params) => {
            const visit = dashboard.visits.find(visit => visit._id === params.value)
            if (!visit) return ''
            return visit.reason
          },
          filterValueGetter: (params) => {
            const visit = dashboard.visits.find(visit => visit._id === params.data.visit)
            if (!visit) return ''
            return visit.reason
          }
        },
        {
          editable: (params) => !params.data.archived,
          colId: `${tab}ScheduledVisitAmendment`,
          headerName: t('fields.amendment'),
          field: 'amendment',
          width: COL_AMENDMENT_WIDTH,
          cellDataType: 'text',
          valueGetter: (params) => params.data.amendment,
          valueFormatter: (params) => {
            const amendment = dashboard.amendments.find(item => item._id === params.data.amendment)
            if (!amendment) return ''
            return amendment.name
          },
          filterValueGetter: (params) => {
            const amendment = dashboard.amendments.find(item => item._id === params.data.amendment)
            if (!amendment) return ''
            return amendment.name
          },
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            options: dashboard.amendments.filter(item => item.visit === params.data.visit).map(item => ({ label: item.name, value: item._id }))
          })
        },
        {
          editable: (params) => !params.data.archived,
          colId: `${tab}ScheduledVisitPatient`,
          field: 'patient',
          cellDataType: 'text',
          valueGetter: (params) => params.data.patient,
          valueFormatter: (params) => dashboard.patients.find(patient => patient._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.patients.find(patient => patient._id === params.data.patient)?.name,
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            options: dashboard.patients.filter(patient => patient.clinicalResearch === params.data.clinicalResearch).map(patient => ({ label: patient.name, value: patient._id }))
          })
        },
        {
          editable: false,
          colId: `${tab}ScheduledVisitPatientIdentifier`,
          field: 'patient',
          headerName: t('fields.patientID'),
          cellDataType: 'text',
          valueGetter: (params) => params.data.patient,
          valueFormatter: (params) => dashboard.patients.find(patient => patient._id === params.value)?.identifier,
          filterValueGetter: (params) => dashboard.patients.find(patient => patient._id === params.data.patient)?.identifier
        },
        {
          editable: (params) => !params.data.archived,
          colId: `${tab}ScheduledVisitDate`,
          field: 'date',
          width: COL_DATE_WIDTH,
          cellDataType: 'date',
          valueGetter: (params) => moment(params.data.date).toDate(),
          valueFormatter: (params) => moment(params.data.date).format('DD MMM YYYY')
        },
        tab === 'active' && {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onDelete: () => {
              handleDangerConfirm({
                onConfirm: () => {
                  store.dispatch(deleteScheduledVisit({ _id: params.data._id }))
                }
              })
            }
          })
        }
      ].filter(item => item)
    case TABLES.ACTUAL_VISITS:
      if (!dashboard.actualVisits) return undefined
      return [
        // {
        //   filter: false,
        //   sortable: false,
        //   width: 70,
        //   editable: (params) => !params.data.archived && !params.data.completed,
        //   colId: `${tab}ActualVisitColor`,
        //   field: 'color',
        //   cellDataType: 'text',
        //   cellEditor: ColorEditor,
        //   valueFormatter: () => ''
        // },
        {
          editable: false,
          colId: `${tab}ActualVisitClinicalResearch`,
          field: 'clinicalResearch',
          cellDataType: 'text',
          valueFormatter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.data.clinicalResearch)?.name
        },
        {
          editable: false,
          colId: `${tab}ActualVisitVisit`,
          headerName: t('fields.visit'),
          field: 'visit',
          cellDataType: 'text',
          valueGetter: (params) => params.data.visit,
          valueFormatter: (params) => {
            const visit = dashboard.visits.find(visit => visit._id === params.value)
            if (!visit) return ''
            return visit.reason
          },
          filterValueGetter: (params) => {
            const visit = dashboard.visits.find(visit => visit._id === params.data.visit)
            if (!visit) return ''
            return visit.reason
          }
        },
        {
          editable: (params) => !params.data.archived && !dashboard.invoices.find(item => (item._id === params.data.visitInvoice && item.completed) || (item._id === params.data.reimbursementInvoice && item.completed)),
          colId: `${tab}ActualVisitAmendment`,
          headerName: t('fields.amendment'),
          field: 'amendment',
          width: COL_AMENDMENT_WIDTH,
          cellDataType: 'text',
          valueGetter: (params) => params.data.amendment,
          valueFormatter: (params) => {
            const amendment = dashboard.amendments.find(item => item._id === params.data.amendment)
            if (!amendment) return ''
            return amendment.name
          },
          filterValueGetter: (params) => {
            const amendment = dashboard.amendments.find(item => item._id === params.data.amendment)
            if (!amendment) return ''
            return amendment.name
          },
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            options: dashboard.amendments.filter(item => item.visit === params.data.visit).map(item => ({ label: item.name, value: item._id }))
          })
        },
        {
          editable: (params) => !params.data.archived && !dashboard.invoices.find(item => (item._id === params.data.visitInvoice && item.completed) || (item._id === params.data.reimbursementInvoice && item.completed)),
          colId: `${tab}ActualVisitPatient`,
          field: 'patient',
          cellDataType: 'text',
          valueGetter: (params) => params.data.patient,
          valueFormatter: (params) => dashboard.patients.find(patient => patient._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.patients.find(patient => patient._id === params.data.patient)?.name,
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            options: dashboard.patients.filter(patient => patient.clinicalResearch === params.data.clinicalResearch).map(patient => ({ label: patient.name, value: patient._id }))
          })
        },
        {
          editable: false,
          colId: `${tab}ActualVisitPatientIdentifier`,
          field: 'patient',
          headerName: t('fields.patientID'),
          cellDataType: 'text',
          valueGetter: (params) => params.data.patient,
          valueFormatter: (params) => dashboard.patients.find(patient => patient._id === params.value)?.identifier,
          filterValueGetter: (params) => dashboard.patients.find(patient => patient._id === params.data.patient)?.identifier
        },
        {
          editable: (params) => !params.data.archived,
          colId: `${tab}ActualVisitDate`,
          field: 'date',
          width: COL_DATE_WIDTH,
          cellDataType: 'date',
          valueGetter: (params) => moment(params.data.date).toDate(),
          valueFormatter: (params) => moment(params.data.date).format('DD MMM YYYY')
        },
        {
          editable: (params) => !params.data.archived && !dashboard.invoices.find(item => item._id === params.data.reimbursementInvoice && item.completed),
          colId: `${tab}ActualVisitReimbursement`,
          field: 'reimbursement',
          cellDataType: 'number',
          valueFormatter: (params) => Number(params.data.reimbursement).toFixed(2)
        },
        // {
        //   editable: (params) => !params.data.archived,
        //   colId: `${tab}ActualVisitCurrency`,
        //   field: 'currency',
        //   cellDataType: 'text',
        //   cellEditor: SelectEditor,
        //   cellEditorParams: {
        //     options: CURRENCIES.map(currency => ({ label: currency, value: currency }))
        //   }
        // },
        hasReqLevel(2) && {
          editable: (params) => !params.data.archived && !dashboard.invoices.find(item => item._id === params.data.visitInvoice && item.completed),
          colId: `${tab}ActualVisitVisitInvoice`,
          field: 'visitInvoice',
          cellDataType: 'text',
          valueGetter: (params) => params.data.visitInvoice,
          valueFormatter: (params) => dashboard.invoices.find(invoice => invoice._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.invoices.find(invoice => invoice._id === params.data.visitInvoice)?.name,
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            canBeEmpty: true,
            options: dashboard.invoices.filter(invoice => invoice.clinicalResearches.includes(params.data.clinicalResearch) && !invoice.completed).map(invoice => ({ label: invoice.name, value: invoice._id }))
          })
        },
        hasReqLevel(2) && {
          editable: (params) => !params.data.archived && !dashboard.invoices.find(item => item._id === params.data.reimbursementInvoice && item.completed),
          colId: `${tab}ActualVisitReimbursementInvoice`,
          field: 'reimbursementInvoice',
          cellDataType: 'text',
          valueGetter: (params) => params.data.reimbursementInvoice,
          valueFormatter: (params) => dashboard.invoices.find(invoice => invoice._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.invoices.find(invoice => invoice._id === params.data.reimbursementInvoice)?.name,
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            canBeEmpty: true,
            options: dashboard.invoices.filter(invoice => invoice.clinicalResearches.includes(params.data.clinicalResearch) && !invoice.completed).map(invoice => ({ label: invoice.name, value: invoice._id }))
          })
        },
        { editable: (params) => !params.data.archived, colId: `${tab}ActualVisitNotes`, field: 'notes', cellDataType: 'text' },
        tab === 'active' && {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onDelete: !dashboard.invoices.find(item => (item._id === params.data.visitInvoice && item.completed) || (item._id === params.data.reimbursementInvoice && item.completed))
              ? () => {
                  handleDangerConfirm({
                    onConfirm: () => {
                      store.dispatch(deleteActualVisit({ _id: params.data._id }))
                    }
                  })
                }
              : null
          })
        }
      ].filter(item => item)
    case TABLES.INVOICES:
      if (!dashboard.invoices) return undefined
      return [
        {
          filter: false,
          sortable: false,
          width: 70,
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}InvoiceColor`,
          field: 'color',
          cellDataType: 'text',
          cellEditor: ColorEditor,
          valueFormatter: () => ''
        },
        {
          editable: false,
          colId: `${tab}InvoiceClinicalResearches`,
          field: 'clinicalResearches',
          cellDataType: 'text',
          valueFormatter: (params) => params.data.clinicalResearches?.map(clinicalResearch => dashboard.clinicalResearches.find(cr => cr._id === clinicalResearch)?.name || (tab === 'active' ? '_ARCHIVED_CR_' : '_ACTIVE_CR_'))?.join('; '),
          filterValueGetter: (params) => params.data.clinicalResearches?.map(clinicalResearch => dashboard.clinicalResearches.find(cr => cr._id === clinicalResearch)?.name || (tab === 'active' ? '_ARCHIVED_CR_' : '_ACTIVE_CR_'))?.join('; ')
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}InvoiceName`,
          field: 'name',
          cellDataType: 'text'
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}InvoiceNotes`,
          field: 'notes',
          cellDataType: 'text'
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}InvoiceDate`,
          field: 'date',
          width: COL_DATE_WIDTH,
          cellDataType: 'date',
          valueGetter: (params) => moment(params.data.date).toDate(),
          valueFormatter: (params) => moment(params.data.date).format('DD MMM YYYY')
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}InvoiceAmount`,
          field: 'amount',
          cellDataType: 'number',
          valueFormatter: (params) => Number(params.data.amount).toFixed(2)
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}InvoiceCurrency`,
          field: 'currency',
          headerName: 'Curr.',
          width: 80,
          cellDataType: 'text',
          cellEditor: SelectEditor,
          cellEditorParams: {
            options: CURRENCIES.map(currency => ({ label: currency, value: currency }))
          }
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed && !dashboard.payments.find(payment => payment._id === params.data.payment)?.completed,
          colId: `${tab}InvoicePayment`,
          field: 'payment',
          cellDataType: 'text',
          valueGetter: (params) => params.data.payment,
          valueFormatter: (params) => params.data.payment ? (dashboard.payments.find(p => p._id === params.data.payment)?.reason || (tab === 'active' ? '_ARCHIVED_PAYMENT_' : '_ACTIVE_PAYMENT_')) : '',
          filterValueGetter: (params) => params.data.payment ? (dashboard.payments.find(p => p._id === params.data.payment)?.reason || (tab === 'active' ? '_ARCHIVED_PAYMENT_' : '_ACTIVE_PAYMENT_')) : '',
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            canBeEmpty: true,
            options: dashboard.payments
              .filter(payment => !payment.completed)
              .map(payment => ({ label: payment.reason, value: payment._id }))
          })
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}InvoiceReceived`,
          headerName: 'Rec.',
          width: 80,
          field: 'received',
          cellDataType: 'boolean'
        },
        { editable: (params) => !params.data.archived && hasReqLevel(3), colId: `${tab}InvoiceCompleted`, width: 95, headerName: 'Comp.', field: 'completed', cellDataType: 'boolean' },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}InvoiceCompany`,
          field: 'company',
          cellDataType: 'text',
          headerName: t('fields.billTo'),
          valueGetter: (params) => params.data.company,
          valueFormatter: (params) => dashboard.companies.find(company => company._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.companies.find(company => company._id === params.data.company)?.name,
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            options: dashboard.companies.slice(0)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(company => ({ label: company.name, value: company._id })),
            canBeEmpty: true
          })
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}InvoiceCategory`,
          field: 'category',
          cellDataType: 'text',
          width: 135,
          valueGetter: (params) => params.data.category,
          valueFormatter: (params) => _.capitalize(params.data.category),
          cellEditor: SelectEditor,
          cellEditorParams: {
            options: INVOICE_CATEGORIES.map(category => ({ label: _.capitalize(category), value: category }))
          }
        },
        hasReqLevel(3) && {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}InvoiceBonus`,
          field: 'bonus',
          cellDataType: 'text'
        },
        tab === 'active' && {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onDelete: !params.data.completed
              ? () => {
                  handleDangerConfirm({
                    onConfirm: () => {
                      store.dispatch(deleteInvoice({ _id: params.data._id }))
                    }
                  })
                }
              : null
          })
        }
      ].filter(item => item)
    case TABLES.PAYMENTS:
      if (!dashboard.payments) return undefined
      return [
        {
          filter: false,
          sortable: false,
          width: 70,
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}InvoiceColor`,
          field: 'color',
          cellDataType: 'text',
          cellEditor: ColorEditor,
          valueFormatter: () => ''
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}PaymentDate`,
          field: 'date',
          width: COL_DATE_WIDTH,
          cellDataType: 'date',
          valueGetter: (params) => moment(params.data.date).toDate(),
          valueFormatter: (params) => moment(params.data.date).format('DD MMM YYYY')
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}PaymentReason`,
          field: 'reason',
          cellDataType: 'text'
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}PaymentAmount`,
          field: 'amount',
          cellDataType: 'number',
          valueFormatter: (params) => Number(params.data.amount).toFixed(2)
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}PaymentVAT`,
          field: 'VAT',
          width: 85,
          cellDataType: 'boolean'
        },
        {
          editable: false,
          colId: `${tab}PaymentTotal`,
          field: 'total',
          cellDataType: 'number',
          valueFormatter: (params) => Number(params.data.total).toFixed(2)
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}PaymentCurrency`,
          field: 'currency',
          headerName: 'Curr.',
          width: 80,
          cellDataType: 'text',
          cellEditor: SelectEditor,
          cellEditorParams: {
            options: CURRENCIES.map(currency => ({ label: currency, value: currency }))
          }
        },
        { editable: (params) => !params.data.archived && hasReqLevel(3), colId: `${tab}PaymentCompleted`, width: 95, headerName: 'Comp.', field: 'completed', cellDataType: 'boolean' },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}PaymentCompany`,
          field: 'company',
          cellDataType: 'text',
          headerName: t('fields.billTo'),
          valueGetter: (params) => params.data.company,
          valueFormatter: (params) => dashboard.companies.find(company => company._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.companies.find(company => company._id === params.data.company)?.name,
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            options: dashboard.companies.slice(0)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(company => ({ label: company.name, value: company._id })),
            canBeEmpty: true
          })
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}PaymentBank`,
          field: 'bank',
          width: 115,
          cellDataType: 'text',
          headerName: t('fields.bank'),
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            options: BANKS.map(bank => ({ label: bank, value: bank }))
          })
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}PaymentClinicalResearch`,
          field: 'clinicalResearch',
          cellDataType: 'text',
          valueGetter: (params) => params.data.clinicalResearch,
          valueFormatter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.data.clinicalResearch)?.name,
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            canBeEmpty: true,
            options: dashboard.clinicalResearches
              .map(cr => ({ label: cr.name, value: cr._id }))
          })
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}PaymentNotes`,
          field: 'notes',
          cellDataType: 'text'
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}PaymentProformas`,
          field: 'proformas',
          cellDataType: 'text',
          valueGetter: (params) => params.data.proformas,
          valueFormatter: (params) => params.data.proformas?.map(proforma => dashboard.proformas.find(pf => pf._id === proforma)?.reason || (tab === 'active' ? '_ARCHIVED_PROFORMA_' : '_ACTIVE_PROFORMA_'))?.join('; '),
          filterValueGetter: (params) => params.data.proformas?.map(proforma => dashboard.proformas.find(pf => pf._id === proforma)?.reason || (tab === 'active' ? '_ARCHIVED_PROFORMA_' : '_ACTIVE_PROFORMA_'))?.join('; '),
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            multiple: true,
            options: dashboard.proformas
              .filter(proforma => !proforma.completed)
              .map(proforma => ({ label: proforma.reason, value: proforma._id }))
          })
        },
        {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onUnarchive: params.data.archived && hasReqLevel(3)
              ? () => {
                  handleDangerConfirm({
                    text: t('dashboard.unarchivePayment'),
                    onConfirm: () => {
                      store.dispatch(unarchivePayment({ _id: params.data._id }))
                    }
                  })
                }
              : null,
            onArchive: !params.data.archived && hasReqLevel(3)
              ? () => {
                  handleDangerConfirm({
                    text: t('dashboard.archivePayment'),
                    onConfirm: () => {
                      store.dispatch(archivePayment({ _id: params.data._id }))
                    }
                  })
                }
              : null,
            onDelete: !params.data.archived && !params.data.completed
              ? () => {
                  handleDangerConfirm({
                    onConfirm: () => {
                      store.dispatch(deletePayment({ _id: params.data._id }))
                    }
                  })
                }
              : null
          })
        }
      ].filter(item => item)
    case TABLES.PROFORMAS:
      if (!dashboard.proformas) return undefined
      return [
        {
          filter: false,
          sortable: false,
          width: 70,
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}InvoiceColor`,
          field: 'color',
          cellDataType: 'text',
          cellEditor: ColorEditor,
          valueFormatter: () => ''
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}ProformaDate`,
          field: 'date',
          width: COL_DATE_WIDTH,
          cellDataType: 'date',
          valueGetter: (params) => moment(params.data.date).toDate(),
          valueFormatter: (params) => moment(params.data.date).format('DD MMM YYYY')
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}ProformaReason`,
          field: 'reason',
          cellDataType: 'text'
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}ProformaAmount`,
          field: 'amount',
          cellDataType: 'number',
          valueFormatter: (params) => Number(params.data.amount).toFixed(2)
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}ProformaVAT`,
          field: 'VAT',
          width: 85,
          cellDataType: 'boolean'
        },
        {
          editable: false,
          colId: `${tab}ProformaTotal`,
          field: 'total',
          cellDataType: 'number',
          valueFormatter: (params) => Number(params.data.total).toFixed(2)
        },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}ProformaCurrency`,
          field: 'currency',
          width: COL_CURRENCY_WIDTH,
          cellDataType: 'text',
          cellEditor: SelectEditor,
          cellEditorParams: {
            options: CURRENCIES.map(currency => ({ label: currency, value: currency }))
          }
        },
        { editable: (params) => !params.data.archived && hasReqLevel(3), colId: `${tab}ProformaCompleted`, width: 95, headerName: 'Comp.', field: 'completed', cellDataType: 'boolean' },
        {
          editable: (params) => !params.data.archived && !params.data.completed,
          colId: `${tab}ProformaNotes`,
          field: 'notes',
          cellDataType: 'text'
        },
        {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onUnarchive: params.data.archived && hasReqLevel(3)
              ? () => {
                  handleDangerConfirm({
                    text: t('dashboard.unarchiveProforma'),
                    onConfirm: () => {
                      store.dispatch(unarchiveProforma({ _id: params.data._id }))
                    }
                  })
                }
              : null,
            onArchive: !params.data.archived && hasReqLevel(3)
              ? () => {
                  handleDangerConfirm({
                    text: t('dashboard.archiveProforma'),
                    onConfirm: () => {
                      store.dispatch(archiveProforma({ _id: params.data._id }))
                    }
                  })
                }
              : null,
            onDelete: !params.data.archived && !params.data.completed
              ? () => {
                  handleDangerConfirm({
                    onConfirm: () => {
                      store.dispatch(deleteProforma({ _id: params.data._id }))
                    }
                  })
                }
              : null
          })
        }
      ].filter(item => item)
    case TABLES.PROCEDURES:
      if (!dashboard.procedures) return undefined
      return [
        {
          editable: false,
          colId: `${tab}procedureClinicalResearch`,
          field: 'clinicalResearch',
          cellDataType: 'text',
          valueFormatter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.data.clinicalResearch)?.name
        },
        {
          editable: (params) => !params.data.archived,
          colId: `${tab}procedureName`,
          field: 'name',
          cellDataType: 'text'
        },
        {
          editable: (params) => !params.data.archived,
          colId: `${tab}procedureVisits`,
          field: 'visits',
          headerName: t('fields.visits'),
          cellDataType: 'text',
          valueGetter: (params) => params.data.visits,
          valueFormatter: (params) => params.data.visits?.map(visit => dashboard.visits.find(v => v._id === visit)?.reason)?.join('; '),
          filterValueGetter: (params) => params.data.visits?.map(visit => dashboard.visits.find(v => v._id === visit)?.reason)?.join('; '),
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            multiple: true,
            options: dashboard.visits
              .filter(visit => visit.clinicalResearch === params.data.clinicalResearch)
              .map(visit => ({ label: visit.reason, value: visit._id }))
          })
        },
        tab === 'active' && {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onDelete: () => {
              handleDangerConfirm({
                onConfirm: () => {
                  store.dispatch(deleteProcedure({ _id: params.data._id }))
                }
              })
            }
          })
        }
      ].filter(item => item)
    case TABLES.LAB_KITS:
      if (!dashboard.labKits) return undefined
      return [
        {
          editable: false,
          colId: `${tab}labKitClinicalResearch`,
          field: 'clinicalResearch',
          cellDataType: 'text',
          valueFormatter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.data.clinicalResearch)?.name
        },
        {
          editable: (params) => !params.data.archived,
          colId: `${tab}labKitName`,
          field: 'name',
          cellDataType: 'text'
        },
        {
          editable: (params) => !params.data.archived,
          colId: `${tab}labKitVisits`,
          field: 'visits',
          headerName: t('fields.visits'),
          cellDataType: 'text',
          valueGetter: (params) => params.data.visits,
          valueFormatter: (params) => params.data.visits?.map(visit => dashboard.visits.find(v => v._id === visit)?.reason)?.join('; '),
          filterValueGetter: (params) => params.data.visits?.map(visit => dashboard.visits.find(v => v._id === visit)?.reason)?.join('; '),
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            multiple: true,
            options: dashboard.visits
              .filter(visit => visit.clinicalResearch === params.data.clinicalResearch)
              .map(visit => ({ label: visit.reason, value: visit._id }))
          })
        },
        tab === 'active' && {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onDelete: () => {
              handleDangerConfirm({
                onConfirm: () => {
                  store.dispatch(deleteLabKit({ _id: params.data._id }))
                }
              })
            }
          })
        }
      ].filter(item => item)
    case TABLES.REVENUES:
      if (!dashboard.revenues) return undefined
      return [
        {
          editable: false,
          colId: `${tab}RevenueClinicalResearch`,
          field: 'clinicalResearch',
          cellDataType: 'text',
          valueFormatter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.clinicalResearches.find(clinicalResearch => clinicalResearch._id === params.data.clinicalResearch)?.name
        },
        { editable: (params) => !params.data.archived, colId: `${tab}RevenueName`, field: 'name', cellDataType: 'text' },
        {
          editable: (params) => !params.data.archived && !dashboard.invoices.find(item => item._id === params.data.invoice && item.completed),
          colId: `${tab}RevenueAmount`,
          field: 'amount',
          cellDataType: 'number',
          valueFormatter: (params) => Number(params.data.amount).toFixed(2)
        },
        {
          editable: (params) => !params.data.archived && !dashboard.invoices.find(item => item._id === params.data.invoice && item.completed),
          colId: `${tab}RevenueCurrency`,
          field: 'currency',
          width: COL_CURRENCY_WIDTH,
          cellDataType: 'text',
          cellEditor: SelectEditor,
          cellEditorParams: {
            options: CURRENCIES.map(currency => ({ label: currency, value: currency }))
          }
        },
        {
          editable: (params) => !params.data.archived && !dashboard.invoices.find(item => item._id === params.data.invoice && item.completed),
          colId: `${tab}RevenueInvoice`,
          field: 'invoice',
          cellDataType: 'text',
          valueGetter: (params) => params.data.invoice,
          valueFormatter: (params) => dashboard.invoices.find(invoice => invoice._id === params.value)?.name,
          filterValueGetter: (params) => dashboard.invoices.find(invoice => invoice._id === params.data.invoice)?.name,
          cellEditor: SelectEditor,
          cellEditorParams: (params) => ({
            canBeEmpty: true,
            options: dashboard.invoices.filter(invoice => invoice.clinicalResearches.includes(params.data.clinicalResearch) && !invoice.completed).map(invoice => ({ label: invoice.name, value: invoice._id }))
          })
        },
        tab === 'active' && {
          ...getControlColDef(colDef),
          cellRendererParams: (params) => ({
            onDelete: !dashboard.invoices.find(item => (item._id === params.data.invoice && item.completed))
              ? () => {
                  handleDangerConfirm({
                    onConfirm: () => {
                      store.dispatch(deleteRevenue({ _id: params.data._id }))
                    }
                  })
                }
              : null
          })
        }
      ].filter(item => item)
    default: return []
  }
}

export const getGridRowData = (table) => {
  const { dashboard } = store.getState()
  switch (table) {
    case TABLES.CLINICAL_RESEARCHES:
      return dashboard.clinicalResearches
    case TABLES.DOCTORS:
      return dashboard.doctors
    case TABLES.PATIENTS:
      return dashboard.patients
    case TABLES.VISITS:
      return dashboard.visits
    case TABLES.SCHEDULED_VISITS:
      return dashboard.scheduledVisits
    case TABLES.ACTUAL_VISITS:
      return dashboard.actualVisits
    case TABLES.INVOICES:
      return dashboard.invoices
    case TABLES.PAYMENTS:
      return dashboard.payments
    case TABLES.PROFORMAS:
      return dashboard.proformas
    case TABLES.PROCEDURES:
      return dashboard.procedures
    case TABLES.LAB_KITS:
      return dashboard.labKits
    case TABLES.REVENUES:
      return dashboard.revenues
    case TABLES.COMPANIES:
      return dashboard.companies
    default: return []
  }
}

export const handleCellEditRequest = (table, event) => {
  let data = {}
  switch (table) {
    case TABLES.CLINICAL_RESEARCHES:
      data = validateClinicalResearch({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editClinicalResearch({
        _id: event.data._id,
        key: 'clinicalResearches',
        data
      }))
      break
    case TABLES.DOCTORS:
      data = validateDoctor({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editDoctor({
        _id: event.data._id,
        key: 'doctors',
        data
      }))
      break
    case TABLES.PATIENTS:
      data = validatePatient({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editPatient({
        _id: event.data._id,
        key: 'patients',
        data
      }))
      break
    case TABLES.VISITS:
      data = validateVisit({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editVisit({
        _id: event.data._id,
        key: 'visits',
        data
      }))
      break
    case TABLES.AMENDMENTS:
      data = validateAmendment({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editAmendment({
        _id: event.data._id,
        key: 'amendments',
        data
      }))
      break
    case TABLES.SCHEDULED_VISITS:
      data = validateScheduledVisit({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editScheduledVisit({
        _id: event.data._id,
        key: 'scheduledVisits',
        data
      }))
      break
    case TABLES.ACTUAL_VISITS:
      data = validateActualVisit({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editActualVisit({
        _id: event.data._id,
        key: 'actualVisits',
        data
      }))
      break
    case TABLES.INVOICES:
      data = validateInvoice({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editInvoice({
        _id: event.data._id,
        key: 'invoices',
        data
      }))
      break
    case TABLES.PAYMENTS:
      data = validatePayment({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editPayment({
        _id: event.data._id,
        key: 'payments',
        data
      }))
      break
    case TABLES.PROFORMAS:
      data = validateProforma({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editProforma({
        _id: event.data._id,
        key: 'proformas',
        data
      }))
      break
    case TABLES.PROCEDURES:
      data = validateProcedure({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editProcedure({
        _id: event.data._id,
        key: 'procedures',
        data
      }))
      break
    case TABLES.LAB_KITS:
      data = validateLabKit({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editLabKit({
        _id: event.data._id,
        key: 'labKits',
        data
      }))
      break
    case TABLES.COMPANIES:
      data = validateCompany({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editCompany({
        _id: event.data._id,
        key: 'companies',
        data
      }))
      break
    case TABLES.REVENUES:
      data = validateRevenue({
        [event.colDef.field]: event.newValue
      })
      if (_.isEmpty(data)) return
      store.dispatch(editRevenue({
        _id: event.data._id,
        key: 'revenues',
        data
      }))
      break
    default: break
  }
}

export const getSVHasCorrespondingAV = (id) => {
  const { scheduledVisits, actualVisits } = store.getState().dashboard
  if (!scheduledVisits || !actualVisits) return false
  const sv = scheduledVisits.find(sv => sv._id === id)

  const found = !!actualVisits.find(item => item.visit === sv.visit && item.patient === sv.patient && item.clinicalResearch === sv.clinicalResearch)

  return found
}
