import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { handleCellEditRequest } from '../../../utils/grid'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { store, t } from '../../../config'
import moment from 'moment'
import { SelectEditor } from '../../cells'
import { COL_AMENDMENT_WIDTH, TABLES } from '../../../config/constants'

const getGridColDefs = () => {
  const { dashboard } = store.getState()
  if (!dashboard.actualVisits) return undefined
  return [
    {
      editable: false,
      colId: 'date',
      field: 'date',
      cellDataType: 'date',
      valueGetter: (params) => moment(params.data.date).toDate(),
      valueFormatter: (params) => moment(params.data.date).format('DD MMM YYYY')
    },
    {
      editable: false,
      colId: 'patientIdentifier',
      field: 'patient',
      headerName: t('fields.patientID'),
      cellDataType: 'text',
      valueGetter: (params) => params.data.patient,
      valueFormatter: (params) => dashboard.patients.find(patient => patient._id === params.value)?.identifier,
      filterValueGetter: (params) => dashboard.patients.find(patient => patient._id === params.data.patient)?.identifier
    },
    {
      editable: false,
      colId: 'visit',
      headerName: t('fields.visit'),
      field: 'visit',
      cellDataType: 'text',
      valueGetter: (params) => params.data.visit,
      valueFormatter: (params) => {
        const visit = dashboard.visits.find(visit => visit._id === params.value)
        if (!visit) return ''
        return visit.reason
      },
      filterValueGetter: (params) => {
        const visit = dashboard.visits.find(visit => visit._id === params.data.visit)
        if (!visit) return ''
        return visit.reason
      }
    },
    {
      editable: (params) => !params.data.archived && !dashboard.invoices.find(item => (item._id === params.data.visitInvoice && item.completed) || (item._id === params.data.reimbursementInvoice && item.completed)),
      colId: 'visitAmendment',
      headerName: t('fields.amendment'),
      field: 'amendment',
      width: COL_AMENDMENT_WIDTH,
      cellDataType: 'text',
      valueGetter: (params) => params.data.amendment,
      valueFormatter: (params) => {
        const amendment = dashboard.amendments.find(item => item._id === params.data.amendment)
        if (!amendment) return ''
        return amendment.name
      },
      filterValueGetter: (params) => {
        const amendment = dashboard.amendments.find(item => item._id === params.data.amendment)
        if (!amendment) return ''
        return amendment.name
      },
      cellEditor: SelectEditor,
      cellEditorParams: (params) => ({
        options: dashboard.amendments.filter(item => item.visit === params.data.visit).map(item => ({ label: item.name, value: item._id }))
      })
    },
    {
      editable: (params) => !params.data.archived && !dashboard.invoices.find(item => item._id === params.data.visitInvoice && item.completed),
      colId: 'visitInvoice',
      field: 'visitInvoice',
      cellDataType: 'text',
      valueGetter: (params) => params.data.visitInvoice,
      valueFormatter: (params) => dashboard.invoices.find(invoice => invoice._id === params.value)?.name,
      filterValueGetter: (params) => dashboard.invoices.find(invoice => invoice._id === params.data.visitInvoice)?.name,
      cellEditor: SelectEditor,
      cellEditorParams: (params) => ({
        canBeEmpty: true,
        options: dashboard.invoices.filter(invoice => invoice.clinicalResearches.includes(params.data.clinicalResearch) && !invoice.completed).map(invoice => ({ label: invoice.name, value: invoice._id }))
      })
    },
    {
      editable: false,
      colId: 'budgetMhat',
      headerName: t('fields.budgetMhat'),
      field: 'amendment',
      cellDataType: 'text',
      valueFormatter: (params) => {
        const amendment = dashboard.amendments.find(item => item._id === params.data.amendment)
        if (!amendment) return ''
        return Number(amendment.budgetMhat).toFixed(2)
      },
      filterValueGetter: (params) => {
        const amendment = dashboard.amendments.find(item => item._id === params.data.amendment)
        if (!amendment) return ''
        return Number(amendment.budgetMhat).toFixed(2)
      }
    },
    {
      editable: false,
      colId: 'budgetMhatCurrency',
      headerName: t('fields.currency'),
      field: 'visit',
      cellDataType: 'text',
      valueFormatter: (params) => {
        const visit = dashboard.visits.find(item => item._id === params.data.visit)
        if (!visit) return ''
        return visit.currency
      },
      filterValueGetter: (params) => {
        const visit = dashboard.visits.find(item => item._id === params.data.visit)
        if (!visit) return ''
        return visit.currency
      }
    },
    {
      editable: false,
      colId: 'reimbursement',
      field: 'reimbursement',
      cellDataType: 'number',
      valueFormatter: (params) => Number(params.data.reimbursement).toFixed(2)
    },
    {
      editable: (params) => !params.data.archived && !dashboard.invoices.find(item => item._id === params.data.reimbursementInvoice && item.completed),
      colId: 'reimbursementInvoice',
      field: 'reimbursementInvoice',
      cellDataType: 'text',
      valueGetter: (params) => params.data.reimbursementInvoice,
      valueFormatter: (params) => dashboard.invoices.find(invoice => invoice._id === params.value)?.name,
      filterValueGetter: (params) => dashboard.invoices.find(invoice => invoice._id === params.data.reimbursementInvoice)?.name,
      cellEditor: SelectEditor,
      cellEditorParams: (params) => ({
        canBeEmpty: true,
        options: dashboard.invoices.filter(invoice => invoice.clinicalResearches.includes(params.data.clinicalResearch) && !invoice.completed).map(invoice => ({ label: invoice.name, value: invoice._id }))
      })
    }
  ].filter(item => item)
}

function ReportVisitToInvoice (props) {
  const { dashboard, params } = props
  const gridRef = useRef(null)

  const defaultColDef = useMemo(() => ({ filter: true, editable: true, resizable: true })) // Enable on all columns

  const [colDefs, setColDefs] = useState([])
  const rowData = useMemo(() => dashboard.actualVisits?.filter(item => item.clinicalResearch === params.id), [dashboard, params])

  useEffect(() => {
    const _colDefs = getGridColDefs()
    setColDefs(_colDefs)
  }, [dashboard])

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <div className='ag-theme-quartz' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          pagination
          readOnlyEdit
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={colDefs}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          onCellEditRequest={(event) => {
            handleCellEditRequest(TABLES.ACTUAL_VISITS, event)
          }}
        />
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps)(ReportVisitToInvoice)
