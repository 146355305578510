import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { connect } from 'react-redux'
import { validatePatient } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import { PATIENT_STATUSES } from '../../../config/constants'
import _ from 'lodash'
import { createPatient } from '../../../actions'

// const EX = {
//   clinicalResearch: null,
//   name: '',
//   identifier: '',
//   status: '',
//   notes: 'active',
// }

function PatientForm (props) {
  const { clinicalResearches, onHide } = props

  const [query, setQuery] = useState({ status: 'active' })
  const [loading, setLoading] = useState(false)

  const handleUpdateQuery = (field, value) => {
    const _query = validatePatient({ ...query, [field]: value })

    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createPatient({
      data: validatePatient(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const disabled = !query.clinicalResearch || !query.name || !query.identifier || !query.status
  return (
    <>
      <Row>

        <Col>
          <Form.Label>
            {t('fields.clinicalResearch')}*
          </Form.Label>
          <Form.Select
            value={query.clinicalResearch}
            onChange={e => handleUpdateQuery('clinicalResearch', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {clinicalResearches.map(cr => {
              return (
                <option
                  key={cr._id}
                  value={cr._id}
                >
                  {cr.name}
                </option>
              )
            })}
          </Form.Select>
          <br />

          <Form.Label>
            {t('fields.identifier')}*
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('identifier', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.notes')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('notes', e.target.value)}
          />
        </Col>

        <Col>
          <Form.Label>
            {t('fields.name')}*
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('name', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.status')}*
          </Form.Label>
          <Form.Select
            value={query.status}
            onChange={e => handleUpdateQuery('status', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {PATIENT_STATUSES.map(status => {
              return (
                <option
                  key={status}
                  value={status}
                >
                  {_.capitalize(status)}
                </option>
              )
            })}
          </Form.Select>
          <br />
        </Col>
      </Row>
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapStateToProps = state => ({
  clinicalResearches: state.dashboard.clinicalResearches
})

const mapDispatchToProps = dispatch => ({
  createPatient: payload => dispatch(createPatient(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientForm)
