import React, { useEffect, useMemo } from 'react'
import { ReportsContainer } from '../../../components/dashboard'
import { connect } from 'react-redux'
import { getDashboard } from '../../../actions'
import { useParams } from 'react-router-dom'
import { t } from '../../../config'
import { ReportAllInvoices, ReportLabKits, ReportPatientsWithoutSchedule, ReportSchedule, ReportSumVisitsInInvoices, ReportVisitToInvoice } from '../../../components/reports'

function Reports (props) {
  const { type, clinicalResearches } = props
  const params = useParams()

  useEffect(() => {
    props.getDashboard()
  }, [])

  const renderContent = () => {
    switch (type) {
      case 'schedule':
        return (
          <ReportSchedule />
        )
      case 'all-invoices':
        return (
          <ReportAllInvoices
            params={params}
          />
        )
      case 'lab-kits':
        return (
          <ReportLabKits />
        )
      case 'visits-to-invoice':
        return (
          <ReportVisitToInvoice
            params={params}
          />
        )
      case 'sum-visits-in-invoices':
        return (
          <ReportSumVisitsInInvoices
            params={params}
          />
        )
      case 'patients-without-schedule':
        return (
          <ReportPatientsWithoutSchedule />
        )

      default: return (
        <>
          <h1>Under Development</h1>
        </>
      )
    }
  }
  const title = useMemo(() => {
    switch (type) {
      case 'schedule': return `${t('dashboard.reports.schedule')}`
      case 'all-invoices': return `${t('dashboard.reports.allInvoices')} ${t('common.for')} ${clinicalResearches?.find(cr => cr._id === params.id)?.name || '-'}`
      case 'visits-to-invoice': return `${t('dashboard.reports.visitsToInvoice')} ${t('common.for')} ${clinicalResearches?.find(cr => cr._id === params.id)?.name || '-'}`
      case 'lab-kits': return `${t('dashboard.reports.labKits')}`
      case 'sum-visits-in-invoices': return `${t('dashboard.reports.sumVisitsInInvoices')} ${t('common.for')} ${clinicalResearches?.find(cr => cr._id === params.id)?.name || '-'}`
      case 'patients-without-schedule': return `${t('dashboard.reports.patientsWithoutSchedule')}`
      default: return 'Unknown'
    }
  }, [type, clinicalResearches])

  useEffect(() => {
    document.title = `Report: ${title}`
  }, [title])

  return (
    <ReportsContainer
      title={`${t('common.report')}: ${title}`}
    >
      {renderContent()}
    </ReportsContainer>
  )
}

const mapStateToProps = state => ({
  clinicalResearches: state.dashboard.clinicalResearches
})

const mapDispatchToProps = dispatch => ({
  getDashboard: payload => dispatch(getDashboard(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
