import { ofType } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { Observable } from 'rxjs'
import { API } from '../config'
import { handleAPIError, jwtAuth } from '../utils'
import { treasuryTypes } from '../actions/treasury'

export const getTreasuryMovementsEpic = action$ =>
  action$.pipe(
    ofType(treasuryTypes.GET_TREASURY_MOVEMENTS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/treasuryMovement/list`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload?.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: treasuryTypes.GET_TREASURY_MOVEMENTS_SUCCESS,
              payload: response.movements
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const editTreasuryMovementEpic = action$ =>
  action$.pipe(
    ofType(treasuryTypes.EDIT_TREASURY_MOVEMENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/treasuryMovement/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: treasuryTypes.EDIT_TREASURY_MOVEMENT_SUCCESS,
              payload: response.treasuryMovement
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: treasuryTypes.EDIT_TREASURY_MOVEMENT_FAILED,
              payload
            })
            obs.complete()
          })
        })
      )
    })
  )

export const deleteTreasuryMovementEpic = action$ =>
  action$.pipe(
    ofType(treasuryTypes.DELETE_TREASURY_MOVEMENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/treasuryMovement/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: treasuryTypes.DELETE_TREASURY_MOVEMENT_SUCCESS,
              payload: response.treasuryMovement
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createTreasuryMovementEpic = action$ =>
  action$.pipe(
    ofType(treasuryTypes.CREATE_TREASURY_MOVEMENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/treasuryMovement/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: treasuryTypes.CREATE_TREASURY_MOVEMENT_SUCCESS,
              payload: response.treasuryMovement
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )
