import { loginTypes } from '../actions'

const initialState = {
  token: '',
  user: {}
}

const login = (state = initialState, { type, payload }) => {
  switch (type) {
    case loginTypes.SET_LOGIN_FIELDS:
      return { ...state, ...payload }
    case loginTypes.DEAUTHENTICATE:
      return initialState
    default:
      return state
  }
}

export default login
