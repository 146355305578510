import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { getGridColDefs, getGridRowData, handleCellEditRequest } from '../../../utils/grid'
import { connect } from 'react-redux'
import { t } from '../../../config'
import { ButtonGroup, Row, ToggleButton } from 'react-bootstrap'
import { TABLES } from '../../../config/constants'
import { ColorsGroup } from '../../shared'

function Grid (props) {
  const INVOICE_FILTERS_TABLES = useMemo(() => [TABLES.INVOICES, TABLES.PAYMENTS, TABLES.PROFORMAS], [])
  const INVOICE_FILTERS = useMemo(() => [
    { label: t('common.all'), value: null },
    { label: t('common.pending'), value: 'pending' },
    { label: t('common.completed'), value: 'completed' }
  ], [])

  const { table, dashboard, tab } = props

  const [filter, setFilter] = useState({ invoiceFilter: 'pending', colorFilter: null })

  const gridRef = useRef(null)

  const defaultColDef = useMemo(() => ({ filter: true, editable: true, resizable: true })) // Enable on all columns
  const [colDefs, setColDefs] = useState([])

  const gridOptions = useMemo(() => {
    return {
      getRowStyle: (params) => ({ backgroundColor: params.data.color || 'white' })
    }
  }, [])

  const rowData = useMemo(() => {
    let data = getGridRowData(table)
    if (!data) return undefined
    if (INVOICE_FILTERS_TABLES.includes(table)) {
      switch (filter.invoiceFilter) {
        case 'pending':
          data = data.filter(item => !item.completed)
          break
        case 'completed':
          data = data.filter(item => item.completed)
          break
        default:
          break
      }
      if (filter.colorFilter) {
        data = data.filter(item => item.color === filter.colorFilter)
      }
    }
    return data
  }, [table, dashboard, filter])

  useEffect(() => {
    const _colDefs = getGridColDefs(table, tab)
    setColDefs(_colDefs)
  }, [table, dashboard, tab])

  useEffect(() => {
    gridRef?.current?.api?.showLoadingOverlay()
  }, [tab])

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      {INVOICE_FILTERS_TABLES.includes(table) &&
        <Row
          className='mb-3'
        >
          <ButtonGroup
            style={{ width: 450 }}
          >
            {INVOICE_FILTERS.map((item, index) => {
              return (
                <ToggleButton
                  key={item.label}
                  variant={filter.invoiceFilter === item.value ? 'primary' : 'secondary'}
                  onClick={() => setFilter({ ...filter, invoiceFilter: item.value })}
                >
                  {item.label}
                </ToggleButton>
              )
            })}
          </ButtonGroup>
          <ColorsGroup
            value={filter.colorFilter}
            onClick={(colorFilter) => setFilter({ ...filter, colorFilter })}
          />
        </Row>}
      <div className='ag-theme-quartz' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          pagination
          readOnlyEdit
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={colDefs}
          gridOptions={gridOptions}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          onCellEditRequest={(event) => {
            handleCellEditRequest(table, event)
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps)(Grid)
