import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { t } from '../../../config'

function FormFooter (props) {
  const { disabled, onClick, onDelete, deleteText, text, loading } = props
  return (
    <>
      <hr />
      <div
        className='d-flex justify-content-end'
      >
        {onDelete &&
          <Button
            disabled={disabled || loading}
            onClick={onDelete}
            variant='danger'
            className='me-3'
          >
            {loading ? <Spinner size='sm' /> : (deleteText || t('common.delete'))}
          </Button>}
        <Button
          disabled={disabled || loading}
          onClick={onClick}
        >
          {loading ? <Spinner size='sm' /> : (text || t('common.add'))}
        </Button>
      </div>
    </>
  )
}

export default FormFooter
