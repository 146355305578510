import { store } from '../config'
import { setLoginFields, deauthenticate, loginGetMyUser } from '../actions'
import moment from 'moment'
export const jwtAuth = () => {
  const jwt = store.getState().login.token

  return {
    Authorization: jwt,
    'Content-Type': 'application/json'
  }
}
export const noAuth = () => {
  return {
    'Content-Type': 'application/json'
  }
}

export const storeCredentials = (credentials) => {
  window.localStorage.setItem('credentials', JSON.stringify(credentials))
  loadCredentials()
}

export const loadCredentials = () => {
  const _credentials = window.localStorage.getItem('credentials')
  if (_credentials) {
    const credentials = JSON.parse(_credentials)
    store.dispatch(setLoginFields(credentials))
  }
}

export const logout = async () => {
  window.localStorage.removeItem('credentials')
  window.localStorage.removeItem('lastUserUpdate')
  store.dispatch(deauthenticate())
}

export const updateCurrentUser = () => {
  const { token } = store.getState().login
  const lastUserUpdate = window.localStorage.getItem('lastUserUpdate')
  if (token && (!lastUserUpdate || moment().diff(moment(lastUserUpdate), 'minutes') > 10)) {
    window.localStorage.setItem('lastUserUpdate', moment().format())
    store.dispatch(loginGetMyUser())
  }
}
