import { I18n } from 'i18n-js'
import _ from 'lodash'
import moment from 'moment'
import localizationEN from 'moment/locale/en-ca'
import { store } from './store'
import { setLanguage } from '../actions'

const i18n = new I18n()

export const translationGetters = {
  en: () => require('../translations/en.json')
}
const locales = {
  en: localizationEN
}
export const t = _.memoize(
  (key, config) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key)
)

export const i18nConfig = async () => {
  const language = 'en'
  store.dispatch(setLanguage(language))
  moment.updateLocale('en', locales[language])

  t.cache.clear()

  i18n.translations = { [language]: translationGetters[language]() }
  i18n.locale = language
}
