import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { connect } from 'react-redux'
import { validateTreasuryMovement } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import moment from 'moment'
import _ from 'lodash'
import { TREASURY_MOVEMENT_TYPES } from '../../../config/constants'
import { createTreasuryMovement } from '../../../actions'

// const EX = {
//   type: 'top-up',
//   date: '',
//   amount: 0,
//   notes: ''
// }

function TreasuryMovementForm (props) {
  const { onHide } = props

  const [query, setQuery] = useState({ type: 'expense', amount: 0, date: moment().toDate() })
  const [loading, setLoading] = useState(false)

  const handleUpdateQuery = (field, value) => {
    const _query = validateTreasuryMovement({ ...query, [field]: value })
    if (field === 'amount') {
      if (_query[field] === undefined) {
        _query[field] = 0
      }
    }
    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createTreasuryMovement({
      data: validateTreasuryMovement(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const disabled = !query.type || !query.date
  return (
    <>
      <Row>
        <Col>
          <Form.Label>
            {t('fields.type')}*
          </Form.Label>
          <Form.Select
            value={query.type}
            onChange={e => handleUpdateQuery('type', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {TREASURY_MOVEMENT_TYPES.map(c => {
              return (
                <option
                  key={c}
                  value={c}
                >
                  {c.toUpperCase()}
                </option>
              )
            })}
          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.amount')}
          </Form.Label>
          <Form.Control
            type='number'
            onChange={e => handleUpdateQuery('amount', e.target.value)}
            onFocus={e => e.target.select()}
          />
        </Col>
        <Col>
          <Form.Label>
            {t('fields.date')}*
          </Form.Label>
          <Form.Control
            type='date'
            value={moment(query.date).format('YYYY-MM-DD')}
            onChange={e => handleUpdateQuery('date', moment(e.target.value, 'YYYY-MM-DD').toDate())}
          />
          <br />
          <Form.Label>
            {t('fields.notes')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('notes', e.target.value)}
          />
        </Col>
      </Row>
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  createTreasuryMovement: payload => dispatch(createTreasuryMovement(payload))
})

export default connect(null, mapDispatchToProps)(TreasuryMovementForm)
