import React, { useEffect, useMemo, useState } from 'react'
import { BsDoorClosed, BsPeople, BsList, BsClipboard2Data, BsClipboard2DataFill, BsFillPersonVcardFill, BsPersonVcard, BsPeopleFill, BsCalendarEvent, BsCalendarEventFill, BsCalendarCheck, BsCalendarCheckFill, BsFileEarmarkRuled, BsFileEarmarkRuledFill, BsFileEarmarkArrowDown, BsFileEarmarkArrowDownFill, BsFileEarmarkPpt, BsFileEarmarkPptFill, BsFileEarmarkBreak, BsFileEarmarkBreakFill, BsCollection, BsCollectionFill, BsGraphUp, BsGraphUpArrow } from 'react-icons/bs'
import { IoMedkitOutline, IoMedkit, IoBusinessOutline, IoBusiness } from 'react-icons/io5'
import { RiSyringeLine, RiSyringeFill, RiTaskLine, RiTaskFill } from 'react-icons/ri'
import { t } from '../../../config'
import { hasReqLevel, logout } from '../../../utils'
import { REPORTS, TABLES, TASKS } from '../../../config/constants'
import { Button, Tab, Tabs } from 'react-bootstrap'
import { DashboardCreateModal } from '..'
import { connect } from 'react-redux'
import { getArchivedDashboard, getDashboard, setInitialDashboard } from '../../../actions'
import { exportTableToCSV } from '../../../utils/csv'

const TabItem = (props) => {
  const { active, data, onClick, onAuxClick, labelClassName } = props
  const { iconActive, icon, label } = data
  return (
    <li className='nav-item font-weight-bold'>
      <span
        onAuxClick={onAuxClick}
        onClick={onClick}
        className={`nav-link d-flex align-items-center gap-2 active ${labelClassName} ${active ? 'fw-bold' : ''}`} role='button'
      >
        {active ? iconActive : icon}
        {label}
      </span>
    </li>
  )
}

const DashboardContainer = (props) => {
  const { user, activeTable, setActiveTable, activeTab, setActiveTab, dashboardLoading } = props
  const TABS = [{ label: t('common.active'), value: 'active' }, { label: t('common.archived'), value: 'archived' }]

  const [menuOpen, setMenuOpen] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)

  const tables = useMemo(() =>
    [
      { canAdd: hasReqLevel(2), value: TABLES.CLINICAL_RESEARCHES, label: t('dashboard.tabs.clinicalResearches'), icon: <BsClipboard2Data />, iconActive: <BsClipboard2DataFill /> },
      { canAdd: hasReqLevel(2), value: TABLES.DOCTORS, label: t('dashboard.tabs.doctors'), icon: <BsPersonVcard />, iconActive: <BsFillPersonVcardFill /> },
      { canAdd: true, value: TABLES.PATIENTS, label: t('dashboard.tabs.patients'), icon: <BsPeople />, iconActive: <BsPeopleFill /> },
      { canAdd: hasReqLevel(2), value: TABLES.VISITS, label: t('dashboard.tabs.visits'), icon: <BsClipboard2Data />, iconActive: <BsClipboard2DataFill /> },
      { canAdd: true, value: TABLES.SCHEDULED_VISITS, label: t('dashboard.tabs.scheduledVisits'), icon: <BsCalendarEvent />, iconActive: <BsCalendarEventFill /> },
      { canAdd: true, value: TABLES.ACTUAL_VISITS, label: t('dashboard.tabs.actualVisits'), icon: <BsCalendarCheck />, iconActive: <BsCalendarCheckFill /> },
      { canAdd: true, value: TABLES.PROCEDURES, label: t('dashboard.tabs.procedures'), icon: <RiSyringeLine />, iconActive: <RiSyringeFill /> },
      { canAdd: true, value: TABLES.LAB_KITS, label: t('dashboard.tabs.labKits'), icon: <IoMedkitOutline />, iconActive: <IoMedkit /> },
      hasReqLevel(2) && { canAdd: true, value: TABLES.INVOICES, label: t('dashboard.tabs.invoices'), icon: <BsFileEarmarkRuled />, iconActive: <BsFileEarmarkRuledFill /> },
      hasReqLevel(2) && { canAdd: true, value: TABLES.PAYMENTS, label: t('dashboard.tabs.payments'), icon: <BsFileEarmarkArrowDown />, iconActive: <BsFileEarmarkArrowDownFill /> },
      hasReqLevel(2) && { canAdd: true, value: TABLES.PROFORMAS, label: t('dashboard.tabs.proformas'), icon: <BsFileEarmarkPpt />, iconActive: <BsFileEarmarkPptFill /> },
      hasReqLevel(2) && { canAdd: true, value: TABLES.REVENUES, label: t('dashboard.tabs.revenues'), icon: <BsGraphUp />, iconActive: <BsGraphUpArrow /> },
      hasReqLevel(2) && { canAdd: true, value: TABLES.COMPANIES, label: t('dashboard.tabs.companies'), icon: <IoBusinessOutline />, iconActive: <IoBusiness /> }
    ].filter(item => item)
  , [user])

  const dashboard = useMemo(() =>
    [
      { value: REPORTS, label: t('dashboard.tabs.reports'), icon: <BsFileEarmarkBreak />, iconActive: <BsFileEarmarkBreakFill /> },
      { canAdd: true, value: TASKS, label: t('dashboard.tabs.tasks'), icon: <RiTaskLine />, iconActive: <RiTaskFill /> }
    ], [])

  const treasury = useMemo(() =>
    [
      { canAdd: true, value: 'treasuryMovements', label: t('dashboard.tabs.treasuryMovements'), icon: <BsCollection />, iconActive: <BsCollectionFill /> }
    ], [])

  const handleToggleMenu = (e) => {
    e.preventDefault()
    setMenuOpen(!menuOpen)
  }
  const handleLogout = () => {
    logout()
  }

  useEffect(() => {
    const sidebarMenu = document.getElementById('sidebarMenu')
    const body = document.getElementsByTagName('body')[0]

    if (menuOpen) {
      sidebarMenu.classList.add('show')
      sidebarMenu.setAttribute('modal', 'true')
      sidebarMenu.setAttribute('role', 'dialog')
      body.setAttribute('style', 'overflow: hidden;')
      return
    }
    sidebarMenu.classList.remove('show')
    sidebarMenu.removeAttribute('modal')
    sidebarMenu.removeAttribute('role')
    body.removeAttribute('style')
  }, [menuOpen])

  const handleSelectTab = (key) => {
    switch (key) {
      case 'active':
        props.setInitialDashboard()
        props.getDashboard()
        break
      case 'archived':
        props.setInitialDashboard()
        props.getArchivedDashboard()
        break
      default:break
    }
    setActiveTab(key)
  }

  const handleAuxClick = (tab) => {
    window.open(`${window.location.origin}/dashboard/${tab}`)
  }
  useEffect(() => {
    const title = tables.find(item => item.value === activeTable)?.label || treasury.find(item => item.value === activeTable)?.label || dashboard.find(item => item.value === activeTable)?.label
    if (title) document.title = title
  }, [activeTable])

  return (
    <div className='d-flex flex-column flex-fill'>
      {/* header */}
      <header className='navbar sticky-top bg-dark flex-md-nowrap p-0 shadow' data-bs-theme='dark'>
        <span className='navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 py-3 text-white'>{t('dashboard.title')}</span>
        <ul className='navbar-nav flex-row d-md-none'>
          <li className='nav-item text-nowrap'>
            <button
              onClick={handleToggleMenu}
              className='nav-link px-3 text-white' type='button' data-bs-toggle='offcanvas' data-bs-target='#sidebarMenu' aria-controls='sidebarMenu' aria-expanded='false' aria-label='Toggle navigation'
            >
              <BsList
                size={24}
              />
            </button>
          </li>
        </ul>
      </header>
      {/* /header */}
      <div className='container-fluid d-flex flex-column flex-fill p-0'>
        <div className='d-flex flex-row flex-fill'>
          {/* sidebar */}
          <div className='sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary'>
            <div className='offcanvas-md offcanvas-end bg-body-tertiary' tabIndex='-1' id='sidebarMenu' aria-labelledby='sidebarMenuLabel'>
              <div className='offcanvas-header'>
                <h5 className='offcanvas-title' id='sidebarMenuLabel'>{t('dashboard.title')}</h5>
                <button
                  onClick={handleToggleMenu}
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='offcanvas'
                  data-bs-target='#sidebarMenu'
                  aria-label='Close'
                />
              </div>
              <div className='offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto'>
                <h6 className='sidebar-heading d-flex justify-content-between align-items-center px-3 mb-1 text-body-secondary text-uppercase'>
                  <span>{t('dashboard.container.dashboard')}</span>
                </h6>
                <ul className='nav flex-column'>
                  {dashboard.map((item, index) => {
                    return (
                      <TabItem
                        key={item.value}
                        data={item}
                        active={activeTable === item.value}
                        onClick={() => setActiveTable(item.value)}
                      />
                    )
                  })}
                </ul>

                <h6 className='sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase'>
                  <span>{t('dashboard.container.tables')}</span>
                </h6>

                <ul className='nav flex-column mb-auto'>
                  {tables.map((item, index) => {
                    return (
                      <TabItem
                        key={item.value}
                        data={item}
                        active={activeTable === item.value}
                        onClick={() => setActiveTable(item.value)}
                        onAuxClick={() => handleAuxClick(item.value)}
                      />
                    )
                  })}
                </ul>

                <h6 className='sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase'>
                  <span>{t('dashboard.container.treasury')}</span>
                </h6>

                <ul className='nav flex-column mb-auto'>
                  {treasury.map((item, index) => {
                    return (
                      <TabItem
                        key={item.value}
                        data={item}
                        active={activeTable === item.value}
                        onClick={() => setActiveTable(item.value)}
                        onAuxClick={() => handleAuxClick(item.value)}
                      />
                    )
                  })}
                </ul>

                <hr className='my-3' />
                <ul className='nav flex-column mb-3'>
                  <TabItem
                    onClick={handleLogout}
                    labelClassName='text-danger fw-medium'
                    data={{
                      icon: <BsDoorClosed className='link-danger' />,
                      label: t('dashboard.tabs.signOut')
                    }}
                  />
                </ul>
              </div>
            </div>
            {menuOpen &&
              <div
                onClick={handleToggleMenu}
                className='offcanvas-backdrop fade show'
              />}
          </div>
          {/* /sidebar */}
          <main className='col-md-9 ms-sm-auto col-lg-10 p-4 d-flex flex-fill flex-column'>
            {hasReqLevel(3) && tables.some(item => item.value === activeTable) &&
              <>
                <Tabs
                  className='mb-3'
                  defaultActiveKey='active'
                  activeKey={activeTab}
                  onSelect={handleSelectTab}
                >
                  {TABS.map((item) => {
                    return (
                      <Tab
                        key={item.value}
                        disabled={dashboardLoading}
                        eventKey={item.value}
                        title={item.label}
                      />
                    )
                  })}
                </Tabs>
              </>}
            <div className='d-flex flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom'>
              <h1 className='h2 me-3'>{t(`dashboard.tabs.${activeTable}`)}</h1>
              {((activeTab === 'active' && tables.find(item => item.value === activeTable)?.canAdd) || treasury.find(item => item.value === activeTable)?.canAdd || dashboard.find(item => item.value === activeTable)?.canAdd) &&
                <Button
                  onClick={() => setCreateOpen(true)}
                  disabled={dashboardLoading}
                  className='me-3'
                >
                  {t('common.add')}
                </Button>}
              {hasReqLevel(3) && tables.find(item => item.value === activeTable) &&
                <Button
                  variant='success'
                  disabled={dashboardLoading}
                  onClick={() => {
                    exportTableToCSV(activeTable, activeTab === 'archived')
                  }}
                >
                  {t('common.export')}
                </Button>}
            </div>
            {props.children}
          </main>
          <DashboardCreateModal
            activeTable={activeTable}
            show={createOpen}
            onHide={() => setCreateOpen(false)}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.login.user,
  dashboardLoading: state.dashboard.loading
})

const mapDispatchToProps = dispatch => ({
  setInitialDashboard: () => dispatch(setInitialDashboard()),
  getArchivedDashboard: () => dispatch(getArchivedDashboard()),
  getDashboard: () => dispatch(getDashboard())
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
