import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { connect } from 'react-redux'
import { store, t } from '../../../config'
import { handleDangerConfirm, hasReqLevel } from '../../../utils'
import { getControlColDef, handleCellEditRequest } from '../../../utils/grid'
import { TABLES } from '../../../config/constants'
import DashboardCreateModal from '../DashboardCreateModal'
import { Button } from 'react-bootstrap'
import { deleteAmendment } from '../../../actions'

const getGridRowData = (visit) => {
  const { dashboard } = store.getState()
  if (!dashboard.amendments) return undefined

  const data = dashboard.amendments.filter(item => item.visit === visit._id)

  return data
}

const getGridColDefs = () => {
  const { dashboard } = store.getState()
  if (!dashboard.amendments) return undefined
  return [
    {
      editable: (params) => !params.data.archived,
      colId: 'amendmentName',
      field: 'name',
      cellDataType: 'text'
    },
    hasReqLevel(2) && {
      editable: (params) => !params.data.archived,
      colId: 'amendmentBudgetMhat',
      field: 'budgetMhat',
      headerName: t('fields.budgetMhat'),
      cellDataType: 'number',
      valueFormatter: (params) => Number(params.data.budgetMhat).toFixed(2)
    },
    hasReqLevel(2) && {
      editable: (params) => !params.data.archived,
      colId: 'amendmentBudgetPI',
      field: 'budgetPI',
      headerName: t('fields.budgetPI'),
      cellDataType: 'number',
      valueFormatter: (params) => Number(params.data.budgetPI).toFixed(2)
    },
    hasReqLevel(2) && {
      ...getControlColDef('AmendmentColDef'),
      cellRendererParams: (params) => ({
        onDelete: (!params.data.archived && !params.data.initial)
          ? () => {
              handleDangerConfirm({
                onConfirm: () => {
                  store.dispatch(deleteAmendment({ _id: params.data._id }))
                }
              })
            }
          : null
      })
    }
  ].filter(item => item)
}

function AmendmentsGrid (props) {
  const { dashboard, visit } = props

  const gridRef = useRef(null)

  const [createOpen, setCreateOpen] = useState(false)

  const defaultColDef = useMemo(() => ({ filter: true, editable: true, resizable: true })) // Enable on all columns
  const [colDefs, setColDefs] = useState([])
  const rowData = useMemo(() => getGridRowData(visit), [dashboard, visit])

  useEffect(() => {
    const _colDefs = getGridColDefs(visit)
    setColDefs(_colDefs)
  }, [dashboard, visit])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '65vh' }}>
      {hasReqLevel(2) &&
        <Button
          style={{ alignSelf: 'flex-start' }}
          onClick={() => setCreateOpen(true)}
          className='mb-3'
        >
          {t('common.add')}
        </Button>}
      <div className='ag-theme-quartz' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          pagination
          readOnlyEdit
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={colDefs}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          onCellEditRequest={(event) => {
            handleCellEditRequest(TABLES.AMENDMENTS, event)
          }}
        />
      </div>
      {createOpen &&
        <div
          style={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundColor: 'black',
            opacity: 0.5
          }}
        />}
      <DashboardCreateModal
        activeTable={TABLES.AMENDMENTS}
        prefill={{
          clinicalResearch: visit.clinicalResearch,
          visit: visit._id
        }}
        show={createOpen}
        onHide={() => {
          setCreateOpen(false)
        }}
      />

    </div>
  )
}

const mapStateToProps = state => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps, null)(AmendmentsGrid)
