import { ofType } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { Observable } from 'rxjs'
import { API } from '../config'
import { handleAPIError, jwtAuth } from '../utils'
import { dashboardTypes, getArchivedDashboard, getDashboard } from '../actions'

export const getDashboardEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.GET_DASHBOARD),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/dashboard/list`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload?.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.GET_DASHBOARD_SUCCESS,
              payload: response.dashboard
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const getArchivedDashboardEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.GET_ARCHIVED_DASHBOARD),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/dashboard/archived/list`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload?.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.GET_ARCHIVED_DASHBOARD_SUCCESS,
              payload: response.dashboard
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const archiveClinicalResearchEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.ARCHIVE_CLINICAL_RESEARCH),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/cr/archive/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({ type: dashboardTypes.ARCHIVE_CLINICAL_RESEARCH_SUCCESS })
            obs.next(getDashboard())
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const unarchiveClinicalResearchEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.UNARCHIVE_CLINICAL_RESEARCH),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/cr/unarchive/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({ type: dashboardTypes.UNARCHIVE_CLINICAL_RESEARCH_SUCCESS })
            obs.next(getArchivedDashboard())
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const editClinicalResearchEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_CLINICAL_RESEARCH),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/cr/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.EDIT_CLINICAL_RESEARCH_SUCCESS,
              payload: { key: 'clinicalResearches', payload: response.clinicalResearch }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_CLINICAL_RESEARCH_FAILED,
              payload: { key: 'clinicalResearches', payload }
            })
            if (payload?.onFailure) payload.onFailure()
            obs.complete()
          })
        })
      )
    })
  )

export const editClinicalResearchTemplateEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_CLINICAL_RESEARCH_TEMPLATE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/cr/template/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.EDIT_CLINICAL_RESEARCH_SUCCESS,
              payload: { key: 'clinicalResearches', payload: response.clinicalResearch }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_CLINICAL_RESEARCH_FAILED,
              payload: { key: 'clinicalResearches', payload }
            })
            if (payload?.onFailure) payload.onFailure()
            obs.complete()
          })
        })
      )
    })
  )

export const editDoctorEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_DOCTOR),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/doctor/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.EDIT_DOCTOR_SUCCESS,
              payload: { key: 'doctors', payload: response.doctor }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_DOCTOR_FAILED,
              payload: { key: 'doctors', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const editPatientEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_PATIENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/patient/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.EDIT_PATIENT_SUCCESS,
              payload: { key: 'patients', payload: response.patient }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_PATIENT_FAILED,
              payload: { key: 'patients', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const editVisitEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_VISIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/visit/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.EDIT_VISIT_SUCCESS,
              payload: { key: 'visits', payload: response.visit }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_VISIT_FAILED,
              payload: { key: 'visits', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const editScheduledVisitEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_SCHEDULED_VISIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/scheduledVisit/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.EDIT_SCHEDULED_VISIT_SUCCESS,
              payload: { key: 'scheduledVisits', payload: response.scheduledVisit }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_SCHEDULED_VISIT_FAILED,
              payload: { key: 'scheduledVisits', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const editActualVisitEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_ACTUAL_VISIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/actualVisit/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.EDIT_ACTUAL_VISIT_SUCCESS,
              payload: { key: 'actualVisits', payload: response.actualVisit }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_ACTUAL_VISIT_FAILED,
              payload: { key: 'actualVisits', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const editProformaEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_PROFORMA),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/proforma/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.EDIT_PROFORMA_SUCCESS,
              payload: { key: 'proformas', payload: response.proforma }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_PROFORMA_FAILED,
              payload: { key: 'proformas', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const editPaymentEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_PAYMENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/payment/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.EDIT_PAYMENT_SUCCESS,
              payload: { key: 'payments', payload: response.payment }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_PAYMENT_FAILED,
              payload: { key: 'payments', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const editInvoiceEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_INVOICE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/invoice/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.EDIT_INVOICE_SUCCESS,
              payload: { key: 'invoices', payload: response.invoice }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_INVOICE_FAILED,
              payload: { key: 'invoices', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const editProcedureEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_PROCEDURE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/procedure/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.EDIT_PROCEDURE_SUCCESS,
              payload: { key: 'procedures', payload: response.procedure }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_PROCEDURE_FAILED,
              payload: { key: 'procedures', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const editLabKitEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_LAB_KIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/labKit/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.EDIT_LAB_KIT_SUCCESS,
              payload: { key: 'labKits', payload: response.labKit }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_LAB_KIT_FAILED,
              payload: { key: 'labKits', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const editAmendmentEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_AMENDMENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/amendment/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.EDIT_AMENDMENT_SUCCESS,
              payload: { key: 'amendments', payload: response.amendment }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_AMENDMENT_FAILED,
              payload: { key: 'amendments', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const editCompanyEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_COMPANY),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/company/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.EDIT_COMPANY_SUCCESS,
              payload: { key: 'companies', payload: response.company }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_COMPANY_FAILED,
              payload: { key: 'companies', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const editRevenueEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.EDIT_REVENUE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/revenue/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.EDIT_REVENUE_SUCCESS,
              payload: { key: 'revenues', payload: response.revenue }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: dashboardTypes.EDIT_REVENUE_FAILED,
              payload: { key: 'revenues', payload }
            })
            obs.complete()
          })
        })
      )
    })
  )

export const deleteClinicalResearchEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_CLINICAL_RESEARCH),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/cr/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_CLINICAL_RESEARCH_SUCCESS,
              payload: { key: 'clinicalResearches', payload: response.clinicalResearch }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deleteDoctorEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_DOCTOR),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/doctor/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_DOCTOR_SUCCESS,
              payload: { key: 'doctors', payload: response.doctor }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deletePatientEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_PATIENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/patient/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_PATIENT_SUCCESS,
              payload: { key: 'patients', payload: response.patient }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deleteVisitEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_VISIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/visit/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_VISIT_SUCCESS,
              payload: { key: 'visits', payload: response.visit }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deleteScheduledVisitEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_SCHEDULED_VISIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/scheduledVisit/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_SCHEDULED_VISIT_SUCCESS,
              payload: { key: 'scheduledVisits', payload: response.scheduledVisit }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deleteActualVisitEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_ACTUAL_VISIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/actualVisit/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_ACTUAL_VISIT_SUCCESS,
              payload: { key: 'actualVisits', payload: response.actualVisit }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deleteProformaEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_PROFORMA),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/proforma/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_PROFORMA_SUCCESS,
              payload: { key: 'proformas', payload: response.proforma }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deletePaymentEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_PAYMENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/payment/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_PAYMENT_SUCCESS,
              payload: { key: 'payments', payload: response.payment }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deleteInvoiceEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_INVOICE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/invoice/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_INVOICE_SUCCESS,
              payload: { key: 'invoices', payload: response.invoice }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deleteProcedureEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_PROCEDURE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/procedure/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_PROCEDURE_SUCCESS,
              payload: { key: 'procedures', payload: response.procedure }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deleteLabKitEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_LAB_KIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/labKit/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_LAB_KIT_SUCCESS,
              payload: { key: 'labKits', payload: response.labKit }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deleteAmendmentEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_AMENDMENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/amendment/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_AMENDMENT_SUCCESS,
              payload: { key: 'amendments', payload: response.amendment }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deleteCompanyEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_COMPANY),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/company/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_COMPANY_SUCCESS,
              payload: { key: 'companies', payload: response.company }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const deleteRevenueEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.DELETE_REVENUE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/revenue/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.DELETE_REVENUE_SUCCESS,
              payload: { key: 'revenues', payload: response.revenue }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createClinicalResearchEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_CLINICAL_RESEARCH),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/cr/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.CREATE_CLINICAL_RESEARCH_SUCCESS,
              payload: { key: 'clinicalResearches', payload: response.clinicalResearch }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createDoctorEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_DOCTOR),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/doctor/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.CREATE_DOCTOR_SUCCESS,
              payload: { key: 'doctors', payload: response.doctor }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createPatientEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_PATIENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/patient/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.CREATE_PATIENT_SUCCESS,
              payload: { key: 'patients', payload: response.patient }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createVisitEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_VISIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/visit/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.CREATE_AMENDMENT_SUCCESS,
              payload: { key: 'amendments', payload: response.amendment }
            })
            obs.next({
              type: dashboardTypes.CREATE_VISIT_SUCCESS,
              payload: { key: 'visits', payload: response.visit }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createScheduledVisitEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_SCHEDULED_VISIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/scheduledVisit/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.CREATE_SCHEDULED_VISIT_SUCCESS,
              payload: { key: 'scheduledVisits', payload: response.scheduledVisit }
            })
            if (response.additionalSVs) {
              obs.next({
                type: dashboardTypes.CREATE_MULTIPLE_SCHEDULED_VISITS_SUCCESS,
                payload: { key: 'scheduledVisits', payload: response.additionalSVs }
              })
            }
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createActualVisitEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_ACTUAL_VISIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/actualVisit/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.CREATE_ACTUAL_VISIT_SUCCESS,
              payload: { key: 'actualVisits', payload: response.actualVisit }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createProformaEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_PROFORMA),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/proforma/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.CREATE_PROFORMA_SUCCESS,
              payload: { key: 'proformas', payload: response.proforma }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createPaymentEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_PAYMENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/payment/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.CREATE_PAYMENT_SUCCESS,
              payload: { key: 'payments', payload: response.payment }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createInvoiceEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_INVOICE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/invoice/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.CREATE_INVOICE_SUCCESS,
              payload: { key: 'invoices', payload: response.invoice }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createProcedureEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_PROCEDURE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/procedure/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.CREATE_PROCEDURE_SUCCESS,
              payload: { key: 'procedures', payload: response.procedure }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createLabKitEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_LAB_KIT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/labKit/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: dashboardTypes.CREATE_LAB_KIT_SUCCESS,
              payload: { key: 'labKits', payload: response.labKit }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createAmendmentEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_AMENDMENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/amendment/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess(response.amendment)
            obs.next({
              type: dashboardTypes.CREATE_AMENDMENT_SUCCESS,
              payload: { key: 'amendments', payload: response.amendment }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createCompanyEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_COMPANY),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/company/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess(response.company)
            obs.next({
              type: dashboardTypes.CREATE_COMPANY_SUCCESS,
              payload: { key: 'companies', payload: response.company }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createRevenueEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.CREATE_REVENUE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/revenue/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess(response.revenue)
            obs.next({
              type: dashboardTypes.CREATE_REVENUE_SUCCESS,
              payload: { key: 'revenues', payload: response.revenue }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const archivePaymentEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.ARCHIVE_PAYMENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/payment/archive/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.ARCHIVE_PAYMENT_SUCCESS,
              payload: { key: 'payments', payload: response.payment }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const unarchivePaymentEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.UNARCHIVE_PAYMENT),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/payment/unarchive/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.UNARCHIVE_PAYMENT_SUCCESS,
              payload: { key: 'payments', payload: response.payment }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const archiveProformaEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.ARCHIVE_PROFORMA),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/proforma/archive/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.ARCHIVE_PROFORMA_SUCCESS,
              payload: { key: 'proformas', payload: response.proforma }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const unarchiveProformaEpic = action$ =>
  action$.pipe(
    ofType(dashboardTypes.UNARCHIVE_PROFORMA),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/proforma/unarchive/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: dashboardTypes.UNARCHIVE_PROFORMA_SUCCESS,
              payload: { key: 'proformas', payload: response.proforma }
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )
