import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { connect } from 'react-redux'
import { store } from '../../../config'
import moment from 'moment'
import _ from 'lodash'

const getGridRowData = (filter) => {
  const { dashboard } = store.getState()
  if (!dashboard.patients || !dashboard.scheduledVisits) return undefined

  const data = dashboard.patients
    .filter(item => item.status === 'active' && !dashboard.scheduledVisits.find(sv => sv.patient === item._id))
    .map(item => {
      return {
        clinicalResearch: dashboard.clinicalResearches.find(cr => cr._id === item.clinicalResearch).name,
        name: item.name,
        identifier: item.identifier,
        status: _.capitalize(item.status),
        createdAt: moment(item.createdAt).format('DD MMM YYYY')
      }
    }).sort((a, b) => a.clinicalResearch.localeCompare(b.clinicalResearch))
  return data
}

const getGridColDefs = () => {
  const { dashboard } = store.getState()
  if (!dashboard.scheduledVisits) return undefined
  return [
    {
      editable: false,
      colId: 'clinicalResearch',
      field: 'clinicalResearch',
      cellDataType: 'text'
    },
    {
      editable: false,
      colId: 'name',
      field: 'name',
      cellDataType: 'text'
    },
    {
      editable: false,
      colId: 'identifier',
      field: 'identifier',
      cellDataType: 'text'
    },
    {
      editable: false,
      colId: 'status',
      field: 'status',
      cellDataType: 'text'
    },
    {
      editable: false,
      colId: 'createdAt',
      field: 'createdAt',
      headerName: 'Created On',
      cellDataType: 'text'
    }
  ].filter(item => item)
}

function ReportPatients (props) {
  const { dashboard } = props

  const gridRef = useRef(null)

  const defaultColDef = useMemo(() => ({ filter: true, editable: true, resizable: true })) // Enable on all columns
  const [colDefs, setColDefs] = useState([])
  const rowData = useMemo(() => getGridRowData(), [dashboard])

  useEffect(() => {
    const _colDefs = getGridColDefs()
    setColDefs(_colDefs)
  }, [dashboard])

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <div className='ag-theme-quartz' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          pagination
          readOnlyEdit
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={colDefs}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps, null)(ReportPatients)
