import React, { useState } from 'react'
import { Col, Dropdown, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { connect } from 'react-redux'
import { validatePayment } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import moment from 'moment'
import { calcMoney } from '../../../utils'
import _ from 'lodash'
import { BANKS, CURRENCIES, MULTIPLIER_VAT } from '../../../config/constants'
import { createPayment } from '../../../actions'

// const EX = {
//   proformas: [],
//   reason: '',
//   date: '',
//   amount: 0,
//   VAT: false',
//   total: 0,
//   currency: 'BGN',
//   completed: false,
//   archived: false,
//   notes: ''
// }

function PaymentForm (props) {
  const { proformas, companies, clinicalResearches, onHide } = props

  const [query, setQuery] = useState({ proformas: [], currency: 'BGN', VAT: false, amount: 0, bank: BANKS[0] })
  const [loading, setLoading] = useState(false)

  const handleUpdateQuery = (field, value) => {
    if (field === 'proformas' && value) {
      const prs = query.proformas.includes(value) ? query.proformas.filter(e => e !== value) : [...query.proformas, value]
      const _query = validatePayment({ ...query, proformas: prs })
      setQuery(_query)
      return
    }
    const _query = validatePayment({ ...query, [field]: value })
    if (field === 'amount') {
      if (_query[field] === undefined) {
        _query[field] = 0
      }
    }
    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createPayment({
      data: validatePayment(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const sortedCompanies = companies.slice(0).sort((a, b) => a.name.localeCompare(b.name))

  const disabled = !query.reason || !query.date
  return (
    <>
      <Row>
        <Col>
          <Form.Label>
            {t('fields.proformas')}
          </Form.Label>
          <Dropdown>
            <Dropdown.Toggle
              className='bg-white text-black'
              style={{ width: '100%', alignSelf: 'flex-start', display: 'flex', alignItems: 'center', borderColor: 'lightGrey' }}
            >
              {query.proformas.length ? query.proformas.map(id => proformas.find(item => item._id === id)?.reason).join('; ') : t('common.select')}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{ maxHeight: '450px', overflowY: 'auto' }}
            >
              {proformas.filter(item => !item.completed).map(pr => {
                return (
                  <Dropdown.Item
                    onClick={() => handleUpdateQuery('proformas', pr._id)}
                    className={query.proformas.includes(pr._id) ? 'bg-primary text-white' : ''}
                    key={pr._id}
                    value={pr._id}
                  >
                    {pr.reason}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          <br />
          <Form.Label>
            {t('fields.clinicalResearch')}
          </Form.Label>
          <Form.Select
            value={query.clinicalResearch}
            onChange={e => handleUpdateQuery('clinicalResearch', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {clinicalResearches
              .map(cr => {
                return (
                  <option
                    key={cr._id}
                    value={cr._id}
                  >
                    {cr.name}
                  </option>
                )
              })}
          </Form.Select>
        </Col>
        <Row>
          <Col>
            <br />
            <Form.Label>
              {t('fields.reason')}*
            </Form.Label>
            <Form.Control
              type='text'
              onChange={e => handleUpdateQuery('reason', e.target.value)}
            />
            <br />
            <Form.Label>
              {t('fields.amount')}
            </Form.Label>
            <Form.Control
              type='number'
              onChange={e => handleUpdateQuery('amount', e.target.value)}
              onFocus={e => e.target.select()}
            />
            <br />
            <Form.Label>
              {t('fields.total')}
            </Form.Label>
            <Form.Control
              disabled
              value={_.isFinite(query.amount) ? Number(calcMoney(query.VAT ? (query.amount * MULTIPLIER_VAT) : query.amount)).toFixed(2) : 0}
              type='number'
            />
            <br />
            <Form.Label>
              {t('fields.notes')}
            </Form.Label>
            <Form.Control
              type='text'
              onChange={e => handleUpdateQuery('notes', e.target.value)}
            />
          </Col>
          <Col>
            <br />
            <Form.Label>
              {t('fields.date')}*
            </Form.Label>
            <Form.Control
              type='date'
              onChange={e => handleUpdateQuery('date', moment(e.target.value, 'YYYY-MM-DD').toDate())}
            />
            <br />
            <Form.Label>
              {t('fields.VAT')}
            </Form.Label>
            <Form.Select
              value={query.VAT}
              onChange={e => handleUpdateQuery('VAT', e.target.value === 'true')}
            >
              <option value='false'>{t('common.no')}</option>
              <option value='true'>{t('common.yes')}</option>
            </Form.Select>
            <br />
            <Form.Label>
              {t('fields.currency')}
            </Form.Label>
            <Form.Select
              value={query.currency}
              onChange={e => handleUpdateQuery('currency', e.target.value)}
            >
              <option value=''>{t('common.select')}...</option>
              {CURRENCIES.map(c => {
                return (
                  <option
                    key={c}
                    value={c}
                  >
                    {c}
                  </option>
                )
              })}
            </Form.Select>
            <br />
            <Form.Label>
              {t('fields.billTo')}
            </Form.Label>
            <Form.Select
              value={query.company}
              onChange={e => handleUpdateQuery('company', e.target.value)}
            >
              <option value=''>{t('common.select')}...</option>
              {sortedCompanies
                .map(c => {
                  return (
                    <option
                      key={c._id}
                      value={c._id}
                    >
                      {c.name}
                    </option>
                  )
                })}
            </Form.Select>
            <br />
            <Form.Label>
              {t('fields.bank')}
            </Form.Label>
            <Form.Select
              value={query.bank}
              onChange={e => handleUpdateQuery('bank', e.target.value)}
            >
              <option value=''>{t('common.select')}...</option>
              {BANKS
                .map(b => {
                  return (
                    <option
                      key={b}
                      value={b}
                    >
                      {b}
                    </option>
                  )
                })}
            </Form.Select>
          </Col>
        </Row>
      </Row>
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapStateToProps = state => ({
  clinicalResearches: state.dashboard.clinicalResearches,
  proformas: state.dashboard.proformas,
  companies: state.dashboard.companies
})

const mapDispatchToProps = dispatch => ({
  createPayment: payload => dispatch(createPayment(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm)
