import SweetAlert2 from 'sweetalert2'
import { store, t } from '../config'
import { logout } from './auth'

export const Swal = SweetAlert2.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-2',
    cancelButton: 'btn btn-danger mx-2'
  },
  buttonsStyling: false
})

export const hasReqLevel = (level) => {
  const userLevel = store.getState().login.user.level || 0
  return userLevel >= level
}

export const handleAPIError = (error) => {
  if (error.status === 401) {
    Swal.fire({
      icon: 'error',
      title: t('common.oops'),
      text: t('errors.unauthorized'),
      confirmButtonText: t('common.continue')
    })
    logout()
    return
  }
  Swal.fire({
    icon: 'error',
    title: t('common.oops'),
    text: error?.response?.message || error,
    confirmButtonText: t('common.continue')
  })
}

export const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export const getHasMatchingArrayElements = (arr1, arr2) => {
  return arr1.some(item => arr2.includes(item))
}

export const handleDangerConfirm = ({ onConfirm, ...rest }) => {
  SweetAlert2.mixin({
    customClass: {
      confirmButton: 'btn btn-danger mx-2',
      cancelButton: 'btn btn-secondary mx-2'
    },
    buttonsStyling: false
  }).fire({
    title: t('common.warning'),
    text: t('common.irreversibleAction'),
    icon: 'warning',
    reverseButtons: true,
    showCancelButton: true,
    confirmButtonText: t('common.confirm'),
    ...rest
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm && onConfirm()
    }
  })
}

export const calcMoney = (expression) => {
  return Math.round(((expression) + Number.EPSILON) * 100) / 100
}
