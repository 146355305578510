import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { connect } from 'react-redux'
import { validateClinicalResearch } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import { createClinicalResearch } from '../../../actions'

// const EX = {
//   name: '',
//   doctor: null,
//   cro: '',
//   sponsor: '',
//   status: 'active',
//   //
//   invoiceTo: '',
//   reimbursement: '',
//   notes: '',
//   stage: '',
//   daysOfPatientInCR: '',
//   drugDescription: '',
//   structureOfStudy: '',
//   disease: '',
//   phase: ''
// }

function ClinicalResearchForm (props) {
  const { doctors, onHide } = props

  const [query, setQuery] = useState({ status: 'active' })
  const [loading, setLoading] = useState(false)
  const handleUpdateQuery = (field, value) => {
    const _query = validateClinicalResearch({ ...query, [field]: value })

    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createClinicalResearch({
      data: validateClinicalResearch(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const disabled = !query.name || !query.cro || !query.doctor || !query.sponsor
  return (
    <>
      <Row>

        <Col>
          <Form.Label>
            {t('fields.name')}*
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('name', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.cro')}*
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('cro', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.invoiceTo')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('invoiceTo', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.notes')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('notes', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.daysOfPatientInCR')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('daysOfPatientInCR', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.structureOfStudy')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('structureOfStudy', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.phase')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('phase', e.target.value)}
          />
        </Col>

        <Col>
          <Form.Label>
            {t('fields.doctor')}*
          </Form.Label>
          <Form.Select
            value={query.doctor}
            onChange={e => handleUpdateQuery('doctor', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {doctors.map(doctor => {
              return (
                <option
                  key={doctor._id}
                  value={doctor._id}
                >
                  {doctor.name}
                </option>
              )
            })}
          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.sponsor')}*
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('sponsor', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.reimbursement')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('reimbursement', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.stage')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('stage', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.drugDescription')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('drugDescription', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.disease')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('disease', e.target.value)}
          />
        </Col>
      </Row>
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapStateToProps = state => ({
  doctors: state.dashboard.doctors
})

const mapDispatchToProps = dispatch => ({
  createClinicalResearch: payload => dispatch(createClinicalResearch(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalResearchForm)
