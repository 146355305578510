import { loginTypes, utilityTypes } from '../actions'

const initialState = {
  tasks: undefined
}

const handleRemoveTableItemSuccess = (tableState, payload) => {
  return tableState.filter(item => item._id !== payload._id)
}

const handleCreateTableItemSuccess = (tableState, payload) => {
  return [payload, ...tableState]
}

const handleUpdateTableStart = (tableState, payload) => {
  return tableState.map(item => item._id === payload._id ? { ...item, ...payload.data, _pending: item } : item)
}
const handleUpdateTableSuccess = (tableState, payload) => {
  return tableState.map(item => item._id === payload._id ? payload : item)
}
const handleUpdateTableFailed = (tableState, payload) => {
  return tableState.map(item => item._id === payload._id ? item._pending : item)
}

const utility = (state = initialState, { type, payload }) => {
  switch (type) {
    case utilityTypes.GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: payload
      }
    case utilityTypes.EDIT_TASK:
      return {
        ...state,
        tasks: handleUpdateTableStart(state.tasks, payload)
      }
    case utilityTypes.EDIT_TASK_SUCCESS:
      return {
        ...state,
        tasks: handleUpdateTableSuccess(state.tasks, payload)
      }
    case utilityTypes.EDIT_TASK_FAILED:
      return {
        ...state,
        tasks: handleUpdateTableFailed(state.tasks, payload)
      }
    case utilityTypes.DELETE_TASK_SUCCESS:
      return {
        ...state,
        tasks: handleRemoveTableItemSuccess(state.tasks, payload)
      }
    case utilityTypes.CREATE_TASK_SUCCESS:
      return {
        ...state,
        tasks: handleCreateTableItemSuccess(state.tasks, payload)
      }
    case loginTypes.DEAUTHENTICATE:
      return initialState
    default:
      return state
  }
}

export default utility
