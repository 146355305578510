import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TABLES } from '../../../config/constants'
import { connect } from 'react-redux'
import { getGridColDefs, getGridRowData, handleCellEditRequest } from '../../../utils/grid'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'

function ReportAllInvoices (props) {
  const { dashboard, params } = props
  const table = TABLES.INVOICES
  const tab = 'active'
  const gridRef = useRef(null)

  const defaultColDef = useMemo(() => ({ filter: true, editable: true, resizable: true })) // Enable on all columns

  const [colDefs, setColDefs] = useState([])
  const rowData = useMemo(() => getGridRowData(table)?.filter(item => item.clinicalResearches.includes(params.id)), [table, dashboard, params])

  useEffect(() => {
    const _colDefs = getGridColDefs(table, tab)
    setColDefs(_colDefs)
  }, [table, dashboard, tab])

  const gridOptions = useMemo(() => {
    return {
      getRowStyle: (params) => ({ backgroundColor: params.data.color || 'white' })
    }
  }, [])

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <div className='ag-theme-quartz' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          pagination
          readOnlyEdit
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={colDefs}
          gridOptions={gridOptions}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          onCellEditRequest={(event) => {
            handleCellEditRequest(table, event)
          }}
        />
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps)(ReportAllInvoices)
