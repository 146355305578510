import React, { useMemo, useRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { t } from '../../../config'
import { BsPrinter } from 'react-icons/bs'
import { useReactToPrint } from 'react-to-print'

const PRINT_AVAILABLE = ['scheduledProcedures']

function ModalInfo (props) {
  const { onHide, show, type, prefill } = props

  const componentRef = useRef(null)
  const content = useMemo(() => {
    switch (type) {
      case 'scheduledProcedures':
        return {
          title: `${t('modals.proceduresForVisit')}`,
          render: () => {
            return (
              <div
                ref={componentRef}
              >
                <p>{t('fields.clinicalResearch')}: <b>{prefill.clinicalResearch}</b></p>
                <p>{t('fields.visit')}: <b>{prefill.visit}</b></p>
                <p>{t('fields.patient')}: <b>{prefill.patient}</b></p>
                <h5>{t('fields.procedures')}</h5>
                {prefill.procedures?.map((procedure, index) => {
                  return (
                    <div key={'procedure' + index}>
                      <p className='my-1'>{index + 1}. {procedure}</p>
                    </div>
                  )
                })}
                {!prefill.procedures?.length && <p><i>{t('modals.proceduresForVisitEmpty')}</i></p>}

              </div>
            )
          }
        }
    }
  }, [type, prefill])

  const handlePrint = useReactToPrint({
    bodyClass: 'p-5',
    content: () => componentRef.current
  })

  if (!content) return null
  return (
    <Modal
      onHide={onHide}
      show={show}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header
        closeButton
      >
        <Modal.Title id='contained-modal-title-vcenter'>
          {content.title}
          {PRINT_AVAILABLE.includes(type) &&
            <Button
              className='ms-3'
              onClick={handlePrint}
            >
              <BsPrinter
                className='me-1'
              />
              {t('common.print')}
            </Button>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content.render()}
      </Modal.Body>
    </Modal>
  )
}

export default ModalInfo
