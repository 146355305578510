import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import FormFooter from '../FormFooter'
import moment from 'moment'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { editClinicalResearchTemplate } from '../../../actions'
import { validateClinicalResearch } from '../../../utils/validation'
import { t } from '../../../config'
import { handleDangerConfirm } from '../../../utils'
// const EX = {
//   clinicalResearch: '',
//   reason: '',
//   budgetMhat: 0,
//   budgetPI: 0,
//   currency: 'BGN',
// }
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const VisitItem = ({ data, onChange, checked }) => {
  return (
    <div
      className='my-1'
      style={{ backgroundColor: '#eae7e6', borderRadius: 8, height: 34, display: 'flex', alignItems: 'center', paddingLeft: 10, overflow: 'hidden' }}
    >
      <Form.Check
        label={data.label}
        onChange={onChange}
        type='checkbox'
        checked={checked}
        style={{ overflow: 'hidden', height: 24 }}
      />
    </div>
  )
}
function TemplateForm (props) {
  const { onHide, data } = props
  const allVisits = props.visits.filter(item => item.clinicalResearch === data._id).sort((a, b) => moment(a.createdAt).isAfter(moment(b.createdAt)) ? 1 : -1).map(item => ({ _id: item._id, label: item.reason }))

  const visitsPrefill = data.template
    ? data.template.visits.map(item => allVisits.find(visit => visit._id === item))
    : allVisits

  const daysPrefill = data.template
    ? data.template.daysAfter
    : Array.from({ length: visitsPrefill.length }, () => '')

  const [visitsList, setVisitsList] = useState(visitsPrefill)
  const [daysList, setDaysList] = useState(daysPrefill)

  const [loading, setLoading] = useState(false)

  const handledDragEnd = (result) => {
    if (!result.destination) return
    const items = reorder(
      visitsList,
      result.source.index,
      result.destination.index
    )
    setVisitsList(items)
  }

  const handleEditTemplate = () => {
    setLoading(true)
    props.editClinicalResearchTemplate({
      _id: data._id,
      key: 'clinicalResearches',
      data: validateClinicalResearch({ template: { visits: visitsList.map(item => item._id), daysAfter: daysList } }),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const handleDeleteTemplate = () => {
    handleDangerConfirm({
      onConfirm: () => {
        setLoading(true)
        props.editClinicalResearchTemplate({
          _id: data._id,
          key: 'clinicalResearches',
          data: validateClinicalResearch({ template: null }),
          onSuccess: () => onHide(),
          onFailure: () => setLoading(false)
        })
      }
    })
  }

  const handleToggleVisit = (visitId) => {
    const _visitsList = visitsList.find(item => item._id === visitId) ? visitsList.filter(item => item._id !== visitId) : [...visitsList, allVisits.find(item => item._id === visitId)]
    const _daysList = visitsList.find(item => item._id === visitId) ? daysList.slice(0, _visitsList.length) : [...daysList, '']

    if (_visitsList.length < 2) return
    setVisitsList(_visitsList)
    setDaysList(_daysList)
  }

  const disabled = visitsList.length <= 2 || (visitsList.length !== daysList.length) || daysList.some(item => !item)
  return (
    <>
      <div className='d-grid' style={{ maxWidth: 600, margin: 'auto' }}>
        <Row style={{ }}>
          <Col style={{ width: 250, display: 'flex', flexDirection: 'column' }}>
            <p>{t('fields.visits')}</p>
            <DragDropContext
              onDragEnd={handledDragEnd}
            >
              <Droppable
                droppableId='droppable'
              >
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {visitsList.map((item, index) => (
                      <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <VisitItem
                              data={item}
                              checked
                              onChange={() => handleToggleVisit(item._id)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {allVisits.length !== visitsList.length &&
              <>
                <hr />
                {allVisits.filter(item => !visitsList.find(visit => visit._id === item._id)).map((item) => {
                  return (
                    <VisitItem
                      key={item._id}
                      data={item}
                      checked={false}
                      onChange={() => handleToggleVisit(item._id)}
                    />
                  )
                })}
              </>}
          </Col>
          <Col
            md='auto'
          >
            <p style={{ marginBottom: 21 }}>{t('common.daysTilVisit')}</p>

            {daysList.map((item, index) => {
              return (
                <Form.Control
                  style={{ height: 34, width: 75 }}
                  key={'input' + index}
                  size='sm'
                  type='number'
                  className='my-1'
                  value={item}
                  onChange={e => {
                    const _daysList = [...daysList]
                    _daysList[index] = Math.abs(Math.round(e.target.value)) || ''
                    setDaysList(_daysList)
                  }}
                />
              )
            })}
          </Col>
        </Row>
      </div>

      <FormFooter
        loading={loading}
        disabled={disabled}
        text={t(data.template ? 'common.edit' : 'common.add')}
        onDelete={data.template ? handleDeleteTemplate : null}
        onClick={handleEditTemplate}
      />
    </>
  )
}

const mapStateToProps = state => ({
  visits: state.dashboard.visits
})

const mapDispatchToProps = dispatch => ({
  editClinicalResearchTemplate: (payload) => dispatch(editClinicalResearchTemplate(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateForm)
