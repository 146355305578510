import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { validateCompany } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import { connect } from 'react-redux'
import { createCompany } from '../../../actions'

// const EX = {
//   name: ''
//   notes: ''
// }

function CompanyForm (props) {
  const { onHide } = props

  const [query, setQuery] = useState({})
  const [loading, setLoading] = useState(false)

  const handleUpdateQuery = (field, value) => {
    const _query = validateCompany({ ...query, [field]: value })

    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createCompany({
      data: validateCompany(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const disabled = !query.name
  return (
    <>
      <Row>
        <Col>
          <Form.Label>
            {t('fields.name')}*
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('name', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.notes')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('notes', e.target.value)}
          />
        </Col>
      </Row>
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  createCompany: payload => dispatch(createCompany(payload))
})

export default connect(null, mapDispatchToProps)(CompanyForm)
