export const utilityTypes = {
  SET_UTILITY_FIELDS: 'utility/SET_UTILITY_FIELDS',

  GET_TASKS: 'utility/GET_TASKS',
  GET_TASKS_SUCCESS: 'utility/GET_TASKS_SUCCESS',

  CREATE_TASK: 'utility/CREATE_TASK',
  CREATE_TASK_SUCCESS: 'utility/CREATE_TASK_SUCCESS',

  EDIT_TASK: 'utility/EDIT_TASK',
  EDIT_TASK_SUCCESS: 'utility/EDIT_TASK_SUCCESS',
  EDIT_TASK_FAILED: 'utility/EDIT_TASK_FAILED',

  DELETE_TASK: 'utility/DELETE_TASK',
  DELETE_TASK_SUCCESS: 'utility/DELETE_TASK_SUCCESS'
}

export const setUtilityFields = payload => ({
  type: utilityTypes.SET_UTILITY_FIELDS,
  payload
})

export const getTasks = payload => ({
  type: utilityTypes.GET_TASKS,
  payload
})

export const createTask = payload => ({
  type: utilityTypes.CREATE_TASK,
  payload
})

export const editTask = payload => ({
  type: utilityTypes.EDIT_TASK,
  payload
})

export const deleteTask = payload => ({
  type: utilityTypes.DELETE_TASK,
  payload
})
