import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { connect } from 'react-redux'
import { deleteTask, editTask, getTasks } from '../../../actions'
import { ColorEditor } from '../../cells'
import { getControlColDef } from '../../../utils/grid'
import { handleDangerConfirm } from '../../../utils'
import { store } from '../../../config'
import { validateTask } from '../../../utils/validation'
import _ from 'lodash'

const getGridColDefs = () => {
  const { tasks } = store.getState().utility
  if (!tasks) return undefined
  return [
    {
      filter: false,
      sortable: false,
      width: 70,
      colId: 'taskColor',
      field: 'color',
      cellDataType: 'text',
      cellEditor: ColorEditor,
      valueFormatter: () => ''
    },
    {
      flex: 1,
      colId: 'taskDescription',
      field: 'description',
      cellDataType: 'text'
    },
    {
      flex: 1,
      colId: 'taskResponsible',
      field: 'responsible',
      cellDataType: 'text'
    },
    {
      flex: 1,
      colId: 'taskNotes',
      field: 'notes',
      cellDataType: 'text'
    },
    {
      ...getControlColDef('taskColId'),
      cellRendererParams: (params) => ({
        onDelete: () => {
          handleDangerConfirm({
            onConfirm: () => {
              store.dispatch(deleteTask({ _id: params.data._id }))
            }
          })
        }

      })
    }
  ].filter(item => item)
}

function TasksGrid (props) {
  const { tasks } = props
  const gridRef = useRef(null)

  const defaultColDef = useMemo(() => ({ filter: true, editable: true, resizable: true })) // Enable on all columns
  const [colDefs, setColDefs] = useState([])
  const rowData = tasks

  const gridOptions = useMemo(() => {
    return {
      getRowStyle: (params) => ({ backgroundColor: params.data.color || 'white' })
    }
  }, [])

  useEffect(() => {
    props.getTasks()
  }, [])

  useEffect(() => {
    const _colDefs = getGridColDefs(tasks)
    setColDefs(_colDefs)
  }, [tasks])

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <div className='ag-theme-quartz' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          pagination
          readOnlyEdit
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={colDefs}
          gridOptions={gridOptions}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          onCellEditRequest={(event) => {
            const data = validateTask({
              [event.colDef.field]: event.newValue
            })

            if (_.isEmpty(data)) return

            store.dispatch(editTask({
              _id: event.data._id,
              data
            }))
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  tasks: state.utility.tasks
})

const mapDispatchToProps = dispatch => ({
  getTasks: () => dispatch(getTasks())
})

export default connect(mapStateToProps, mapDispatchToProps)(TasksGrid)
