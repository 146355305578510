import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { connect } from 'react-redux'
import { validateScheduledVisit } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import moment from 'moment'
import { createScheduledVisit } from '../../../actions'
import { generateAdditionalSVs } from '../../../utils/dashboard'

// const EX = {
//   clinicalResearch: '',
//   visit: '',
//   patient: '',
//   amendment: '',
//   date: ''
// }

function ScheduledVisitForm (props) {
  const { clinicalResearches, visits, patients, amendments, onHide } = props

  const [query, setQuery] = useState({})
  const [loading, setLoading] = useState(false)

  const [createFromTemplate, setCreateFromTemplate] = useState(false)

  const handleUpdateQuery = (field, value) => {
    const _query = validateScheduledVisit({ ...query, [field]: value })

    if (field === 'clinicalResearch') {
      _query.visit = ''
      _query.amendment = ''
      _query.patient = ''
    }
    if (field === 'visit') {
      _query.amendment = ''
      const _amendments = amendments.filter(a => a.visit === value)
      if (_amendments.length) {
        _query.amendment = _amendments[0]._id
      }
    }
    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createScheduledVisit({
      data: validateScheduledVisit({ ...query, additional: additionalSVs }),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const clinicalResearch = clinicalResearches.find(cr => cr._id === query.clinicalResearch)
  const additionalSVs = createFromTemplate ? generateAdditionalSVs({ template: clinicalResearch.template, ...query }) : []
  const showTemplateQuestion = clinicalResearch?.template?.visits?.includes(query.visit) && clinicalResearch?.template?.visits?.indexOf(query.visit) !== clinicalResearch?.template?.visits?.length - 1
  const disabled = !query.clinicalResearch || !query.visit || !query.patient || !query.date || !query.amendment
  return (
    <>
      <Row>

        <Col>
          <Form.Label>
            {t('fields.clinicalResearch')}*
          </Form.Label>
          <Form.Select
            value={query.clinicalResearch}
            onChange={e => handleUpdateQuery('clinicalResearch', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {clinicalResearches.map(cr => {
              return (
                <option
                  key={cr._id}
                  value={cr._id}
                >
                  {cr.name}
                </option>
              )
            })}
          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.visit')}*
          </Form.Label>
          <Form.Select
            disabled={!query.clinicalResearch}
            value={query.visit}
            onChange={e => handleUpdateQuery('visit', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {visits
              .filter(v => v.clinicalResearch === query.clinicalResearch)
              .map(v => {
                return (
                  <option
                    key={v._id}
                    value={v._id}
                  >
                    {v.reason}
                  </option>
                )
              })}
          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.date')}*
          </Form.Label>
          <Form.Control
            type='date'
            onChange={e => handleUpdateQuery('date', moment(e.target.value, 'YYYY-MM-DD').toDate())}
          />
        </Col>
        <Col>
          <Form.Label>
            {t('fields.patient')}*
          </Form.Label>
          <Form.Select
            disabled={!query.clinicalResearch}
            value={query.patient}
            onChange={e => handleUpdateQuery('patient', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {patients
              .filter(p => p.clinicalResearch === query.clinicalResearch)
              .map(p => {
                return (
                  <option
                    key={p._id}
                    value={p._id}
                  >
                    {p.name} ({p.identifier})
                  </option>
                )
              })}
          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.amendment')}*
          </Form.Label>
          <Form.Select
            disabled={!query.visit || amendments.filter(a => a.visit === query.visit).length === 1}
            value={query.amendment}
            onChange={e => handleUpdateQuery('amendment', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {amendments
              .filter(a => a.visit === query.visit)
              .map(a => {
                return (
                  <option
                    key={a._id}
                    value={a._id}
                  >
                    {a.name}
                  </option>
                )
              })}
          </Form.Select>

        </Col>
      </Row>
      {showTemplateQuestion &&
        <>
          <hr />
          <Row>
            <Col>
              <Form.Label>
                {t('forms.createSVsFromTemplate')}
              </Form.Label>
              <Form.Select
                value={createFromTemplate}
                onChange={e => setCreateFromTemplate(e.target.value === 'true')}
              >
                <option value='false'>{t('common.no')}</option>
                <option value='true'>{t('common.yes')}</option>
              </Form.Select>
              <br />
              {additionalSVs.map((sv, index) => {
                return (
                  <div key={`additional-visit-${index}`}>
                    <p>{index + 1}. {t('fields.reason')}: <b>{visits.find(item => item._id === sv.visit)?.reason}</b>; {t('fields.date')}: <b>{moment(sv.date).format('DD MMM YYYY')}</b></p>
                  </div>
                )
              })}
            </Col>
          </Row>
        </>}
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapStateToProps = state => ({
  clinicalResearches: state.dashboard.clinicalResearches,
  visits: state.dashboard.visits,
  patients: state.dashboard.patients,
  amendments: state.dashboard.amendments
})

const mapDispatchToProps = dispatch => ({
  createScheduledVisit: payload => dispatch(createScheduledVisit(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledVisitForm)
