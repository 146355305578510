import React, { useEffect, useImperativeHandle, useState } from 'react'
import { MdArchive, MdDeleteForever, MdUnarchive } from 'react-icons/md'

const styles = {
  icon: {
    cursor: 'pointer',
    alignSelf: 'center',
    marginLeft: 4,
    marginRight: 4
  }
}
const RowEditor = React.forwardRef((props, ref) => {
  const { onArchive, onUnarchive, onDelete } = props
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(true)
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return undefined
      },
      isPopup: () => {
        return false
      }
    }
  })

  if (!visible) return null

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <div>
        {!!onUnarchive &&
          <MdUnarchive
            className='text-secondary'
            onClick={onUnarchive}
            size={22}
            style={styles.icon}
          />}
        {!!onArchive &&
          <MdArchive
            className='text-secondary'
            onClick={onArchive}
            size={22}
            style={styles.icon}
          />}
        {!!onDelete &&
          <MdDeleteForever
            className='text-danger'
            onClick={onDelete}
            size={24}
            style={styles.icon}
          />}
      </div>
    </div>
  )
})

export default RowEditor
