import { handleAPIError, jwtAuth } from '.'
import { API } from '../config'
import { utils, writeFile } from 'xlsx'

export const exportTableToCSV = async (table, archived = false) => {
  try {
    const res = await window.fetch(`${API}/csv/${table}`, {
      headers: jwtAuth(),
      method: 'POST',
      body: JSON.stringify({ archived })
    })

    const response = await res.json()
    if (!res.ok) throw new Error(response.message)
    const ws = utils.json_to_sheet(response.data)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Table')

    writeFile(wb, `${table}_${archived ? 'archived' : 'active'}.csv`, { type: 'binary', bookType: 'csv' })
    return true
  } catch (err) {
    handleAPIError(err)
    return false
  }
}

export const exportCSV = (data, filename) => {
  try {
    const ws = utils.json_to_sheet(data)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Table')

    writeFile(wb, `${filename || 'export'}.csv`, { type: 'binary', bookType: 'csv' })
  } catch (err) {
    handleAPIError(err)
  }
}
