export const dashboardTypes = {
  SET_INITIAL_DASHBOARD: 'dashboard/SET_INITIAL_DASHBOARD',

  GET_DASHBOARD: 'dashboard/GET_DASHBOARD',
  GET_DASHBOARD_SUCCESS: 'dashboard/GET_DASHBOARD_SUCCESS',

  GET_ARCHIVED_DASHBOARD: 'dashboard/GET_ARCHIVED_DASHBOARD',
  GET_ARCHIVED_DASHBOARD_SUCCESS: 'dashboard/GET_ARCHIVED_DASHBOARD_SUCCESS',

  ARCHIVE_CLINICAL_RESEARCH: 'dashboard/ARCHIVE_CLINICAL_RESEARCH',
  ARCHIVE_CLINICAL_RESEARCH_SUCCESS: 'dashboard/ARCHIVE_CLINICAL_RESEARCH_SUCCESS',

  UNARCHIVE_CLINICAL_RESEARCH: 'dashboard/UNARCHIVE_CLINICAL_RESEARCH',
  UNARCHIVE_CLINICAL_RESEARCH_SUCCESS: 'dashboard/UNARCHIVE_CLINICAL_RESEARCH_SUCCESS',

  ARCHIVE_PAYMENT: 'dashboard/ARCHIVE_PAYMENT',
  ARCHIVE_PAYMENT_SUCCESS: 'dashboard/ARCHIVE_PAYMENT_SUCCESS',

  UNARCHIVE_PAYMENT: 'dashboard/UNARCHIVE_PAYMENT',
  UNARCHIVE_PAYMENT_SUCCESS: 'dashboard/UNARCHIVE_PAYMENT_SUCCESS',

  ARCHIVE_PROFORMA: 'dashboard/ARCHIVE_PROFORMA',
  ARCHIVE_PROFORMA_SUCCESS: 'dashboard/ARCHIVE_PROFORMA_SUCCESS',

  UNARCHIVE_PROFORMA: 'dashboard/UNARCHIVE_PROFORMA',
  UNARCHIVE_PROFORMA_SUCCESS: 'dashboard/UNARCHIVE_PROFORMA_SUCCESS',
  // EDIT

  EDIT_CLINICAL_RESEARCH: 'dashboard/EDIT_CLINICAL_RESEARCH',
  EDIT_CLINICAL_RESEARCH_SUCCESS: 'dashboard/EDIT_CLINICAL_RESEARCH_SUCCESS',
  EDIT_CLINICAL_RESEARCH_FAILED: 'dashboard/EDIT_CLINICAL_RESEARCH_FAILED',

  EDIT_CLINICAL_RESEARCH_TEMPLATE: 'dashboard/EDIT_CLINICAL_RESEARCH_TEMPLATE',

  EDIT_DOCTOR: 'dashboard/EDIT_DOCTOR',
  EDIT_DOCTOR_SUCCESS: 'dashboard/EDIT_DOCTOR_SUCCESS',
  EDIT_DOCTOR_FAILED: 'dashboard/EDIT_DOCTOR_FAILED',

  EDIT_PATIENT: 'dashboard/EDIT_PATIENT',
  EDIT_PATIENT_SUCCESS: 'dashboard/EDIT_PATIENT_SUCCESS',
  EDIT_PATIENT_FAILED: 'dashboard/EDIT_PATIENT_FAILED',

  EDIT_VISIT: 'dashboard/EDIT_VISIT',
  EDIT_VISIT_SUCCESS: 'dashboard/EDIT_VISIT_SUCCESS',
  EDIT_VISIT_FAILED: 'dashboard/EDIT_VISIT_FAILED',

  EDIT_SCHEDULED_VISIT: 'dashboard/EDIT_SCHEDULED_VISIT',
  EDIT_SCHEDULED_VISIT_SUCCESS: 'dashboard/EDIT_SCHEDULED_VISIT_SUCCESS',
  EDIT_SCHEDULED_VISIT_FAILED: 'dashboard/EDIT_SCHEDULED_VISIT_FAILED',

  EDIT_ACTUAL_VISIT: 'dashboard/EDIT_ACTUAL_VISIT',
  EDIT_ACTUAL_VISIT_SUCCESS: 'dashboard/EDIT_ACTUAL_VISIT_SUCCESS',
  EDIT_ACTUAL_VISIT_FAILED: 'dashboard/EDIT_ACTUAL_VISIT_FAILED',

  EDIT_PROFORMA: 'dashboard/EDIT_PROFORMA',
  EDIT_PROFORMA_SUCCESS: 'dashboard/EDIT_PROFORMA_SUCCESS',
  EDIT_PROFORMA_FAILED: 'dashboard/EDIT_PROFORMA_FAILED',

  EDIT_PAYMENT: 'dashboard/EDIT_PAYMENT',
  EDIT_PAYMENT_SUCCESS: 'dashboard/EDIT_PAYMENT_SUCCESS',
  EDIT_PAYMENT_FAILED: 'dashboard/EDIT_PAYMENT_FAILED',

  EDIT_INVOICE: 'dashboard/EDIT_INVOICE',
  EDIT_INVOICE_SUCCESS: 'dashboard/EDIT_INVOICE_SUCCESS',
  EDIT_INVOICE_FAILED: 'dashboard/EDIT_INVOICE_FAILED',

  EDIT_PROCEDURE: 'dashboard/EDIT_PROCEDURE',
  EDIT_PROCEDURE_SUCCESS: 'dashboard/EDIT_PROCEDURE_SUCCESS',
  EDIT_PROCEDURE_FAILED: 'dashboard/EDIT_PROCEDURE_FAILED',

  EDIT_LAB_KIT: 'dashboard/EDIT_LAB_KIT',
  EDIT_LAB_KIT_SUCCESS: 'dashboard/EDIT_LAB_KIT_SUCCESS',
  EDIT_LAB_KIT_FAILED: 'dashboard/EDIT_LAB_KIT_FAILED',

  EDIT_AMENDMENT: 'dashboard/EDIT_AMENDMENT',
  EDIT_AMENDMENT_SUCCESS: 'dashboard/EDIT_AMENDMENT_SUCCESS',
  EDIT_AMENDMENT_FAILED: 'dashboard/EDIT_AMENDMENT_FAILED',

  EDIT_COMPANY: 'dashboard/EDIT_COMPANY',
  EDIT_COMPANY_SUCCESS: 'dashboard/EDIT_COMPANY_SUCCESS',
  EDIT_COMPANY_FAILED: 'dashboard/EDIT_COMPANY_FAILED',

  EDIT_REVENUE: 'dashboard/EDIT_REVENUE',
  EDIT_REVENUE_SUCCESS: 'dashboard/EDIT_REVENUE_SUCCESS',
  EDIT_REVENUE_FAILED: 'dashboard/EDIT_REVENUE_FAILED',

  // DELETE
  DELETE_CLINICAL_RESEARCH: 'dashboard/DELETE_CLINICAL_RESEARCH',
  DELETE_CLINICAL_RESEARCH_SUCCESS: 'dashboard/DELETE_CLINICAL_RESEARCH_SUCCESS',

  DELETE_DOCTOR: 'dashboard/DELETE_DOCTOR',
  DELETE_DOCTOR_SUCCESS: 'dashboard/DELETE_DOCTOR_SUCCESS',

  DELETE_PATIENT: 'dashboard/DELETE_PATIENT',
  DELETE_PATIENT_SUCCESS: 'dashboard/DELETE_PATIENT_SUCCESS',

  DELETE_VISIT: 'dashboard/DELETE_VISIT',
  DELETE_VISIT_SUCCESS: 'dashboard/DELETE_VISIT_SUCCESS',

  DELETE_SCHEDULED_VISIT: 'dashboard/DELETE_SCHEDULED_VISIT',
  DELETE_SCHEDULED_VISIT_SUCCESS: 'dashboard/DELETE_SCHEDULED_VISIT_SUCCESS',

  DELETE_ACTUAL_VISIT: 'dashboard/DELETE_ACTUAL_VISIT',
  DELETE_ACTUAL_VISIT_SUCCESS: 'dashboard/DELETE_ACTUAL_VISIT_SUCCESS',

  DELETE_PROFORMA: 'dashboard/DELETE_PROFORMA',
  DELETE_PROFORMA_SUCCESS: 'dashboard/DELETE_PROFORMA_SUCCESS',

  DELETE_PAYMENT: 'dashboard/DELETE_PAYMENT',
  DELETE_PAYMENT_SUCCESS: 'dashboard/DELETE_PAYMENT_SUCCESS',

  DELETE_INVOICE: 'dashboard/DELETE_INVOICE',
  DELETE_INVOICE_SUCCESS: 'dashboard/DELETE_INVOICE_SUCCESS',

  DELETE_PROCEDURE: 'dashboard/DELETE_PROCEDURE',
  DELETE_PROCEDURE_SUCCESS: 'dashboard/DELETE_PROCEDURE_SUCCESS',

  DELETE_LAB_KIT: 'dashboard/DELETE_LAB_KIT',
  DELETE_LAB_KIT_SUCCESS: 'dashboard/DELETE_LAB_KIT_SUCCESS',

  DELETE_AMENDMENT: 'dashboard/DELETE_AMENDMENT',
  DELETE_AMENDMENT_SUCCESS: 'dashboard/DELETE_AMENDMENT_SUCCESS',

  DELETE_COMPANY: 'dashboard/DELETE_COMPANY',
  DELETE_COMPANY_SUCCESS: 'dashboard/DELETE_COMPANY_SUCCESS',

  DELETE_REVENUE: 'dashboard/DELETE_REVENUE',
  DELETE_REVENUE_SUCCESS: 'dashboard/DELETE_REVENUE_SUCCESS',

  // ADD
  CREATE_CLINICAL_RESEARCH: 'dashboard/CREATE_CLINICAL_RESEARCH',
  CREATE_CLINICAL_RESEARCH_SUCCESS: 'dashboard/CREATE_CLINICAL_RESEARCH_SUCCESS',

  CREATE_DOCTOR: 'dashboard/CREATE_DOCTOR',
  CREATE_DOCTOR_SUCCESS: 'dashboard/CREATE_DOCTOR_SUCCESS',

  CREATE_PATIENT: 'dashboard/CREATE_PATIENT',
  CREATE_PATIENT_SUCCESS: 'dashboard/CREATE_PATIENT_SUCCESS',

  CREATE_VISIT: 'dashboard/CREATE_VISIT',
  CREATE_VISIT_SUCCESS: 'dashboard/CREATE_VISIT_SUCCESS',

  CREATE_SCHEDULED_VISIT: 'dashboard/CREATE_SCHEDULED_VISIT',
  CREATE_SCHEDULED_VISIT_SUCCESS: 'dashboard/CREATE_SCHEDULED_VISIT_SUCCESS',

  CREATE_MULTIPLE_SCHEDULED_VISITS_SUCCESS: 'dashboard/CREATE_MULTIPLE_SCHEDULED_VISITS_SUCCESS',

  CREATE_ACTUAL_VISIT: 'dashboard/CREATE_ACTUAL_VISIT',
  CREATE_ACTUAL_VISIT_SUCCESS: 'dashboard/CREATE_ACTUAL_VISIT_SUCCESS',

  CREATE_PROFORMA: 'dashboard/CREATE_PROFORMA',
  CREATE_PROFORMA_SUCCESS: 'dashboard/CREATE_PROFORMA_SUCCESS',

  CREATE_PAYMENT: 'dashboard/CREATE_PAYMENT',
  CREATE_PAYMENT_SUCCESS: 'dashboard/CREATE_PAYMENT_SUCCESS',

  CREATE_INVOICE: 'dashboard/CREATE_INVOICE',
  CREATE_INVOICE_SUCCESS: 'dashboard/CREATE_INVOICE_SUCCESS',

  CREATE_PROCEDURE: 'dashboard/CREATE_PROCEDURE',
  CREATE_PROCEDURE_SUCCESS: 'dashboard/CREATE_PROCEDURE_SUCCESS',

  CREATE_LAB_KIT: 'dashboard/CREATE_LAB_KIT',
  CREATE_LAB_KIT_SUCCESS: 'dashboard/CREATE_LAB_KIT_SUCCESS',

  CREATE_AMENDMENT: 'dashboard/CREATE_AMENDMENT',
  CREATE_AMENDMENT_SUCCESS: 'dashboard/CREATE_AMENDMENT_SUCCESS',

  CREATE_COMPANY: 'dashboard/CREATE_COMPANY',
  CREATE_COMPANY_SUCCESS: 'dashboard/CREATE_COMPANY_SUCCESS',

  CREATE_REVENUE: 'dashboard/CREATE_REVENUE',
  CREATE_REVENUE_SUCCESS: 'dashboard/CREATE_REVENUE_SUCCESS'
}

export const createAmendment = payload => ({
  type: dashboardTypes.CREATE_AMENDMENT,
  payload
})

export const createClinicalResearch = payload => ({
  type: dashboardTypes.CREATE_CLINICAL_RESEARCH,
  payload
})

export const createDoctor = payload => ({
  type: dashboardTypes.CREATE_DOCTOR,
  payload
})

export const createCompany = payload => ({
  type: dashboardTypes.CREATE_COMPANY,
  payload
})

export const createRevenue = payload => ({
  type: dashboardTypes.CREATE_REVENUE,
  payload
})

export const createPatient = payload => ({
  type: dashboardTypes.CREATE_PATIENT,
  payload
})

export const createVisit = payload => ({
  type: dashboardTypes.CREATE_VISIT,
  payload
})

export const createScheduledVisit = payload => ({
  type: dashboardTypes.CREATE_SCHEDULED_VISIT,
  payload
})

export const createActualVisit = payload => ({
  type: dashboardTypes.CREATE_ACTUAL_VISIT,
  payload
})

export const createProforma = payload => ({
  type: dashboardTypes.CREATE_PROFORMA,
  payload
})

export const createPayment = payload => ({
  type: dashboardTypes.CREATE_PAYMENT,
  payload
})

export const createInvoice = payload => ({
  type: dashboardTypes.CREATE_INVOICE,
  payload
})

export const createProcedure = payload => ({
  type: dashboardTypes.CREATE_PROCEDURE,
  payload
})

export const createLabKit = payload => ({
  type: dashboardTypes.CREATE_LAB_KIT,
  payload
})

export const deleteAmendment = payload => ({
  type: dashboardTypes.DELETE_AMENDMENT,
  payload
})

export const deleteClinicalResearch = payload => ({
  type: dashboardTypes.DELETE_CLINICAL_RESEARCH,
  payload
})

export const deleteDoctor = payload => ({
  type: dashboardTypes.DELETE_DOCTOR,
  payload
})

export const deleteCompany = payload => ({
  type: dashboardTypes.DELETE_COMPANY,
  payload
})

export const deleteRevenue = payload => ({
  type: dashboardTypes.DELETE_REVENUE,
  payload
})

export const deletePatient = payload => ({
  type: dashboardTypes.DELETE_PATIENT,
  payload
})

export const deleteVisit = payload => ({
  type: dashboardTypes.DELETE_VISIT,
  payload
})

export const deleteScheduledVisit = payload => ({
  type: dashboardTypes.DELETE_SCHEDULED_VISIT,
  payload
})

export const deleteActualVisit = payload => ({
  type: dashboardTypes.DELETE_ACTUAL_VISIT,
  payload
})

export const deleteProforma = payload => ({
  type: dashboardTypes.DELETE_PROFORMA,
  payload
})

export const deletePayment = payload => ({
  type: dashboardTypes.DELETE_PAYMENT,
  payload
})

export const deleteInvoice = payload => ({
  type: dashboardTypes.DELETE_INVOICE,
  payload
})

export const deleteProcedure = payload => ({
  type: dashboardTypes.DELETE_PROCEDURE,
  payload
})

export const deleteLabKit = payload => ({
  type: dashboardTypes.DELETE_LAB_KIT,
  payload
})

export const editAmendment = payload => ({
  type: dashboardTypes.EDIT_AMENDMENT,
  payload
})

export const editLabKit = payload => ({
  type: dashboardTypes.EDIT_LAB_KIT,
  payload
})

export const editProcedure = payload => ({
  type: dashboardTypes.EDIT_PROCEDURE,
  payload
})

export const editInvoice = payload => ({
  type: dashboardTypes.EDIT_INVOICE,
  payload
})

export const editPayment = payload => ({
  type: dashboardTypes.EDIT_PAYMENT,
  payload
})

export const editProforma = payload => ({
  type: dashboardTypes.EDIT_PROFORMA,
  payload
})

export const editActualVisit = payload => ({
  type: dashboardTypes.EDIT_ACTUAL_VISIT,
  payload
})

export const editScheduledVisit = payload => ({
  type: dashboardTypes.EDIT_SCHEDULED_VISIT,
  payload
})

export const editVisit = payload => ({
  type: dashboardTypes.EDIT_VISIT,
  payload
})

export const editPatient = payload => ({
  type: dashboardTypes.EDIT_PATIENT,
  payload
})

export const editDoctor = payload => ({
  type: dashboardTypes.EDIT_DOCTOR,
  payload
})

export const editClinicalResearch = payload => ({
  type: dashboardTypes.EDIT_CLINICAL_RESEARCH,
  payload
})

export const editClinicalResearchTemplate = payload => ({
  type: dashboardTypes.EDIT_CLINICAL_RESEARCH_TEMPLATE,
  payload
})

export const editCompany = payload => ({
  type: dashboardTypes.EDIT_COMPANY,
  payload
})

export const editRevenue = payload => ({
  type: dashboardTypes.EDIT_REVENUE,
  payload
})

export const getDashboard = payload => ({
  type: dashboardTypes.GET_DASHBOARD,
  payload
})

export const getArchivedDashboard = payload => ({
  type: dashboardTypes.GET_ARCHIVED_DASHBOARD,
  payload
})

export const archiveClinicalResearch = payload => ({
  type: dashboardTypes.ARCHIVE_CLINICAL_RESEARCH,
  payload
})

export const unarchiveClinicalResearch = payload => ({
  type: dashboardTypes.UNARCHIVE_CLINICAL_RESEARCH,
  payload
})

export const setInitialDashboard = payload => ({
  type: dashboardTypes.SET_INITIAL_DASHBOARD,
  payload
})

export const archivePayment = payload => ({
  type: dashboardTypes.ARCHIVE_PAYMENT,
  payload
})

export const unarchivePayment = payload => ({
  type: dashboardTypes.UNARCHIVE_PAYMENT,
  payload
})

export const archiveProforma = payload => ({
  type: dashboardTypes.ARCHIVE_PROFORMA,
  payload
})

export const unarchiveProforma = payload => ({
  type: dashboardTypes.UNARCHIVE_PROFORMA,
  payload
})
