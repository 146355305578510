import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { getGridColDefs, getGridRowData, getTreasuryBalance, handleCellEditRequest } from '../../../utils/treasury'
import { connect } from 'react-redux'
import { getTreasuryMovements } from '../../../actions/treasury'
import { t } from '../../../config'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'

function TreasuryGrid (props) {
  const FILTERS = [
    { label: t('common.all'), value: null },
    { label: t('common.thisMonth'), value: 'thisMonth' },
    { label: t('common.lastMonth'), value: 'lastMonth' }
  ]
  const { movements } = props
  const gridRef = useRef(null)

  const [filter, setFilter] = useState('thisMonth')

  const defaultColDef = useMemo(() => ({ filter: true, editable: true, resizable: true })) // Enable on all columns
  const [colDefs, setColDefs] = useState([])
  const rowData = useMemo(() => getGridRowData(filter), [movements, filter])

  useEffect(() => {
    props.getTreasuryMovements()
  }, [])

  useEffect(() => {
    const _colDefs = getGridColDefs()
    setColDefs(_colDefs)
  }, [movements])

  const balance = getTreasuryBalance(movements)

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <h3>{t('dashboard.treasury.balance')}: <span className={balance < 0 ? 'text-danger' : 'text-success'}>{movements?.length ? balance.toFixed(2) : 'N/A'}</span></h3>
      <ButtonGroup
        className='my-3'
        style={{ width: 350 }}
      >
        {FILTERS.map((item, index) => {
          return (
            <ToggleButton
              key={item.label}
              variant={filter === item.value ? 'primary' : 'secondary'}
              onClick={() => setFilter(item.value)}
            >
              {item.label}
            </ToggleButton>
          )
        })}
      </ButtonGroup>
      <div className='ag-theme-quartz' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef}
          pagination
          readOnlyEdit
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={colDefs}
          singleClickEdit
          stopEditingWhenCellsLoseFocus
          onCellEditRequest={(event) => {
            handleCellEditRequest(event)
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  movements: state.treasury.movements
})

const mapDispatchToProps = dispatch => ({
  getTreasuryMovements: () => dispatch(getTreasuryMovements())
})

export default connect(mapStateToProps, mapDispatchToProps)(TreasuryGrid)
