import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { connect } from 'react-redux'
import { validateVisit } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import { CURRENCIES } from '../../../config/constants'
import _ from 'lodash'
import { createVisit } from '../../../actions'

// const EX = {
//   clinicalResearch: '',
//   reason: '',
//   budgetMhat: 0,
//   budgetPI: 0,
//   currency: 'BGN',
// }

function VisitForm (props) {
  const { clinicalResearches, onHide } = props
  const [query, setQuery] = useState({ currency: 'BGN' })
  const [loading, setLoading] = useState(false)

  const handleUpdateQuery = (field, value) => {
    const _query = validateVisit({ ...query, [field]: value })
    if (field === 'budgetMhat' || field === 'budgetPI') {
      if (_query[field] === undefined) {
        _query[field] = 0
      }
    }
    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createVisit({
      data: validateVisit(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const disabled = !query.clinicalResearch || !query.reason
  return (
    <>
      <Row>

        <Col>
          <Form.Label>
            {t('fields.clinicalResearch')}*
          </Form.Label>
          <Form.Select
            value={query.clinicalResearch}
            onChange={e => handleUpdateQuery('clinicalResearch', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {clinicalResearches.map(cr => {
              return (
                <option
                  key={cr._id}
                  value={cr._id}
                >
                  {cr.name}
                </option>
              )
            })}
          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.reason')}*
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('reason', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.budgetMhat')}
          </Form.Label>
          <Form.Control
            type='number'
            onChange={e => handleUpdateQuery('budgetMhat', e.target.value)}
            onFocus={e => e.target.select()}
          />

        </Col>
        <Col>
          <Form.Label>
            {t('fields.budgetPI')}
          </Form.Label>
          <Form.Control
            type='number'
            onChange={e => handleUpdateQuery('budgetPI', e.target.value)}
            onFocus={e => e.target.select()}
          />
          <br />
          <Form.Label>
            {t('fields.currency')}
          </Form.Label>
          <Form.Select
            value={query.currency}
            onChange={e => handleUpdateQuery('currency', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {CURRENCIES.map(c => {
              return (
                <option
                  key={c}
                  value={c}
                >
                  {c}
                </option>
              )
            })}
          </Form.Select>
        </Col>
      </Row>
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapStateToProps = state => ({
  clinicalResearches: state.dashboard.clinicalResearches
})

const mapDispatchToProps = dispatch => ({
  createVisit: payload => dispatch(createVisit(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(VisitForm)
