import { ofType } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { Observable } from 'rxjs'
import { API } from '../config'
import { handleAPIError, jwtAuth } from '../utils'
import { utilityTypes } from '../actions'

export const getTasksEpic = action$ =>
  action$.pipe(
    ofType(utilityTypes.GET_TASKS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/task/list`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload?.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: utilityTypes.GET_TASKS_SUCCESS,
              payload: response.tasks
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const editTaskEpic = action$ =>
  action$.pipe(
    ofType(utilityTypes.EDIT_TASK),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/task/${payload._id}`,
        method: 'PUT',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: utilityTypes.EDIT_TASK_SUCCESS,
              payload: response.task
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.next({
              type: utilityTypes.EDIT_TASK_FAILED,
              payload
            })
            obs.complete()
          })
        })
      )
    })
  )

export const deleteTaskEpic = action$ =>
  action$.pipe(
    ofType(utilityTypes.DELETE_TASK),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/task/${payload._id}`,
        method: 'DELETE',
        headers: jwtAuth()
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            obs.next({
              type: utilityTypes.DELETE_TASK_SUCCESS,
              payload: response.task
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )

export const createTaskEpic = action$ =>
  action$.pipe(
    ofType(utilityTypes.CREATE_TASK),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API}/task/create`,
        method: 'POST',
        headers: jwtAuth(),
        body: JSON.stringify(payload.data)
      }).pipe(
        switchMap(({ response }) => {
          return new Observable(obs => {
            if (payload?.onSuccess) payload.onSuccess()
            obs.next({
              type: utilityTypes.CREATE_TASK_SUCCESS,
              payload: response.task
            })
            obs.complete()
          })
        }),
        catchError(err => {
          return new Observable(obs => {
            if (payload?.onFailure) payload.onFailure()
            handleAPIError(err)
            obs.complete()
          })
        })
      )
    })
  )
