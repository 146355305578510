import React, { useEffect, useImperativeHandle, useState } from 'react'
import { Button } from 'react-bootstrap'

const ButtonEditor = React.forwardRef((props, ref) => {
  const { text, onClick, disabled, disabledText } = props
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(true)
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return undefined
      },
      isPopup: () => {
        return false
      }
    }
  })

  if (!visible) return null

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      {(disabled && disabledText)
        ? <span>{disabledText}</span>
        : (
          <Button
            disabled={disabled}
            onClick={onClick}
            size='sm'
          >
            {text}
          </Button>)}
    </div>
  )
})

export default ButtonEditor
