export const generalTypes = {
  SET_GENERAL_FIELDS: 'general/SET_GENERAL_FIELDS',
  START_LOADING: 'general/START_LOADING',
  STOP_LOADING: 'general/STOP_LOADING',
  SET_LANGUAGE: 'general/SET_LANGUAGE'
}

export const setGeneralFields = (payload) => ({
  type: generalTypes.SET_GENERAL_FIELDS,
  payload
})

export const startLoading = (payload) => ({
  type: generalTypes.START_LOADING,
  payload
})
export const stopLoading = (payload) => ({
  type: generalTypes.STOP_LOADING,
  payload
})

export const setLanguage = (payload) => ({
  type: generalTypes.SET_LANGUAGE,
  payload
})
