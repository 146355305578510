export const loginTypes = {
  SET_LOGIN_FIELDS: 'login/SET_LOGIN_FIELDS',
  DEAUTHENTICATE: 'login/DEAUTHENTICATE',
  LOGIN_SIGN_IN: 'login/LOGIN_SIGN_IN',
  LOGIN_GET_MY_USER: 'login/LOGIN_GET_MY_USER'
}

export const setLoginFields = (payload) => ({
  type: loginTypes.SET_LOGIN_FIELDS,
  payload
})

export const deauthenticate = () => ({
  type: loginTypes.DEAUTHENTICATE
})

export const loginSignIn = (payload) => ({
  type: loginTypes.LOGIN_SIGN_IN,
  payload
})

export const loginGetMyUser = () => ({
  type: loginTypes.LOGIN_GET_MY_USER
})
