import React from 'react'
import { COLORS } from '../../../config/constants'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'

function ColorsGroup (props) {
  const { onClick, value, containerStyle } = props
  return (
    <ButtonGroup
      style={{ width: COLORS.length * 100, ...containerStyle }}
    >
      {COLORS.map((item) => {
        return (
          <ToggleButton
            key={item.color}
            variant='dark'
            style={!(value === item.value) ? { backgroundColor: item.color || undefined, color: '#181d1f' } : {}}
            onClick={() => onClick(item.value)}
          >
            {item.name}
          </ToggleButton>
        )
      })}
    </ButtonGroup>
  )
}

export default ColorsGroup
