import React, { useState } from 'react'
import { connect } from 'react-redux'
import { loginSignIn } from '../../../actions'
import { t } from '../../../config'
import { useNavigate } from 'react-router-dom'

function Login (props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()
    props.loginSignIn({
      data: {
        email,
        password
      },
      onSuccess: () => {
        navigate('/dashboard')
      }
    })
  }

  const handleChange = (e, field) => {
    switch (field) {
      case 'email':
        setEmail(e.target.value)
        break
      case 'password':
        setPassword(e.target.value)
        break
      default:
        break
    }
  }
  return (
    <div className='container vh-100 d-flex justify-content-center align-items-center py-4 align-self-center'>
      <main style={{ width: 300 }} className='form-signin m-auto'>
        <form
          onSubmit={handleSubmit}
        >
          <img className='mb-2' src={require('../../../assets/images/logo512.png')} alt='' width='72' height='72' />
          <h1 className='h3 mb-3 fw-normal'>{t('login.title')}</h1>

          <div className='form-floating'>
            <input
              id='floatingInput'
              className='form-control'
              type='email'
              placeholder='name@example.com'
              value={email}
              onChange={(e) => handleChange(e, 'email')}
              required
            />
            <label htmlFor='floatingInput'>{t('login.email')}</label>
          </div>

          <div className='form-floating mt-2'>
            <input
              id='floatingPassword'
              className='form-control'
              type='password'
              placeholder='Password'
              onChange={(e) => handleChange(e, 'password')}
              required
            />
            <label htmlFor='floatingPassword'>{t('login.password')}</label>
          </div>

          <button
            type='submit'
            className='btn btn-primary w-100 py-2 my-3'
          >
            {t('login.button')}
          </button>
        </form>
      </main>
    </div>
  )
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
  loginSignIn: payload => dispatch(loginSignIn(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
