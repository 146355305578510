import React, { useImperativeHandle, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { t } from '../../../config'
import { timeout } from '../../../utils'

const CustomToggle = React.forwardRef((props, ref) => {
  const { multiple } = props
  return <span className='fw-medium fst-italic' style={{ paddingLeft: 16, paddingRight: 16 }}>{t(multiple ? 'common.selectMultiple' : 'common.select')}...</span>
})

const SelectEditor = React.forwardRef((props, ref) => {
  const { options, multiple, canBeEmpty } = props
  const [value, setValue] = useState(props.value)

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value
      },
      isPopup: () => {
        return false
      }
    }
  })

  const handleSelectValue = async (item) => {
    if (multiple) {
      if (value.includes(item.value)) {
        setValue(value.filter(v => v !== item.value))
        return
      }
      setValue([...value, item.value])
      if (options.length === 1) {
        await timeout(50)
        props.api.stopEditing()
      }
      return
    }
    setValue(value === item.value ? (canBeEmpty ? null : undefined) : item.value)
    await timeout(50)
    props.api.stopEditing()
  }

  return (
    <div>
      <Dropdown
        style={{ position: 'fixed' }}
        show
      >
        <Dropdown.Toggle
          multiple={multiple}
          as={CustomToggle}
        />

        <Dropdown.Menu
          style={{ maxHeight: '450px', overflowY: 'auto' }}
        >
          {options.map(item => {
            return (
              <Dropdown.Item
                onClick={() => {
                  handleSelectValue(item)
                }}
                style={{ lineHeight: 2 }}
                active={multiple ? value.includes(item.value) : value === item.value}
                key={item.value}
              >
                {item.label}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
})

export default SelectEditor
