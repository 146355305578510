import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { connect } from 'react-redux'
import { validateActualVisit } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import moment from 'moment'
import { createActualVisit } from '../../../actions'
import { hasReqLevel } from '../../../utils'

// const EX = {
//   visit: '',
//   amendment: '',
//   patient: '',
//   date: '',
//   reimbursement: '',
//   currency: ''
//   reimbursementInvoice: null,
//   visitInvoice: null,
//   notes: ''
// }

function ActualVisitForm (props) {
  const { clinicalResearches, visits, patients, amendments, invoices, onHide, prefill = {} } = props

  const [query, setQuery] = useState({ ...prefill, currency: 'BGN' })
  const [loading, setLoading] = useState(false)

  const handleUpdateQuery = (field, value) => {
    const _query = validateActualVisit({ ...query, [field]: value })

    if (field === 'clinicalResearch') {
      _query.visit = ''
      _query.patient = ''
      _query.reimbursementInvoice = ''
      _query.visitInvoice = ''
      _query.amendment = ''
    }
    if (field === 'visit') {
      _query.amendment = ''
      const _amendments = amendments.filter(a => a.visit === value)
      if (_amendments.length) {
        _query.amendment = _amendments[0]._id
      }
    }
    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createActualVisit({
      data: validateActualVisit(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const disabled = !query.clinicalResearch || !query.visit || !query.patient || !query.date || !query.amendment
  return (
    <>
      <Row>

        <Col>
          <Form.Label>
            {t('fields.clinicalResearch')}*
          </Form.Label>
          <Form.Select
            value={query.clinicalResearch}
            onChange={e => handleUpdateQuery('clinicalResearch', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {clinicalResearches.map(cr => {
              return (
                <option
                  key={cr._id}
                  value={cr._id}
                >
                  {cr.name}
                </option>
              )
            })}
          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.visit')}*
          </Form.Label>
          <Form.Select
            disabled={!query.clinicalResearch}
            value={query.visit}
            onChange={e => handleUpdateQuery('visit', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {visits
              .filter(v => v.clinicalResearch === query.clinicalResearch)
              .map(v => {
                return (
                  <option
                    key={v._id}
                    value={v._id}
                  >
                    {v.reason}
                  </option>
                )
              })}
          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.date')}*
          </Form.Label>
          <Form.Control
            type='date'
            value={query.date ? moment(query.date).format('YYYY-MM-DD') : undefined}
            onChange={e => handleUpdateQuery('date', moment(e.target.value, 'YYYY-MM-DD').toDate())}
          />
          <br />
          <Form.Label>
            {t('fields.reimbursement')}
          </Form.Label>
          <Form.Control
            type='number'
            onChange={e => handleUpdateQuery('reimbursement', e.target.value)}
            onFocus={e => e.target.select()}
          />
          <br />
          <Form.Label>
            {t('fields.notes')}
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('notes', e.target.value)}
          />
        </Col>
        <Col>
          <Form.Label>
            {t('fields.patient')}*
          </Form.Label>
          <Form.Select
            disabled={!query.clinicalResearch}
            value={query.patient}
            onChange={e => handleUpdateQuery('patient', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {patients
              .filter(p => p.clinicalResearch === query.clinicalResearch)
              .map(p => {
                return (
                  <option
                    key={p._id}
                    value={p._id}
                  >
                    {p.name} ({p.identifier})
                  </option>
                )
              })}
          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.amendment')}*
          </Form.Label>
          <Form.Select
            disabled={!query.visit || amendments.filter(a => a.visit === query.visit).length === 1}
            value={query.amendment}
            onChange={e => handleUpdateQuery('amendment', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {amendments
              .filter(a => a.visit === query.visit)
              .map(a => {
                return (
                  <option
                    key={a._id}
                    value={a._id}
                  >
                    {a.name}
                  </option>
                )
              })}
          </Form.Select>
          {/* <br />
          <Form.Label>
            {t('fields.currency')}
          </Form.Label>
          <Form.Select
            value={query.currency}
            onChange={e => handleUpdateQuery('currency', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {CURRENCIES.map(c => {
              return (
                <option
                  key={c}
                  value={c}
                >
                  {c}
                </option>
              )
            })}
          </Form.Select> */}
          {hasReqLevel(2) &&
            <>
              <br />
              <Form.Label>
                {t('fields.visitInvoice')}
              </Form.Label>
              <Form.Select
                disabled={!query.clinicalResearch}
                value={query.visitInvoice}
                onChange={e => handleUpdateQuery('visitInvoice', e.target.value)}
              >
                <option value=''>{t('common.select')}...</option>
                {invoices
                  .filter(inv => inv.clinicalResearches.includes(query.clinicalResearch) && !inv.completed)
                  .map(inv => {
                    return (
                      <option
                        key={inv._id}
                        value={inv._id}
                      >
                        {inv.name}
                      </option>
                    )
                  })}
              </Form.Select>
            </>}
          {hasReqLevel(2) &&
            <>
              <br />
              <Form.Label>
                {t('fields.reimbursementInvoice')}
              </Form.Label>
              <Form.Select
                disabled={!query.clinicalResearch}
                value={query.reimbursementInvoice}
                onChange={e => handleUpdateQuery('reimbursementInvoice', e.target.value)}
              >
                <option value=''>{t('common.select')}...</option>
                {invoices
                  .filter(inv => inv.clinicalResearches.includes(query.clinicalResearch) && !inv.completed)
                  .map(inv => {
                    return (
                      <option
                        key={inv._id}
                        value={inv._id}
                      >
                        {inv.name}
                      </option>
                    )
                  })}
              </Form.Select>
            </>}
        </Col>
      </Row>
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapStateToProps = state => ({
  clinicalResearches: state.dashboard.clinicalResearches,
  visits: state.dashboard.visits,
  patients: state.dashboard.patients,
  invoices: state.dashboard.invoices,
  amendments: state.dashboard.amendments
})

const mapDispatchToProps = dispatch => ({
  createActualVisit: payload => dispatch(createActualVisit(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ActualVisitForm)
