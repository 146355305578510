import { loginTypes } from '../actions'
import { treasuryTypes } from '../actions/treasury'

const initialState = {
  movements: undefined
}

const handleRemoveTableItemSuccess = (tableState, payload) => {
  return tableState.filter(item => item._id !== payload._id)
}

const handleCreateTableItemSuccess = (tableState, payload) => {
  return [payload, ...tableState]
}

const handleUpdateTableStart = (tableState, payload) => {
  return tableState.map(item => item._id === payload._id ? { ...item, ...payload.data, _pending: item } : item)
}
const handleUpdateTableSuccess = (tableState, payload) => {
  return tableState.map(item => item._id === payload._id ? payload : item)
}
const handleUpdateTableFailed = (tableState, payload) => {
  return tableState.map(item => item._id === payload._id ? item._pending : item)
}

const treasury = (state = initialState, { type, payload }) => {
  switch (type) {
    case treasuryTypes.GET_TREASURY_MOVEMENTS_SUCCESS:
      return {
        ...state,
        movements: payload
      }
    case treasuryTypes.EDIT_TREASURY_MOVEMENT:
      return {
        ...state,
        movements: handleUpdateTableStart(state.movements, payload)
      }
    case treasuryTypes.EDIT_TREASURY_MOVEMENT_SUCCESS:
      return {
        ...state,
        movements: handleUpdateTableSuccess(state.movements, payload)
      }
    case treasuryTypes.EDIT_TREASURY_MOVEMENT_FAILED:
      return {
        ...state,
        movements: handleUpdateTableFailed(state.movements, payload)
      }
    case treasuryTypes.DELETE_TREASURY_MOVEMENT_SUCCESS:
      return {
        ...state,
        movements: handleRemoveTableItemSuccess(state.movements, payload)
      }
    case treasuryTypes.CREATE_TREASURY_MOVEMENT_SUCCESS:
      return {
        ...state,
        movements: handleCreateTableItemSuccess(state.movements, payload)
      }
    case loginTypes.DEAUTHENTICATE:
      return initialState
    default:
      return state
  }
}

export default treasury
