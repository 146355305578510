import React, { useMemo, useState } from 'react'
import { t } from '../../../config'
import { Button, Col, Container, Dropdown, DropdownToggle, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { hasReqLevel } from '../../../utils'
import { exportCSV } from '../../../utils/csv'

function Reports (props) {
  const { clinicalResearches = [], actualVisits = [], patients = [], visits = [] } = props
  const [selectedCR, setSelectedCR] = useState(null)

  const exportReimbursementsData = useMemo(() => {
    if (!selectedCR) return []
    const avs = actualVisits
      .filter(av => av.clinicalResearch === selectedCR._id && !av.reimbursementInvoice)
      .map(av => ({
        'Clinical Research': clinicalResearches.find(cr => cr._id === av.clinicalResearch)?.name || '',
        Patient: patients.find(patient => patient._id === av.patient)?.name || '',
        'Patient ID': patients.find(patient => patient._id === av.patient)?.identifier || '',
        Visit: visits.find(visit => visit._id === av.visit)?.reason || '',
        'Reimbursement Amount': av.reimbursement
      }))
    return avs
  }, [selectedCR, actualVisits, clinicalResearches, patients, visits])

  const handleScheduleReport = () => {
    window.open(`${window.location.origin}/reports/schedule`)
  }
  const handleAllInvoicesReport = (id) => {
    window.open(`${window.location.origin}/reports/all-invoices/${id}`)
  }
  const handleVisitsToInvoiceReport = (id) => {
    window.open(`${window.location.origin}/reports/visits-to-invoice/${id}`)
  }
  const handleLabKitsReport = () => {
    window.open(`${window.location.origin}/reports/lab-kits`)
  }
  const handleSumVisitsInInvoices = (id) => {
    window.open(`${window.location.origin}/reports/sum-visits-in-invoices/${id}`)
  }
  const handlePatientsWithoutScheduleReport = () => {
    window.open(`${window.location.origin}/reports/patients-without-schedule`)
  }

  const handleExportReimbursement = () => {
    exportCSV(exportReimbursementsData, 'report_export_reimbursements')
  }

  return (
    <Container fluid>
      <Row lg={2} xl={2} xxl={3}>
        <Col style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            className='mb-3'
            size='lg'
            onClick={handleScheduleReport}
          >
            {t('dashboard.reports.schedule')}
          </Button>
          <Button
            className='mb-3'
            size='lg'
            onClick={handleLabKitsReport}
          >
            {t('dashboard.reports.labKits')}
          </Button>
          <Button
            className='mb-3'
            size='lg'
            onClick={handlePatientsWithoutScheduleReport}
          >
            {t('dashboard.reports.patientsWithoutSchedule')}
          </Button>
        </Col>
        <Col style={{ display: 'flex', flexDirection: 'column' }}>
          <Dropdown
            className='mb-3'
          >
            <DropdownToggle
              disabled={!clinicalResearches.length}
              style={{ width: '100%' }}
              variant='secondary'
              size='lg'
            >
              <span>{selectedCR?.name || t('common.select')}</span>
            </DropdownToggle>
            <Dropdown.Menu
              style={{ maxHeight: '600px', overflowY: 'auto' }}
            >
              {clinicalResearches.map((cr, index) => (
                <Dropdown.Item
                  onClick={() => setSelectedCR(cr)}
                  key={index}
                >{cr.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Button
            className='mb-3'
            disabled={!selectedCR?._id || !exportReimbursementsData.length}
            onClick={() => handleExportReimbursement()}
            variant='success'
            size='lg'
          >
            {t('dashboard.reports.exportReimbursements')} ({exportReimbursementsData.length})
          </Button>
          {hasReqLevel(2) &&
            <Button
              className='mb-3'
              disabled={!selectedCR?._id}
              onClick={() => handleAllInvoicesReport(selectedCR._id)}
              size='lg'
            >
              {t('dashboard.reports.allInvoices')}
            </Button>}
          {hasReqLevel(2) &&
            <Button
              className='mb-3'
              disabled={!selectedCR?._id}
              onClick={() => handleVisitsToInvoiceReport(selectedCR._id)}
              size='lg'
            >
              {t('dashboard.reports.visitsToInvoice')}
            </Button>}
          {hasReqLevel(2) &&
            <Button
              className='mb-3'
              disabled={!selectedCR?._id}
              onClick={() => handleSumVisitsInInvoices(selectedCR._id)}
              size='lg'
            >
              {t('dashboard.reports.sumVisitsInInvoices')}
            </Button>}
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = state => ({
  clinicalResearches: state.dashboard.clinicalResearches,
  actualVisits: state.dashboard.actualVisits,
  patients: state.dashboard.patients,
  visits: state.dashboard.visits
})

export default connect(mapStateToProps)(Reports)
