import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { connect } from 'react-redux'
import { validateRevenue } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import { CURRENCIES } from '../../../config/constants'
import _ from 'lodash'
import { createRevenue } from '../../../actions'

// const EX = {
//   clinicalResearch: '',
//   name: '',
//   amount: 0,
//   currency: 'BGN',
//   invoice: '',
// }

function RevenueForm (props) {
  const { clinicalResearches, invoices, onHide } = props
  const [query, setQuery] = useState({ currency: 'BGN' })
  const [loading, setLoading] = useState(false)

  const handleUpdateQuery = (field, value) => {
    const _query = validateRevenue({ ...query, [field]: value })
    if (field === 'clinicalResearch') {
      _query.invoice = ''
    }
    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createRevenue({
      data: validateRevenue(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const disabled = !query.clinicalResearch || !query.name
  return (
    <>
      <Row>

        <Col>
          <Form.Label>
            {t('fields.clinicalResearch')}*
          </Form.Label>
          <Form.Select
            value={query.clinicalResearch}
            onChange={e => handleUpdateQuery('clinicalResearch', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {clinicalResearches.map(cr => {
              return (
                <option
                  key={cr._id}
                  value={cr._id}
                >
                  {cr.name}
                </option>
              )
            })}
          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.amount')}
          </Form.Label>
          <Form.Control
            type='number'
            onChange={e => handleUpdateQuery('amount', e.target.value)}
            onFocus={e => e.target.select()}
          />
          <br />
          <Form.Label>
            {t('fields.currency')}
          </Form.Label>
          <Form.Select
            value={query.currency}
            onChange={e => handleUpdateQuery('currency', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {CURRENCIES.map(c => {
              return (
                <option
                  key={c}
                  value={c}
                >
                  {c}
                </option>
              )
            })}
          </Form.Select>
        </Col>
        <Col>
          <Form.Label>
            {t('fields.name')}*
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('name', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.invoice')}
          </Form.Label>
          <Form.Select
            disabled={!query.clinicalResearch}
            value={query.invoice}
            onChange={e => handleUpdateQuery('invoice', e.target.value)}
          >
            <option value=''>{t('common.select')}...</option>
            {invoices
              .filter(inv => inv.clinicalResearches.includes(query.clinicalResearch) && !inv.completed)
              .map(inv => {
                return (
                  <option
                    key={inv._id}
                    value={inv._id}
                  >
                    {inv.name}
                  </option>
                )
              })}
          </Form.Select>
        </Col>
      </Row>
      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapStateToProps = state => ({
  clinicalResearches: state.dashboard.clinicalResearches,
  invoices: state.dashboard.invoices

})

const mapDispatchToProps = dispatch => ({
  createRevenue: payload => dispatch(createRevenue(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(RevenueForm)
