import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { t } from '../../../config'
import { connect } from 'react-redux'
import { validateAmendment } from '../../../utils/validation'
import FormFooter from '../FormFooter'
import { createAmendment } from '../../../actions'

// const EX = {
//   clinicalResearch: '',
//   visit: '',
//   name: '',
//   budgetMhat: '',
//   budgetPI: ''
// }

function AmendmentForm (props) {
  const { clinicalResearches, visits, onHide, prefill } = props

  const [query, setQuery] = useState({ ...prefill })
  const [loading, setLoading] = useState(false)

  const handleUpdateQuery = (field, value) => {
    const _query = validateAmendment({ ...query, [field]: value })

    setQuery(_query)
  }

  const handleAdd = () => {
    setLoading(true)
    props.createAmendment({
      data: validateAmendment(query),
      onSuccess: () => onHide(),
      onFailure: () => setLoading(false)
    })
  }

  const disabled = !query.clinicalResearch || !query.visit || !query.name
  return (
    <>
      <Row>
        <Col>
          <Form.Label>
            {t('fields.clinicalResearch')}*
          </Form.Label>
          <Form.Select
            disabled
            value={query.clinicalResearch}
            onChange={e => handleUpdateQuery('clinicalResearch', e.target.value)}
          >
            <option value={query.clinicalResearch}>{clinicalResearches.find(item => item._id === query.clinicalResearch)?.name}</option>
          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.name')}*
          </Form.Label>
          <Form.Control
            type='text'
            onChange={e => handleUpdateQuery('name', e.target.value)}
          />
          <br />
          <Form.Label>
            {t('fields.budgetPI')}
          </Form.Label>
          <Form.Control
            type='number'
            onChange={e => handleUpdateQuery('budgetPI', e.target.value)}
            onFocus={e => e.target.select()}
          />
        </Col>
        <Col>
          <Form.Label>
            {t('fields.visit')}*
          </Form.Label>
          <Form.Select
            disabled
            value={query.visit}
          >
            <option value={query.visit}>{visits.find(item => item._id === query.visit)?.reason}</option>

          </Form.Select>
          <br />
          <Form.Label>
            {t('fields.budgetMhat')}
          </Form.Label>
          <Form.Control
            type='number'
            onChange={e => handleUpdateQuery('budgetMhat', e.target.value)}
            onFocus={e => e.target.select()}
          />

        </Col>
      </Row>

      <FormFooter
        loading={loading}
        disabled={disabled}
        onClick={handleAdd}
      />
    </>
  )
}

const mapStateToProps = state => ({
  clinicalResearches: state.dashboard.clinicalResearches,
  visits: state.dashboard.visits
})

const mapDispatchToProps = dispatch => ({
  createAmendment: payload => dispatch(createAmendment(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(AmendmentForm)
