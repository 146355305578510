import { loginTypes, modalTypes } from '../actions'

const initialState = {
  type: undefined,
  data: undefined,
  show: false
}

const modal = (state = initialState, { type, payload }) => {
  switch (type) {
    case modalTypes.SET_MODAL_FIELDS:
      return { ...state, ...payload }
    case modalTypes.RESET_MODAL_FIELDS:
    case loginTypes.DEAUTHENTICATE:
      return initialState
    default:
      return state
  }
}

export default modal
