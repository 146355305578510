import React, { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { TABLES, TASKS, TREASURY_MOVEMENTS } from '../../../config/constants'
import { t } from '../../../config'
import { ActualVisitForm, ClinicalResearchForm, DoctorForm, InvoiceForm, LabKitForm, PatientForm, PaymentForm, ProcedureForm, ProformaForm, ScheduledVisitForm, TreasuryMovementForm, VisitForm, AmendmentForm, CompanyForm, RevenueForm, TaskForm } from '../../forms'

function DashboardCreateModal (props) {
  const { onHide, show, activeTable, prefill } = props
  const content = useMemo(() => {
    switch (activeTable) {
      case TABLES.CLINICAL_RESEARCHES:
        return {
          title: `${t('common.add')} ${t('fields.clinicalResearch')}`,
          render: () => <ClinicalResearchForm onHide={onHide} />
        }
      case TABLES.DOCTORS:
        return {
          title: `${t('common.add')} ${t('fields.doctor')}`,
          render: () => <DoctorForm onHide={onHide} />
        }
      case TABLES.PATIENTS:
        return {
          title: `${t('common.add')} ${t('fields.patient')}`,
          render: () => <PatientForm onHide={onHide} />
        }
      case TABLES.VISITS:
        return {
          title: `${t('common.add')} ${t('fields.visit')}`,
          render: () => <VisitForm onHide={onHide} />
        }
      case TABLES.SCHEDULED_VISITS:
        return {
          title: `${t('common.add')} ${t('fields.scheduledVisit')}`,
          render: () => <ScheduledVisitForm onHide={onHide} />
        }
      case TABLES.ACTUAL_VISITS:
        return {
          title: `${t('common.add')} ${t('fields.actualVisit')}`,
          render: () => <ActualVisitForm prefill={prefill} onHide={onHide} />
        }
      case TABLES.INVOICES:
        return {
          title: `${t('common.add')} ${t('fields.invoice')}`,
          render: () => <InvoiceForm onHide={onHide} />
        }
      case TABLES.PAYMENTS:
        return {
          title: `${t('common.add')} ${t('fields.payment')}`,
          render: () => <PaymentForm onHide={onHide} />
        }
      case TABLES.PROFORMAS:
        return {
          title: `${t('common.add')} ${t('fields.proforma')}`,
          render: () => <ProformaForm onHide={onHide} />
        }
      case TABLES.PROCEDURES:
        return {
          title: `${t('common.add')} ${t('fields.procedure')}`,
          render: () => <ProcedureForm onHide={onHide} />
        }
      case TABLES.LAB_KITS:
        return {
          title: `${t('common.add')} ${t('fields.labKit')}`,
          render: () => <LabKitForm onHide={onHide} />
        }
      case TABLES.AMENDMENTS:
        return {
          title: `${t('common.add')} ${t('fields.amendment')}`,
          render: () => <AmendmentForm onHide={onHide} prefill={prefill} />
        }
      case TABLES.COMPANIES:
        return {
          title: `${t('common.add')} ${t('fields.company')}`,
          render: () => <CompanyForm onHide={onHide} />
        }
      case TABLES.REVENUES:
        return {
          title: `${t('common.add')} ${t('fields.revenue')}`,
          render: () => <RevenueForm onHide={onHide} />
        }
      case TREASURY_MOVEMENTS:
        return {
          title: `${t('common.add')} ${t('fields.movement')}`,
          render: () => <TreasuryMovementForm onHide={onHide} />
        }
      case TASKS:
        return {
          title: `${t('common.add')} ${t('fields.task')}`,
          render: () => <TaskForm onHide={onHide} />
        }
    }
  }, [activeTable, prefill])
  if (!content) return null
  return (
    <Modal
      onHide={onHide}
      show={show}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header
        closeButton
      >
        <Modal.Title id='contained-modal-title-vcenter'>
          {content.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content.render()}
      </Modal.Body>
    </Modal>
  )
}

export default DashboardCreateModal
